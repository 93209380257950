import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { decodeToken } from '../../utils/TokenUtils';

const initialState = {
	selectCenter: JSON.parse(localStorage.getItem('selectedCenter')) || null,
	isError: false,
	isLoading: true,
	isSuccess: false,
	message: '',
};

/**
 * @name getCenter
 * @description The select center thunk
 * @param {String} centerId The center id
 * @returns {Promise} The promise
 * @version 1.0.0
 *
 */
export const getCenter = createAsyncThunk('', async (data, thunkAPI) => {
	const { centerId, userId } = data;
	if (centerId === '') return null;
	try {
		// eslint-disable-next-line no-unused-vars
		const [_, response] = await Promise.all([
			axios.patch(`/update/selectedCenter/${centerId}`),
			axios.get(`/user/centers/${userId}`),
		]);

		const { token } = response.data.data;
		const centers = decodeToken(token);
		const center = centers.filter(item => item.centerId === centerId)[0];

		return {
			centerId: center.centerId,
			name: center.name,
			role: center.users[0].UsersCenters.role,
		};
	} catch (error) {
		return thunkAPI.rejectWithValue(error.response.data.message);
	}
});

/**
 *
 * @name selectCenterSlice
 * @description The auth slice
 * @version 1.0.0
 *
 */
export const selectCenterSlice = createSlice({
	name: 'selectedCenter',
	initialState,
	reducers: {
		reset: () => initialState,
	},
	extraReducers: builder => {
		builder.addCase(getCenter.pending, state => {
			state.isLoading = true;
		});
		builder.addCase(getCenter.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.selectCenter = action.payload;
			// Guardar en local storage
			localStorage.setItem(
				'selectedCenter',
				JSON.stringify(action.payload),
			);
		});
		builder.addCase(getCenter.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.message = action.payload;
		});
	},
});

export const { reset } = selectCenterSlice.actions;
export default selectCenterSlice.reducer;
