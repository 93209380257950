import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
	Button,
	FormControl,
	FormLabel,
	HStack,
	Input,
	InputGroup,
	InputRightElement,
	Select,
	Stack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getAllCenter } from '../../../../services/CenterServices';
import { getAllModels } from '../../../../services/CameraServices';

export const FormDataCamera = ({
	inputCamera,
	hadlerChangeCreateCamera,
	isUpdate,
	clickOnSubmit,
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const [models, setModels] = useState([]);
	const [centers, setCenters] = useState([]);

	useEffect(() => {
		const AllModels = async () => {
			const [centers, models] = await Promise.all([
				getAllCenter(),
				getAllModels(),
			]);

			setCenters(centers);
			setModels(models);
		};

		AllModels();
	}, []);

	const checkValue = (value, isOptional = true) => {
		if (isOptional) return clickOnSubmit && !value;
		return clickOnSubmit && (!value || value.trim() === '');
	};

	return (
		<Stack spacing={8} maxW={'lg'}>
			<HStack>
				<FormControl
					w={'50%'}
					id='serial'
					isRequired
					isInvalid={checkValue(inputCamera.serial, false)}
				>
					<FormLabel>Número de serie:</FormLabel>
					<Input
						name='serial'
						type='text'
						errorBorderColor='crimson'
						value={inputCamera?.serial}
						onChange={hadlerChangeCreateCamera}
					/>
				</FormControl>

				<FormControl
					isRequired
					w={'50%'}
					isInvalid={clickOnSubmit && !inputCamera.model}
				>
					<FormLabel htmlFor='model' fontWeight={'normal'}>
						Modelo
					</FormLabel>
					<Select
						placeholder={
							inputCamera?.model && isUpdate
								? inputCamera?.model
								: 'Seleccionar modelo'
						}
						id='model'
						name='model'
						errorBorderColor='crimson'
						onChange={hadlerChangeCreateCamera}
					>
						{models.map((model, index) => (
							<option key={index} value={model}>
								{model}
							</option>
						))}
					</Select>
				</FormControl>
			</HStack>
			<Stack flexDirection={'row'}>
				<FormControl
					id='ip'
					isRequired
					isInvalid={checkValue(inputCamera.ip, false)}
				>
					<FormLabel>IP:</FormLabel>
					<Input
						name='ip'
						type='text'
						errorBorderColor='crimson'
						value={inputCamera?.ip}
						onChange={hadlerChangeCreateCamera}
					/>
				</FormControl>
				<FormControl
					id='user'
					isRequired
					isInvalid={checkValue(inputCamera.user, false)}
				>
					<FormLabel>Usuario:</FormLabel>
					<Input
						name='user'
						type='text'
						errorBorderColor='crimson'
						value={inputCamera?.user}
						onChange={hadlerChangeCreateCamera}
					/>
				</FormControl>
				<FormControl
					id='password'
					isRequired
					isInvalid={checkValue(inputCamera.password, false)}
				>
					<FormLabel>Password:</FormLabel>
					<InputGroup>
						<Input
							name='password'
							type={showPassword ? 'text' : 'password'}
							errorBorderColor='crimson'
							value={inputCamera?.password}
							onChange={hadlerChangeCreateCamera}
						/>
						<InputRightElement h={'full'}>
							<Button
								variant={'ghost'}
								onClick={() => {
									setShowPassword(
										showPassword => !showPassword,
									);
								}}
							>
								{showPassword ? <ViewIcon /> : <ViewOffIcon />}
							</Button>
						</InputRightElement>
					</InputGroup>
				</FormControl>
			</Stack>
			{!isUpdate && (
				<FormControl
					isRequired
					isInvalid={clickOnSubmit && !inputCamera.centerId}
				>
					<FormLabel htmlFor='centerId' fontWeight={'normal'}>
						Asignar un centro:
					</FormLabel>
					<Select
						placeholder={
							inputCamera?.centerName && isUpdate
								? inputCamera?.centerName
								: 'Seleccione un centro'
						}
						id='centerId'
						name='centerId'
						errorBorderColor='crimson'
						onChange={hadlerChangeCreateCamera}
					>
						{centers.map(center => (
							<option
								key={center.centerId}
								value={center.centerId}
							>
								{center.name}
							</option>
						))}
					</Select>
				</FormControl>
			)}
		</Stack>
	);
};
