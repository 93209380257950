import { Stack, Button, useToast, useDisclosure } from '@chakra-ui/react';
import { HeaderAll } from '../general/HeaderAll';
// import { FilterNavbar } from './subcomponents/FilterNavbar';
import { useEffect, useState } from 'react';
import {
	getAllEventsNotRead,
	markAsReadAllEvent,
	deleteAllReadEvents,
} from '../../services/EventServices';
import { CardEvent } from './subcomponents/CardEvent';
import { errorToast, okToast } from '../../utils/toast';
import { AlertMarkAsReadAllEvent } from './subcomponents/AlertMarkAsReadAllEvent';
import { AlertDeleteAllReadEvents } from './subcomponents/AlertDeleteAllReadEvents';

export const ViewAllEvents = () => {
	const [allEvents, setAllEvents] = useState([]);
	const [isReload, setIsReload] = useState(true);
	const {
		isOpen: isOpenMarkAsRead,
		onOpen: onOpenMarkAsRead,
		onClose: onCloseMarkAsRead,
	} = useDisclosure();
	const {
		isOpen: isOpenDeleteAllRead,
		onOpen: onOpenDeleteAllRead,
		onClose: onCloseDeleteAllRead,
	} = useDisclosure();
	const toast = useToast();

	useEffect(() => {
		const fetchAllEvents = async () => {
			const events = await getAllEventsNotRead();
			setAllEvents(events);
			setIsReload(false);
		};

		if (isReload) {
			fetchAllEvents();
		}
	}, [isReload]);

	const handleMarkAllAsRead = async () => {
		try {
			await markAsReadAllEvent();
			okToast(toast, 'Todos los eventos marcados como leídos');
			setIsReload(true);
		} catch (error) {
			errorToast(toast, 'Error al marcar todos los eventos como leídos');
		}
	};

	const handleDeleteAllRead = async () => {
		try {
			await deleteAllReadEvents();
			okToast(toast, 'Todos los eventos leídos han sido eliminados');
			setIsReload(true);
		} catch (error) {
			errorToast(toast, 'Error al eliminar los eventos leídos');
		}
	};

	return (
		<Stack m={{ base: 5, lg: 10 }}>
			<Stack
				direction={{ base: 'column', md: 'row' }}
				justifyContent={{ base: 'center', md: 'space-between' }}
				alignItems='center'
				mb={4}
			>
				<HeaderAll
					title='Eventos'
					textAlign={{ base: 'center', md: 'left' }}
				/>
				<Stack
					direction={{ base: 'column', md: 'row' }}
					spacing={2}
					alignItems='center'
					width={{ base: '100%', md: 'auto' }}
				>
					<Button
						bg='#4FD1C5'
						_hover={{ bg: '#4A5568', color: 'white' }}
						color='white'
						onClick={onOpenMarkAsRead}
						width={{ base: '100%', md: 'auto' }}
					>
						Marcar todos como leídos
					</Button>
					<Button
						variant='redAow'
						_hover={{ bg: '#4A5568', color: 'white' }}
						color='white'
						onClick={onOpenDeleteAllRead}
						width={{ base: '100%', md: 'auto' }}
					>
						Eliminar los eventos leídos
					</Button>
				</Stack>
			</Stack>
			<Stack>
				{/* <FilterNavbar /> */}
				{allEvents?.map(event => (
					<CardEvent
						key={event.id}
						event={event}
						setIsReload={setIsReload}
						isReload={isReload}
					/>
				))}
			</Stack>
			<AlertMarkAsReadAllEvent
				isOpen={isOpenMarkAsRead}
				onClose={onCloseMarkAsRead}
				onConfirm={handleMarkAllAsRead}
			/>
			<AlertDeleteAllReadEvents
				isOpen={isOpenDeleteAllRead}
				onClose={onCloseDeleteAllRead}
				onConfirm={handleDeleteAllRead}
			/>
		</Stack>
	);
};
