import {
	Box,
	Center,
	Spinner,
	Stack,
	Text,
	VStack,
	useDisclosure,
} from '@chakra-ui/react';
import { CardsCenters } from './subcomponents/CardsCenters';
import { useEffect, useState } from 'react';
import { getAllCenter } from '../../services/CenterServices';
// import { FilterNavbar } from './subcomponents/FilterNavbar';
import { HeaderAll } from '../general/HeaderAll';
import { FormModalCenters } from './modal/FormModalCenters';
import { errorToast } from '../../utils/toast';

export const ViewAllCenters = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isUpdateData, setIsUpdateData] = useState(false);
	const [isReload, setIsReload] = useState(false);
	const [centers, setCenters] = useState([]);
	const [dataCenterUpdate, setDataCenterUpdate] = useState({});
	const [isFinishGetCenters, setIsFinishGetCenters] = useState(false);

	useEffect(() => {
		try {
			const getCenters = async () => {
				const allCenters = await getAllCenter();

				setCenters(allCenters);
				setIsFinishGetCenters(true);
			};

			getCenters();
		} catch (error) {
			errorToast(error, 'Error al obtener los centros');
		}
	}, [isReload]);

	return (
		<>
			<Stack m={{ base: 5, lg: 10 }}>
				<HeaderAll title='Centros' onOpen={onOpen} />
				<Stack>
					{/* <FilterNavbar /> */}

					<VStack>
						{!isFinishGetCenters ? (
							<Center p={5}>
								<Spinner
									thickness='4px'
									speed='0.65s'
									emptyColor='gray.200'
									color='teal300'
									size='xl'
								/>
							</Center>
						) : (
							<>
								{centers.length === 0 ? (
									<Center minW={'100%'}>
										<Text fontSize='2xl'>
											No hay centros asignados
										</Text>
									</Center>
								) : (
									centers.map(center => (
										<Box w={'100%'} key={center.center_id}>
											<CardsCenters
												center={center}
												setIsReload={setIsReload}
												isReload={isReload}
												onOpenFormModal={onOpen}
												setIsUpdateData={
													setIsUpdateData
												}
												setDataCenterUpdate={
													setDataCenterUpdate
												}
											/>
										</Box>
									))
								)}
							</>
						)}
					</VStack>
				</Stack>
			</Stack>
			<FormModalCenters
				isOpen={isOpen}
				onClose={onClose}
				setIsReload={setIsReload}
				isReload={isReload}
				isUpdateData={isUpdateData}
				setIsUpdateData={setIsUpdateData}
				dataCenterUpdate={dataCenterUpdate}
				setDataCenterUpdate={setDataCenterUpdate}
			/>
		</>
	);
};
