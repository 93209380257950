/**
 * @name formatDate
 * @description Formats a given date string into a 'yyyy-mm-dd' format suitable for use with `date` input fields.
 * This function takes a date string, converts it into a JavaScript Date object, and extracts the year, month,
 * and day. It then formats these components into a string that adheres to the 'yyyy-mm-dd' format, which is commonly
 * used in HTML5 date inputs.
 *
 * @param {string} dateString - A date string that can be parsed by JavaScript's Date constructor.
 * @returns {string} A string in the 'yyyy-mm-dd' format representing the date.
 * @creator Daniel Moreno Acedo, Web Developer at Unknown Gravity
 */
export const formatDate = dateString => {
	const date = new Date(dateString);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
};
