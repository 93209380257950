import {
	Box,
	Center,
	Spinner,
	Stack,
	VStack,
	useDisclosure,
} from '@chakra-ui/react';
import { HeaderAll } from '../general/HeaderAll';
import { CardsUsers } from './subcomponents/CardsUsers';
import { useEffect, useState } from 'react';

import { getAllUsers } from '../../services/UserServices';
import { FormModalUsers } from './modal/FormModalUsers';
import { FormModalViewCenters } from './modal/FormModalViewCenters';
import { FormModalViewPatients } from './modal/FormModalViewPatients';
import { errorToast } from '../../utils/toast';

export const ViewAllUsers = () => {
	const {
		isOpen: isOpenModalUser,
		onOpen: onOpenModalUser,
		onClose: onCloseModalUser,
	} = useDisclosure();
	const {
		isOpen: isOpenModalViewCenters,
		onOpen: onOpenModalViewCenters,
		onClose: onCloseModalViewCenters,
	} = useDisclosure();
	const {
		isOpen: isOpenModalViewPatients,
		onOpen: onOpenModalViewPatients,
		onClose: onCloseModalViewPatients,
	} = useDisclosure();

	const [isUpdateData, setIsUpdateData] = useState(false);
	const [isReload, setIsReload] = useState(false);
	const [isReloadCentersByUser, setIsReloadCentersByUser] = useState(false);
	const [isReloadPatientsByUser, setIsReloadPatientsByUser] = useState(false);
	const [users, setUsers] = useState([]);
	const [dataUserUpdate, setDataUserUpdate] = useState({});
	const [step, setStep] = useState(1);
	const [isFinishGetAllPatients, setIsFinishGetAllPatients] = useState(false);
	const [isFinishGetUsers, setIsFinishGetUsers] = useState(false);
	const [userIdSelect, setUserIdSelect] = useState('');
	const [onCloseStatus, setOnCloseStatus] = useState(false);

	useEffect(() => {
		const getUsers = async () => {
			const allUsers = await getAllUsers();
			setUsers(allUsers);
			setIsFinishGetUsers(true);
		};

		try {
			setIsReload(false);
			getUsers();
		} catch (error) {
			errorToast(error, 'Error al obtener los usuarios');
		}
	}, [isReload]);

	return (
		<>
			<Stack m={{ base: 5, lg: 10 }}>
				<HeaderAll title='Usuarios' onOpen={onOpenModalUser} />
				<Stack>
					<VStack>
						{!isFinishGetUsers ? (
							<Center p={5}>
								<Spinner
									thickness='4px'
									speed='0.65s'
									emptyColor='gray.200'
									color='teal300'
									size='xl'
								/>
							</Center>
						) : (
							<>
								{users.map(user => (
									<Box minW={'100%'} key={user.userId}>
										<CardsUsers
											user2={user}
											isAdmin={user?.isAdmin}
											setIsReload={setIsReload}
											isReload={isReload}
											onOpenFormModal={onOpenModalUser}
											onOpenFormModalViewCenters={
												onOpenModalViewCenters
											}
											onOpenFormModalViewPatients={
												onOpenModalViewPatients
											}
											setIsUpdateData={setIsUpdateData}
											setDataUserUpdate={
												setDataUserUpdate
											}
											setStep={setStep}
											setUserIdSelect={setUserIdSelect}
										/>
									</Box>
								))}
							</>
						)}
					</VStack>
				</Stack>
			</Stack>
			{isOpenModalUser && (
				<FormModalUsers
					isOpen={isOpenModalUser}
					onClose={onCloseModalUser}
					setIsReload={setIsReload}
					isReload={isReload}
					isUpdateData={isUpdateData}
					setIsUpdateData={setIsUpdateData}
					dataUserUpdate={dataUserUpdate}
					setDataUserUpdate={setDataUserUpdate}
					step={step}
					setStep={setStep}
					userIdSelect={userIdSelect}
					isReloadCentersByUser={isReloadCentersByUser}
					setIsReloadCentersByUser={setIsReloadCentersByUser}
					isReloadPatientsByUser={isReloadPatientsByUser}
					setIsReloadPatientsByUser={setIsReloadPatientsByUser}
					setOnCloseStatus={setOnCloseStatus}
					onCloseStatus={onCloseStatus}
				/>
			)}
			{isOpenModalViewCenters && (
				<FormModalViewCenters
					isOpen={isOpenModalViewCenters}
					onClose={onCloseModalViewCenters}
					setUserIdSelect={setUserIdSelect}
					isFinishGetAllPatients={isFinishGetAllPatients}
					setIsFinishGetAllPatients={setIsFinishGetAllPatients}
					onOpenModalUser={onOpenModalUser}
					setStep={setStep}
					userIdSelect={userIdSelect}
					isReloadCentersByUser={isReloadCentersByUser}
					setIsReloadCentersByUser={setIsReloadCentersByUser}
					setOnCloseStatus={setOnCloseStatus}
				/>
			)}
			{isOpenModalViewPatients && (
				<FormModalViewPatients
					isOpen={isOpenModalViewPatients}
					onClose={onCloseModalViewPatients}
					setUserIdSelect={setUserIdSelect}
					isFinishGetAllPatients={isFinishGetAllPatients}
					setIsFinishGetAllPatients={setIsFinishGetAllPatients}
					onOpenModalUser={onOpenModalUser}
					setStep={setStep}
					userIdSelect={userIdSelect}
					isReloadPatientsByUser={isReloadPatientsByUser}
					setIsReloadPatientsByUser={setIsReloadPatientsByUser}
					setOnCloseStatus={setOnCloseStatus}
				/>
			)}
		</>
	);
};
