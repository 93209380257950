import {
	Button,
	HStack,
	Select,
	Spacer,
	Stack,
	Text,
	VStack,
	useToast,
	useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { assingSmartWatch, deleteWatch } from '../../../services/WatchService';
import { errorToast, okToast } from '../../../utils/toast';
import { getAllCenter } from '../../../services/CenterServices';
import { getAllPacientsToCenter } from '../../../services/PatientServices';
import { AlertDeleteDevice } from './AlertDeleteDevice';

export const CardDevice = ({
	smartwatch,
	isReload,
	setIsReload,
	setIsUpdate,
	onOpen,
	setInputWatch,
}) => {
	const {
		isOpen: isOpenAlert,
		onOpen: onOpenAlert,
		onClose: onCloseAlert,
	} = useDisclosure();
	const { user } = useSelector(state => state.auth);
	const [isAssing, setIsAssing] = useState(false);
	const [centers, setCenters] = useState([]);
	const [patients, setPatients] = useState([]);
	const [inputSelectAssingWatch, setInputSelectAssingWatch] = useState({
		watchId: smartwatch?.watchId,
		centerId: smartwatch?.Center?.centerId,
		patientId: smartwatch?.Patient?.patientId,
	});
	const [needReload, setNeedReload] = useState(true);
	const toast = useToast();

	const handlerChangeAssing = e => {
		const { name, value } = e.target;
		const realValue = value === '' ? undefined : value;

		let data;

		if (name === 'centerId') {
			activateAssing();
			setNeedReload(true);
			data = {
				...inputSelectAssingWatch,
				patientId: undefined,
				centerId: realValue,
			};
		} else {
			data = {
				...inputSelectAssingWatch,
				[name]: realValue,
			};
		}

		setInputSelectAssingWatch(data);
	};

	const activateAssing = () => {
		setIsAssing(true);
		getAllCenter().then(centers => setCenters(centers));

		// TODO: ¿Hace falta?
		if (inputSelectAssingWatch?.centerId) allPatients();
	};
	const allPatients = async () => {
		if (inputSelectAssingWatch?.centerId === '') return;

		const patients = await getAllPacientsToCenter(
			inputSelectAssingWatch?.centerId,
			true,
		);

		// Añadir el paciente que tiene asignado
		if (smartwatch.Patient) {
			patients.push({
				patientId: smartwatch.Patient.patientId,
				name: smartwatch.Patient.name,
				lastname: smartwatch.Patient.lastname,
				dni: smartwatch.Patient.dni,
			});
		}

		setPatients(patients);
	};

	useEffect(() => {
		if (centers && isAssing && needReload) {
			setNeedReload(false);
			allPatients();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [centers, isAssing, needReload]);

	const updateWatch = () => {
		setIsUpdate(true);
		setInputWatch({
			watchId: smartwatch?.watchId,
			company: smartwatch?.company,
			model: smartwatch?.model,
			imei: smartwatch?.imei,
			phoneNumber: smartwatch?.phoneNumber,
			phoneAlert1: smartwatch?.phoneAlert1,
			phoneAlert2: smartwatch?.phoneAlert2,
			phoneAlertCentral: smartwatch?.phoneAlertCentral,
			patientId: smartwatch?.patientId,
			patientName: smartwatch?.patientName,
			patientLastname: smartwatch?.patientLastname,
			patientDni: smartwatch?.patientDni,
			centerId: smartwatch?.centerId,
			centerName: smartwatch?.centerName,
		});
		onOpen();
	};

	const onDelete = async () => {
		try {
			const deleted = await deleteWatch(smartwatch?.watchId);

			if (deleted) {
				okToast(toast, 'Dispositivo eliminado correctamente');
				setIsReload(!isReload);
			}
		} catch (error) {
			errorToast(toast, 'Error al eliminar el dispositivo');
		}
	};

	const onSubmit = async () => {
		try {
			let dataToSend = inputSelectAssingWatch;

			if (patients.length === 0) {
				dataToSend = {
					...dataToSend,
					patientId: undefined,
				};
				setInputSelectAssingWatch(dataToSend);
			}
			const rest = await assingSmartWatch(dataToSend);

			if (rest) {
				okToast(toast, 'Dispositivo asignado correctamente');
				setIsAssing(false);
				setIsReload(!isReload);
			}
		} catch (error) {
			errorToast(toast, 'Error al asignar', error.message);
		}
	};

	return (
		<VStack w={'100%'} gap={5} p={5} bg={'white'} borderRadius={5}>
			<Stack direction={{ base: 'column', xl: 'row' }} w='100%'>
				<HStack bgColor={'gray.100'} p={2} px={4} rounded={'lg'}>
					{!isAssing ? (
						<>
							<Text fontWeight={'bold'}>Asignado a:</Text>
							<Text>
								{smartwatch?.Center?.name
									? smartwatch?.Center.name
									: 'Sin asignar'}
							</Text>
						</>
					) : (
						<>
							<Text fontWeight={'bold'}>Asignar:</Text>
							<Select
								placeholder={'Sin centro'}
								value={inputSelectAssingWatch?.centerId}
								name='centerId'
								onChange={handlerChangeAssing}
								isDisabled={!user?.isAdmin}
							>
								{centers?.map(center => (
									<option
										key={`${Math.random()}_${center?.centerId}`}
										value={center?.centerId}
									>
										{center?.name}
									</option>
								))}
							</Select>
						</>
					)}
				</HStack>
				<HStack bgColor={'gray.100'} p={2} rounded={'lg'}>
					{!isAssing ? (
						<>
							<Text fontWeight={'bold'}>Paciente asignado:</Text>
							<Text>
								{smartwatch?.Patient
									? `${smartwatch?.Patient?.name} ${smartwatch?.Patient?.lastname} (${smartwatch?.Patient?.dni})`
									: 'Sin asignar'}
							</Text>
						</>
					) : (
						<>
							<Text fontWeight={'bold'}>Asignar:</Text>
							<Select
								isDisabled={
									inputSelectAssingWatch.centerId === ''
								}
								value={inputSelectAssingWatch?.patientId}
								placeholder={'Sin paciente'}
								name='patientId'
								onChange={handlerChangeAssing}
							>
								{patients?.map(patient => (
									<option
										key={`${Math.random()}_${patient?.patientId}`}
										value={patient?.patientId}
									>
										{`${patient?.name} ${patient?.lastname} (${patient?.dni})`}
									</option>
								))}
							</Select>
						</>
					)}
				</HStack>
				<Spacer />
			</Stack>
			<Stack
				w={'100%'}
				direction={{ base: 'column', xl: 'row' }}
				justify={'space-between'}
			>
				<VStack gap={0} alignItems={'left'}>
					<HStack>
						<Text fontWeight={'bold'}>Teléfono:</Text>
						<Text>{smartwatch?.phoneNumber}</Text>
					</HStack>
					<Spacer />
					<HStack>
						<Text fontWeight={'bold'}>Modelo:</Text>
						<Text>
							{smartwatch?.company} / {smartwatch?.model}
						</Text>
					</HStack>
					<Spacer />
					<HStack>
						<Text fontWeight={'bold'}>IMEI:</Text>
						<Text>{smartwatch?.imei}</Text>
					</HStack>
				</VStack>

				<HStack gap={2} justifyContent={'center'} alignItems={'center'}>
					{!isAssing && (
						<Button
							w={'full'}
							bg={'grayAow'}
							color={'white'}
							rounded={'md'}
							_hover={{
								transform: 'translateY(-2px)',
								boxShadow: 'lg',
							}}
							onClick={() => {
								activateAssing();
							}}
						>
							Asignar
						</Button>
					)}
					{isAssing ? (
						<>
							<Button
								w={'full'}
								bg={'teal300'}
								color={'white'}
								rounded={'md'}
								_hover={{
									transform: 'translateY(-2px)',
									boxShadow: 'lg',
								}}
								onClick={() => {
									onSubmit();
								}}
							>
								Guardar
							</Button>
							<Button
								w={'full'}
								bg={'red'}
								color={'white'}
								rounded={'md'}
								_hover={{
									transform: 'translateY(-2px)',
									boxShadow: 'lg',
								}}
								onClick={() => {
									setIsAssing(false);
									setInputSelectAssingWatch({
										watchId: smartwatch?.watchId,
										centerId: smartwatch?.Center?.centerId,
										patientId:
											smartwatch?.Patient?.patientId,
									});
								}}
							>
								Cancelar
							</Button>
						</>
					) : (
						user.isAdmin && (
							<>
								<Button
									w={'full'}
									bg={'teal300'}
									color={'white'}
									rounded={'md'}
									_hover={{
										transform: 'translateY(-2px)',
										boxShadow: 'lg',
									}}
									onClick={() => updateWatch()}
								>
									Editar
								</Button>
								<Button
									w={'full'}
									bg={'red'}
									color={'white'}
									rounded={'md'}
									_hover={{
										transform: 'translateY(-2px)',
										boxShadow: 'lg',
									}}
									onClick={() => {
										onOpenAlert();
									}}
								>
									Eliminar
								</Button>
							</>
						)
					)}
					<AlertDeleteDevice
						isOpen={isOpenAlert}
						onClose={onCloseAlert}
						onDelete={onDelete}
					/>
				</HStack>
			</Stack>
		</VStack>
	);
};
