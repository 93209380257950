import { useState, useEffect, useCallback } from 'react';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import {
	Box,
	Text,
	VStack,
	Stack,
	FormControl,
	FormLabel,
	Input,
	Button,
	Spinner,
	useToast,
	useBreakpointValue,
} from '@chakra-ui/react';
import { errorToast } from '../../../../utils/toast';
import { Circle } from './../../modal/viewPatients/subcomponents/CircleMaps';

// Component for displaying and managing GPS fence data
export const FormGpsFenceData = ({ handleChangeUser, inputUser }) => {
	const toast = useToast();

	// State to track the marker's location
	const [markerPosition, setMarkerPosition] = useState({
		lat: inputUser?.refLocation?.lat || null,
		lon: inputUser?.refLocation?.lon || null,
	});

	// State to track the radius of the geofence
	const [radiusThreshold, setRadiusThreshold] = useState(
		inputUser?.defaultValueAlarmConfig?.location?.radiusThreshold || '',
	);

	// State to check if the initial location has been set
	const [initialLocationSet, setInitialLocationSet] = useState(false);

	// State to track loading status
	const [isLoading, setIsLoading] = useState(false);

	// Handler function for marker drag end event
	const handleMarkerDragEnd = useCallback(
		e => {
			try {
				const newLat = e.latLng.lat();
				const newLng = e.latLng.lng();
				setMarkerPosition({ lat: newLat, lon: newLng });
			} catch (error) {
				errorToast(toast, 'Could not update marker position.');
			}
		},
		[toast],
	);

	// Handler function for radius input change
	const handleRadiusChange = e => {
		try {
			setRadiusThreshold(e.target.value);
		} catch (error) {
			errorToast(toast, 'Could not update the geofence radius.');
		}
	};

	// Function to get the current user location
	const getCurrentLocation = () => {
		setIsLoading(true);
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				position => {
					try {
						const { latitude, longitude } = position.coords;
						setMarkerPosition({ lat: latitude, lon: longitude });
						setIsLoading(false);
					} catch (error) {
						errorToast(
							toast,
							'No se pudo establecer la ubicación.',
						);
						setIsLoading(false);
					}
				},
				error => {
					errorToast(
						toast,
						'No se pudo obtener la ubicación.',
						error,
					);
					setIsLoading(false);
				},
			);
		} else {
			errorToast(
				toast,
				'Geolocalización no es soportada por este navegador.',
			);
			setIsLoading(false);
		}
	};

	// UseEffect to update backend data
	useEffect(() => {
		try {
			// Only update the backend if the initial location is not set or the marker has moved
			if (
				!initialLocationSet ||
				markerPosition.lat !== inputUser?.refLocation?.lat ||
				markerPosition.lon !== inputUser?.refLocation?.lon ||
				radiusThreshold !==
					inputUser?.defaultValueAlarmConfig?.location
						?.radiusThreshold
			) {
				handleChangeUser({
					target: {
						name: 'refLocation',
						value: markerPosition,
					},
				});
				handleChangeUser({
					target: {
						name: 'defaultValueAlarmConfig.location.radiusThreshold',
						value: radiusThreshold,
					},
				});

				// Mark the initial location as set
				setInitialLocationSet(true);
			}
		} catch (error) {
			errorToast(toast, 'No se pudieron actualizar los datos.');
		}
	}, [
		markerPosition,
		radiusThreshold,
		handleChangeUser,
		initialLocationSet,
		inputUser,
		toast,
	]);

	const mapHeight = useBreakpointValue({ base: '250px', md: '400px' });

	return (
		<VStack spacing={2} align='center'>
			<Box
				w={'100%'}
				h={mapHeight}
				bgColor={'gray.200'}
				borderRadius='md'
				overflow='hidden'
				position={'relative'}
			>
				{isLoading ? (
					<Box
						position='absolute'
						top='50%'
						left='50%'
						transform='translate(-50%, -50%)'
						display='flex'
						flexDirection='column'
						alignItems='center'
						justifyContent='center'
						w='100%'
						h='100%'
						bgColor='rgba(255, 255, 255, 0.8)'
					>
						<Spinner size='xl' color='teal.500' />
						<Text mt={4}>Obteniendo ubicación...</Text>
					</Box>
				) : markerPosition.lat && markerPosition.lon ? (
					<APIProvider
						apiKey={'AIzaSyDXGSYcqJdq1QT4p-VHLANFN5dpidX-Myw'}
					>
						<Map
							defaultCenter={{
								lat: markerPosition.lat,
								lng: markerPosition.lon,
							}}
							defaultZoom={13}
							gestureHandling={'greedy'}
							disableDefaultUI={true}
							center={{
								lat: markerPosition.lat,
								lng: markerPosition.lon,
							}}
						>
							<Marker
								position={{
									lat: markerPosition.lat,
									lng: markerPosition.lon,
								}}
								draggable={true}
								onDragEnd={handleMarkerDragEnd}
							/>
							<Circle
								center={{
									lat: markerPosition.lat,
									lng: markerPosition.lon,
								}}
								radius={parseFloat(radiusThreshold) || 0}
								strokeColor={'#000'}
								strokeOpacity={1}
								strokeWeight={1}
								fillColor={'#FF0000'}
								fillOpacity={0.3}
							/>
						</Map>
					</APIProvider>
				) : (
					<Box
						position='absolute'
						top='50%'
						left='50%'
						transform='translate(-50%, -50%)'
					>
						<Text>No hay datos de ubicación</Text>
					</Box>
				)}
			</Box>

			<Button onClick={getCurrentLocation} colorScheme='teal'>
				Obtener Ubicación
			</Button>

			<Stack
				direction={{ base: 'column', md: 'row' }}
				spacing={4}
				w='full'
				justify='center'
				align='center'
			>
				<FormControl>
					<FormLabel>Latitud</FormLabel>
					<Text
						bgColor='gray.100'
						borderRadius='md'
						padding={2}
						w='full'
						textAlign='center'
						color='gray.600'
					>
						{markerPosition.lat || 'Not available'}
					</Text>
				</FormControl>
				<FormControl>
					<FormLabel>Longitud</FormLabel>
					<Text
						bgColor='gray.100'
						borderRadius='md'
						padding={2}
						w='full'
						textAlign='center'
						color='gray.600'
					>
						{markerPosition.lon || 'Not available'}
					</Text>
				</FormControl>
				<FormControl>
					<FormLabel>Radio Geovalla (en metros)</FormLabel>
					<Input
						type='number'
						value={radiusThreshold}
						onChange={handleRadiusChange}
						bgColor='white'
					/>
				</FormControl>
			</Stack>
		</VStack>
	);
};
