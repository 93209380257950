import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';
import { Footer } from '../Footer';
import { SidebarContent } from './subcomponent/SidebarContent';
import { MobileNav } from './subcomponent/MobileNav';
import { useSelector } from 'react-redux';

const SidebarWithHeader = ({ children }) => {
	const { isSuccess } = useSelector(state => state.auth);
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			<Box bg={'gray.100'}>
				{isSuccess && (
					<>
						<SidebarContent
							onClose={onClose}
							display={{ base: 'none', xl: 'block' }}
						/>
						<Drawer
							isOpen={isOpen}
							placement='left'
							onClose={onClose}
							returnFocusOnClose={false}
							onOverlayClick={onClose}
							size='xs'
						>
							<DrawerContent>
								<SidebarContent onClose={onClose} />
							</DrawerContent>
						</Drawer>
						<MobileNav onOpen={onOpen} />
					</>
				)}
				<Box
					ml={isSuccess ? { base: 0, xl: 60 } : 0}
					minH={{
						base: '70vh',
						sm: '79vh',
						md: 'calc(81vh)',
						xl: isSuccess ? 'calc(100vh - 160.8px)' : '100vh',
					}}
				>
					{children}
				</Box>
				{isSuccess && <Footer />}
			</Box>
		</>
	);
};

export default SidebarWithHeader;
