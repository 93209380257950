import PropTypes from 'prop-types';
import {
	Button,
	Flex,
	Link,
	Stack,
	Text,
	VisuallyHidden,
} from '@chakra-ui/react';
import { FaInstagram, FaFacebook, FaYoutube } from 'react-icons/fa';

const SocialButton = ({ children, label, href }) => {
	return (
		<Button
			bg={'white'}
			size={'lg'}
			cursor={'pointer'}
			as={'a'}
			href={href}
			display={'inline-flex'}
			alignItems={'center'}
			justifyContent={'center'}
			transition={'background 0.3s ease'}
			_hover={{
				transform: 'scale(150%) rotate(6deg)',
			}}
		>
			<VisuallyHidden>{label}</VisuallyHidden>
			{children}
		</Button>
	);
};

SocialButton.propTypes = {
	children: PropTypes.node.isRequired,
	label: PropTypes.string.isRequired,
	href: PropTypes.string.isRequired,
};

export const Footer = () => {
	return (
		<Flex
			ml={{ base: 0, xl: 60 }}
			px={{ base: 4, xl: 4 }}
			py={{ base: 4, xl: 4 }}
			alignItems='center'
			flexDirection={{ base: 'column', xl: 'row' }}
			gap={{ base: 1, md: 0 }}
			bg={'white'}
			borderBottomWidth='1px'
			borderBottomColor={'gray.200'}
			justifyContent={{ base: 'space-between', md: 'space-between' }}
		>
			<Text fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}>
				&copy; 2024 Angels Caring | Powered by{' '}
				<Link href={'https://www.unknowngravity.com/'} isExternal>
					<span style={{ fontWeight: 'bold' }}>Unknown Gravity</span>
				</Link>
			</Text>

			<Stack direction={'row'} spacing={6} mr={5}>
				<SocialButton
					label={'Facebook'}
					href={
						'https://www.facebook.com/people/Angels-OnWatch/pfbid0236pZaNckuQkjsNmNxRAyRjPNSm85EvC7ZrwrpAn8fY8hdtm5U4xHaCqb2rtqshyhl/'
					}
				>
					<FaFacebook />
				</SocialButton>
				<SocialButton
					label={'YouTube'}
					href={
						'https://www.youtube.com/channel/UCF6-suOU0vObL44lMwd7rCA'
					}
				>
					<FaYoutube />
				</SocialButton>
				<SocialButton
					label={'Instagram'}
					href={'https://www.instagram.com/angels_on_watch/'}
				>
					<FaInstagram />
				</SocialButton>
			</Stack>
		</Flex>
	);
};
