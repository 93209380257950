import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useToast,
} from '@chakra-ui/react';

import { updateCenter } from '../../../services/CenterServices';
import { okToast, errorToast } from '../../../utils/toast';

export const RestoreModalCenters = ({
	isOpen,
	onCloseRestoreModalCenter,
	onCloseFormCreateCenterModal,
	inputCreateCenter,
	centerIdRestored,
	isReload,
	setIsReload,
}) => {
	const toast = useToast();
	const {
		name,
		cifDni,
		phoneNumber,
		email,
		address,
		cityProvinceTableId,
		postalCode,
	} = inputCreateCenter;
	const handleRestoreCenter = async () => {
		try {
			const response = await updateCenter({
				centerId: centerIdRestored,
				name,
				cifDni,
				phoneNumber,
				email,
				address,
				cityProvinceTableId,
				postalCode,
				notDeleted: true,
			});

			if (!response) {
				return errorToast(toast, 'Error al restaurar usuario');
			}

			onCloseRestoreModalCenter();
			onCloseFormCreateCenterModal();
			setIsReload(!isReload);
			okToast(toast, 'Usuario restaurado con éxito');
		} catch (error) {
			errorToast(toast, 'Error al restaurar el usuario', error.message);
		}
	};
	return (
		<>
			<Modal
				closeOnOverlayClick={false}
				isOpen={isOpen}
				onClose={onCloseRestoreModalCenter}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader display={'flex'} justifyContent={'center'}>
						Recuperación centro
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<Text>
							El centro que está intentando crear ya existía pero
							fue eliminado.{' '}
							<Text as='b'>¿Desea restaurarlo?</Text>
						</Text>
					</ModalBody>

					<ModalFooter
						display={'flex'}
						flexDirection={'row'}
						justifyContent={'center'}
					>
						<Button
							variant={'teal300'}
							onClick={handleRestoreCenter}
						>
							Restaurar
						</Button>
						<Button
							variant={'redAow'}
							onClick={onCloseRestoreModalCenter}
							ml={3}
						>
							Cancelar
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
