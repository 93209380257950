import {
	FormLabel,
	HStack,
	Radio,
	RadioGroup,
	Spacer,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';
import { formatDateToES } from '../../../../../utils/FormateDateToEs';

export const DataMedicalPatientProfile = ({ patient }) => {
	return (
		<Stack direction={{ base: 'column' }} alignItems={'center'} h={'100%'}>
			<VStack>
				<Text fontWeight={'bold'}>Enfermedades:</Text>
				<Text>{patient?.diseases || 'Sin datos'}</Text>
			</VStack>
			<Spacer />
			<VStack>
				<Text fontWeight={'bold'}>Diagnósticos:</Text>
				<Text>{patient?.diagnosis || 'Sin datos'}</Text>
			</VStack>
			<Spacer />
			<VStack>
				<Text fontWeight={'bold'}>Tratamientos:</Text>
				<Text>{patient?.treatments || 'Sin datos'}</Text>
			</VStack>
			<Spacer />
			<VStack>
				<Text fontWeight={'bold'}>Observaciones:</Text>
				<Text>{patient?.remarks || 'Sin datos'}</Text>
			</VStack>
			<Spacer />
			<HStack gap={6}>
				<VStack>
					<Text fontWeight={'bold'}>Fecha de entrada</Text>
					<Text>
						{formatDateToES(patient?.initiateDate) || 'Sin datos'}
					</Text>
				</VStack>
				<Spacer />
				<VStack>
					<Text fontWeight={'bold'}>Fecha de alta</Text>
					<Text>
						{formatDateToES(patient?.finallyDate) || 'Sin datos'}
					</Text>
				</VStack>
				<Spacer />
			</HStack>

			{/* <VStack>
				<Text fontWeight={'bold'}>Tratamientos vitales:</Text>
				<Text>{patient?.vitalTreatments || 'Sin datos'}</Text>
			</VStack>
			<Spacer /> */}

			<Stack direction={{ base: 'column', lg: 'row' }} gap={10} mt={10}>
				<HStack gap={10} justify={'center'}>
					<RadioGroup
						value={`${patient?.covidSymptoms}`}
						isDisabled
						defaultValue={'false'}
					>
						<FormLabel textAlign={'center'}>
							¿Síntomas covid?
						</FormLabel>
						<Stack direction='row' justify={'center'}>
							<Radio value={'true'}>Si</Radio>
							<Radio value={'false'}>No</Radio>
						</Stack>
					</RadioGroup>
					<RadioGroup
						value={`${patient?.pcr}`}
						isDisabled
						defaultValue={'false'}
					>
						<FormLabel textAlign={'center'}>¿PCR hecha?</FormLabel>
						<Stack direction='row' justify={'center'}>
							<Radio value={'true'}>Si</Radio>
							<Radio value={'false'}>No</Radio>
						</Stack>
					</RadioGroup>
				</HStack>
				<HStack gap={10} justify={'center'}>
					<RadioGroup
						value={`${patient?.antigen}`}
						isDisabled
						defaultValue={'false'}
					>
						<FormLabel textAlign={'center'}>
							¿Antígenos hecho?
						</FormLabel>
						<Stack direction='row' justify={'center'}>
							<Radio value={'true'}>Si</Radio>
							<Radio value={'false'}>No</Radio>
						</Stack>
					</RadioGroup>
					<RadioGroup
						value={`${patient?.testQuick}`}
						isDisabled
						defaultValue={'false'}
					>
						<FormLabel textAlign={'center'}>
							¿Test rápido hecho?
						</FormLabel>
						<Stack direction='row' justify={'center'}>
							<Radio value={'true'}>Si</Radio>
							<Radio value={'false'}>No</Radio>
						</Stack>
					</RadioGroup>
				</HStack>
			</Stack>
		</Stack>
	);
};
