import {
	Box,
	FormControl,
	FormLabel,
	Input,
	HStack,
	InputGroup,
	InputLeftAddon,
	Avatar,
	Button,
	Select,
	VStack,
	Stack,
	useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getProvincies } from '../../../../services/getProvincies';
import { getCities } from '../../../../services/getCities';
import { errorToast } from '../../../../utils/toast';

export const FormCenterData = ({
	inputCreateCenter,
	setCreateCenter,
	isUpdateData,
	dataCenterUpdate,
	clickOnSubmit,
}) => {
	const toast = useToast();
	const [provincies, setProvincies] = useState([]);
	const [cities, setCities] = useState([]);
	const [selectedImage, setSelectedImage] = useState('');

	useEffect(() => {
		isUpdateData && setCreateCenter(dataCenterUpdate);
	}, [dataCenterUpdate, isUpdateData, setCreateCenter]);

	useEffect(() => {
		const loadProvinces = async () => {
			const loadProvinces = await getProvincies();
			setProvincies(loadProvinces);
		};

		loadProvinces();
	}, []);

	const handleChange = e => {
		if (e.target.type === 'select-one' && e.target.name === 'province') {
			const loadCities = async () => {
				const loadCities = await getCities(e.target.value);
				setCities(loadCities);
			};
			loadCities();
		}
		if (e.target.type === 'file') {
			const file = e.target.files[0];
			const fileName = file.name.toLowerCase();
			const reader = new FileReader();

			reader.onload = function (e) {
				setSelectedImage(e.target.result);
			};

			reader.readAsDataURL(file);

			if (!fileName.endsWith('.jpg') && !fileName.endsWith('.png')) {
				return errorToast(toast, 'Solo se permite imagen .png o .jpg');
			}

			setCreateCenter({ ...inputCreateCenter, logoUrl: file });
		} else {
			const { name, value } = e.target;
			setCreateCenter({ ...inputCreateCenter, [name]: value });
		}
	};

	const checkValue = (value, isOptional = true) => {
		if (isOptional) return clickOnSubmit && !value;
		return clickOnSubmit && (!value || value.trim() === '');
	};

	return (
		<Stack
			direction={{ base: 'column', xl: 'row' }}
			alignItems={'center'}
			gap={{ base: 5, xl: 0 }}
		>
			<FormControl id='userName' w={'30%'}>
				<VStack
					direction={['column', 'row']}
					justify={'center'}
					alignItems={'center'}
					spacing={1}
				>
					<Box>
						<Avatar
							size='2xl'
							src={selectedImage}
							bgSize={'cover'}
						></Avatar>
					</Box>
					<Stack
						w={{ base: '100%', xl: '50%' }}
						direction={'column'}
						justify={'center'}
						alignItems={'center'}
					>
						<Input
							type='file'
							accept='image/*'
							onChange={handleChange}
							style={{ display: 'none' }}
							id='logoUrl'
						/>
						<FormLabel htmlFor='logoUrl'>
							<Button
								as='span'
								w='150px'
								cursor={'pointer'}
								bg={'teal300'}
								transition={'0.5s'}
								color={'white'}
								_hover={{
									bg: 'gray.600',
								}}
							>
								Cargar logo
							</Button>
						</FormLabel>
					</Stack>
				</VStack>
			</FormControl>
			<VStack w={'90%'}>
				<VStack>
					<Stack w={'100%'}>
						<HStack>
							<Box w={'100%'}>
								<FormControl
									id='name'
									isRequired
									isInvalid={checkValue(
										inputCreateCenter.name,
										false,
									)}
								>
									<FormLabel>Nombre del centro</FormLabel>
									<Input
										name='name'
										type='text'
										errorBorderColor='crimson'
										value={inputCreateCenter?.name}
										onChange={handleChange}
									/>
								</FormControl>
							</Box>
							<Box>
								<FormControl
									id='cifDni'
									isRequired
									isInvalid={
										clickOnSubmit &&
										!inputCreateCenter.cifDni
									}
								>
									<FormLabel>CIF / DNI</FormLabel>
									<Input
										name='cifDni'
										type='text'
										maxLength={9}
										errorBorderColor='crimson'
										value={inputCreateCenter?.cifDni}
										onChange={handleChange}
									/>
								</FormControl>
							</Box>
						</HStack>
						<HStack>
							<Box w={'100%'}>
								<FormControl
									id='phoneNumber'
									isRequired
									isInvalid={checkValue(
										inputCreateCenter.phoneNumber,
										false,
									)}
								>
									<FormLabel>Teléfono</FormLabel>
									<Input
										name='phoneNumber'
										type='text'
										errorBorderColor='crimson'
										value={inputCreateCenter?.phoneNumber}
										onChange={handleChange}
									/>
								</FormControl>
							</Box>
							<Box w={'100%'}>
								<FormControl
									id='email'
									isRequired
									isInvalid={
										clickOnSubmit &&
										!inputCreateCenter.email
									}
								>
									<FormLabel>Correo electrónico</FormLabel>
									<Input
										name='email'
										type='email'
										errorBorderColor='crimson'
										value={inputCreateCenter?.email}
										onChange={handleChange}
									/>
								</FormControl>
							</Box>
						</HStack>
					</Stack>
					<Stack w={'100%'}>
						<HStack>
							<Box w={'100%'}>
								<FormControl
									id='address'
									isRequired
									isInvalid={checkValue(
										inputCreateCenter.address,
										false,
									)}
								>
									<FormLabel>Dirección</FormLabel>
									<Input
										name='address'
										type='text'
										errorBorderColor='crimson'
										value={inputCreateCenter?.address}
										onChange={handleChange}
									/>
								</FormControl>
							</Box>
						</HStack>
						<Stack direction={{ base: 'column', sm: 'row' }}>
							<FormControl
								id='province'
								isRequired
								isInvalid={
									clickOnSubmit && !inputCreateCenter.province
								}
							>
								<FormLabel>Provincia</FormLabel>
								<Select
									name='province'
									errorBorderColor='crimson'
									onChange={handleChange}
									defaultValue={inputCreateCenter?.provinceId}
								>
									<option
										value={
											isUpdateData
												? inputCreateCenter?.provinceId
												: ''
										}
									>
										{isUpdateData
											? inputCreateCenter?.province
											: 'Seleccione una provincia'}
									</option>
									{provincies.map(provincie => (
										<option
											key={provincie?.id}
											value={provincie?.provinceId}
										>
											{provincie?.province}
										</option>
									))}
								</Select>
							</FormControl>

							<FormControl
								id='cityProvinceTableId'
								isRequired
								isInvalid={
									clickOnSubmit &&
									!inputCreateCenter.cityProvinceTableId
								}
							>
								<FormLabel>Ciudad</FormLabel>
								<Select
									name='cityProvinceTableId'
									errorBorderColor='crimson'
									defaultValue={inputCreateCenter?.cityId}
									onChange={handleChange}
								>
									<option
										value={
											isUpdateData
												? inputCreateCenter?.cityId
												: ''
										}
									>
										{isUpdateData
											? inputCreateCenter?.city
											: 'Seleccione una ciudad'}
									</option>
									{cities?.map(city => (
										<option key={city?.id} value={city?.id}>
											{city?.city}
										</option>
									))}
								</Select>
							</FormControl>

							<FormControl
								id='postalCode'
								isRequired
								isInvalid={checkValue(
									inputCreateCenter.postalCode,
									false,
								)}
							>
								<FormLabel>Código postal</FormLabel>
								<Input
									name='postalCode'
									type='text'
									errorBorderColor='crimson'
									value={inputCreateCenter?.postalCode}
									onChange={handleChange}
								/>
							</FormControl>
						</Stack>
					</Stack>
				</VStack>
				<Stack w={'100%'}>
					<HStack>
						<Box w={'100%'}>
							<FormControl id='websiteUrl'>
								<FormLabel>Sitio web</FormLabel>
								<InputGroup size='md'>
									<InputLeftAddon>https://</InputLeftAddon>
									<Input
										type='text'
										name='websiteUrl'
										value={inputCreateCenter?.websiteUrl}
										onChange={handleChange}
									/>
								</InputGroup>
							</FormControl>
						</Box>
					</HStack>
				</Stack>
			</VStack>
		</Stack>
	);
};
