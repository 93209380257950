import { Box, Button, Flex, Heading, Stack } from '@chakra-ui/react';
import { FormPageViewCenters } from './subcomponents/FormPageViewCenters';
import { useEffect, useState } from 'react';
import { getAllCenterByUser } from '../../../services/UserServices';

export const FormViewCenters = ({
	onClose,
	setUserIdSelect,
	isFinishGetAllPatients,
	setIsFinishGetAllPatients,
	onOpenModalUser,
	setStep,
	userIdSelect,
	isReloadCentersByUser,
	setIsReloadCentersByUser,
	setOnCloseStatus,
}) => {
	const [allCenters, setAllCenters] = useState([]);

	useEffect(() => {
		const getAllCenter = async () => {
			const centers = await getAllCenterByUser(userIdSelect);

			setAllCenters(centers);
			setIsFinishGetAllPatients(true);
		};

		getAllCenter();
	}, [setIsFinishGetAllPatients, userIdSelect, isReloadCentersByUser]);

	return (
		<Flex align={'center'} justify={'center'}>
			<Stack spacing={8} mx={'auto'} maxW={'lg'}>
				<Box rounded={'lg'} bg={'white'} p={3}>
					<Heading textAlign={'center'} fontSize={'md'} mb={5}>
						Centros Asignados
					</Heading>
					<FormPageViewCenters
						allCenters={allCenters}
						isFinishGetAllPatients={isFinishGetAllPatients}
						userIdSelect={userIdSelect}
						isReload={isReloadCentersByUser}
						setIsReload={setIsReloadCentersByUser}
					/>
					<Flex w='100%' gap={4}>
						<Button
							loadingText='Submitting'
							size='lg'
							w={'70%'}
							bg={'teal300'}
							color={'white'}
							transition={'0.5s'}
							_hover={{
								bg: 'gray.600',
								color: 'white',
							}}
							onClick={() => {
								onOpenModalUser();
								setOnCloseStatus(true);
								setStep(2);
							}}
						>
							Añadir más centros
						</Button>
						<Button
							loadingText='Submitting'
							size='lg'
							w={'30%'}
							bg={'gray.700'}
							color={'white'}
							transition={'0.5s'}
							_hover={{
								bg: 'gray.600',
								color: 'white',
							}}
							onClick={() => {
								onClose();
								setAllCenters([]);
								setUserIdSelect('');
								setIsFinishGetAllPatients(false);
							}}
						>
							Cerrar
						</Button>
					</Flex>
				</Box>
			</Stack>
		</Flex>
	);
};
