/**
 * @name formatLastname
 * @description Formats the lastname to display only the first letter in uppercase followed by a dot.
 * @param {string} lastname - The lastname to format
 * @returns {string} The formatted lastname with the first letter in uppercase followed by a dot.
 */

export const formatedLastName = lastname => {
	if (!lastname) return '';
	return lastname.charAt(0).toUpperCase() + '.';
};
