import { weekDays } from '../data/CONSTANTS';

export const formatReminderData = (reminder, inputUser) => {
	const { title, time, repeatAlarm, days } = reminder;
	let reminderSetting;

	const patientId = inputUser.patientId;

	if (title && time && days.length === 0) {
		reminderSetting = `${time}-1-1`;
	} else {
		const weekPattern = weekDays
			.map(day => (days.includes(day.initial) ? '1' : '0'))
			.join('');
		if (days.length === 7) {
			reminderSetting = `${time}-1-2`;
		} else {
			if (weekPattern === '0000000') {
				reminderSetting = `${time}-1-2`;
			} else {
				reminderSetting = `${time}-1-3-${weekPattern}`;
			}
		}
	}

	return {
		patientId,
		reminderSetting,
		reminderNumber: '1',
		reminderText: title,
		voiceData: null,
		active: true,
		notRepeat: !repeatAlarm,
	};
};
