import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../reducers/AuthReducer';
import SelectCenterReducer from '../reducers/SelectCenterReducer';

/**
 *
 * @name store
 * @description The redux store
 * @version 1.0.0
 *
 */
export const store = configureStore({
	reducer: {
		auth: authReducer,
		selectedCenter: SelectCenterReducer,
	},
});
