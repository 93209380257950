import {
	Button,
	HStack,
	Select,
	Stack,
	Text,
	VStack,
	useToast,
	useDisclosure,
	Spacer,
} from '@chakra-ui/react';
import { assingCamera, deleteCamera } from '../../../services/CameraServices';
import { errorToast, okToast } from '../../../utils/toast';
import { useEffect, useState } from 'react';
import { getAllCenter } from '../../../services/CenterServices';
import { getAllPacientsToCenter } from '../../../services/PatientServices';
import { useSelector } from 'react-redux';
import AlertDeleteCamera from './AlertDeleteCamera';

export const CardCamera = ({
	camera,
	onOpenModalCreateCamera,
	isReload,
	setIsReload,
	setIsUpdate,
	setInputCamera,
}) => {
	const { user } = useSelector(state => state.auth);
	const [isAssing, setIsAssing] = useState(false);
	const [centers, setCenters] = useState([]);
	const [patients, setPatients] = useState([]);
	const [inputSelectAssing, setInputSelectAssing] = useState({
		cameraId: camera?.cameraId,
		centerId: camera?.Center?.centerId,
		patientId: camera?.Patient?.patientId,
	});

	const {
		isOpen: isOpenAlert,
		onOpen: onOpenAlert,
		onClose: onCloseAlert,
	} = useDisclosure();
	const toast = useToast();
	const [needReload, setNeedReload] = useState(true);

	const handlerChangeAssing = e => {
		const { name, value } = e.target;
		const realValue = value === '' ? undefined : value;

		let data;

		if (name === 'centerId') {
			activateAssing();
			setNeedReload(true);
			data = {
				...inputSelectAssing,
				patientId: undefined,
				centerId: realValue,
			};
		} else {
			data = {
				...inputSelectAssing,
				[name]: realValue,
			};
		}

		setInputSelectAssing(data);
	};

	const activateAssing = () => {
		setIsAssing(true);
		getAllCenter().then(centers => setCenters(centers));

		if (inputSelectAssing?.centerId) allPatients();
	};

	// const allCenter = async () => {
	// 	const centers = await getAllCenter();
	// 	setCenters(centers);
	// };

	const allPatients = async () => {
		if (inputSelectAssing?.centerId === '') return;
		const patients = await getAllPacientsToCenter(
			inputSelectAssing?.centerId,
		);

		// Añadir el paciente que tiene asignado
		if (camera.Patient) {
			patients.push({
				patientId: camera.Patient.patientId,
				name: camera.Patient.name,
				lastname: camera.Patient.lastname,
				dni: camera.Patient.dni,
			});
		}
		setPatients(patients);
	};

	useEffect(() => {
		if (centers && isAssing && needReload) {
			setNeedReload(false);
			allPatients();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [centers, isAssing, needReload]);

	const updateCamera = () => {
		setInputCamera({
			cameraId: camera?.cameraId,
			model: camera?.model,
			serial: camera?.serial,
			ip: camera?.ip,
			user: camera?.user,
			password: camera?.password,
			centerId: camera?.Center?.centerId,
			centerName: camera?.Center?.name,
			patientId: camera?.Patient?.patientId,
			patientName: camera?.Patient?.name,
			patientLastname: camera?.Patient?.lastName,
			patientDni: camera?.Patient?.dni,
		});
		setIsUpdate(true);
		onOpenModalCreateCamera();
	};

	const onDelete = async () => {
		try {
			const deleted = await deleteCamera(camera?.cameraId);

			if (deleted) {
				okToast(toast, 'Camara borrada correctamente');
				setIsReload(!isReload);
			}
		} catch (error) {
			errorToast(toast, 'Error al borrar camara');
		}
	};

	const onSubmit = async () => {
		try {
			let dataToSend = inputSelectAssing;

			if (patients.length === 0) {
				dataToSend = {
					...dataToSend,
					patientId: undefined,
				};
				setInputSelectAssing(dataToSend);
			}
			const rest = await assingCamera(dataToSend);
			if (rest) {
				okToast(toast, 'Camara asignada correctamente');
				setIsAssing(false);
				setIsReload(!isReload);
			}
		} catch (error) {
			errorToast(toast, 'Error al asignar', error.message);
		}
	};

	return (
		<VStack
			w={'100%'}
			p={5}
			bg={'white'}
			justify={'space-between'}
			borderRadius={5}
		>
			<Stack direction={{ base: 'column', xl: 'row' }} w='100%'>
				<HStack bgColor={'gray.100'} p={2} px={4} rounded={'lg'}>
					{!isAssing ? (
						<>
							<Text fontWeight={'bold'}>Asignada a:</Text>
							<Text>
								{camera?.Center?.name
									? camera?.Center.name
									: 'Sin asignar'}
							</Text>
						</>
					) : (
						<>
							<Text fontWeight={'bold'}>Asignar:</Text>
							<Select
								placeholder={'Sin centro'}
								value={inputSelectAssing?.centerId}
								name='centerId'
								onChange={handlerChangeAssing}
								isDisabled={!user?.isAdmin}
							>
								{centers?.map(center => (
									<option
										key={center?.centerId}
										value={center?.centerId}
									>
										{center?.name}
									</option>
								))}
							</Select>
						</>
					)}
				</HStack>
				<HStack bgColor={'gray.100'} p={2} px={4} rounded={'lg'}>
					{!isAssing ? (
						<>
							<Text fontWeight={'bold'}>Paciente asignado:</Text>
							<Text>
								{camera?.Patient
									? `${camera?.Patient?.name} ${camera?.Patient?.lastname}(${camera?.Patient?.dni})`
									: 'Sin asignar'}
							</Text>
						</>
					) : (
						<>
							<Text fontWeight={'bold'}>Asignar:</Text>
							<Select
								placeholder={'Sin paciente'}
								value={inputSelectAssing?.patientId}
								name='patientId'
								onChange={handlerChangeAssing}
								isDisabled={inputSelectAssing?.centerId === ''}
							>
								{patients?.map(patient => (
									<option
										key={patient?.patientId}
										value={patient?.patientId}
									>
										{`${patient?.name} ${patient?.lastname} (${patient?.dni})`}
									</option>
								))}
							</Select>
						</>
					)}
				</HStack>
				<Spacer />
			</Stack>
			<Stack
				w={'100%'}
				direction={{ base: 'column', xl: 'row' }}
				justify={'space-between'}
			>
				<Stack
					direction={{ base: 'column', xl: 'row' }}
					gap={{ base: 0, xl: 5 }}
					alignItems={'left'}
				>
					<HStack>
						<Text fontWeight={'bold'}>Serial:</Text>
						<Text>{camera?.serial}</Text>
					</HStack>

					<HStack>
						<Text fontWeight={'bold'}>Modelo:</Text>
						<Text>{camera?.model}</Text>
					</HStack>

					<HStack>
						<Text fontWeight={'bold'}>IP:</Text>
						<Text>{camera?.ip}</Text>
					</HStack>
				</Stack>

				<HStack gap={2} justifyContent={'center'} alignItems={'center'}>
					{!isAssing && (
						<Button
							w={'full'}
							bg={'grayAow'}
							color={'white'}
							rounded={'md'}
							_hover={{
								transform: 'translateY(-2px)',
								boxShadow: 'lg',
							}}
							onClick={() => {
								activateAssing();
							}}
						>
							Asignar
						</Button>
					)}
					{isAssing ? (
						<>
							<Button
								w={'full'}
								bg={'teal300'}
								color={'white'}
								rounded={'md'}
								_hover={{
									transform: 'translateY(-2px)',
									boxShadow: 'lg',
								}}
								onClick={() => {
									onSubmit();
								}}
							>
								Guardar
							</Button>
							<Button
								w={'full'}
								bg={'red'}
								color={'white'}
								rounded={'md'}
								_hover={{
									transform: 'translateY(-2px)',
									boxShadow: 'lg',
								}}
								onClick={() => {
									setIsAssing(false);
									setInputSelectAssing({
										cameraId: camera?.cameraId,
										centerId: camera?.Center?.centerId,
										patientId: camera?.Patient?.patientId,
									});
								}}
							>
								Cancelar
							</Button>
						</>
					) : (
						<>
							{user?.isAdmin && (
								<>
									<Button
										w={'full'}
										bg={'teal300'}
										color={'white'}
										rounded={'md'}
										_hover={{
											transform: 'translateY(-2px)',
											boxShadow: 'lg',
										}}
										onClick={() => updateCamera()}
									>
										Editar
									</Button>
									<Button
										w={'full'}
										bg={'red'}
										color={'white'}
										rounded={'md'}
										_hover={{
											transform: 'translateY(-2px)',
											boxShadow: 'lg',
										}}
										onClick={() => {
											onOpenAlert();
										}}
									>
										Eliminar
									</Button>
								</>
							)}
							<AlertDeleteCamera
								isOpen={isOpenAlert}
								onClose={onCloseAlert}
								onDelete={onDelete}
							/>
						</>
					)}
				</HStack>
			</Stack>
		</VStack>
	);
};
