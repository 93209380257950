import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
} from '@chakra-ui/react';
import { FormPatientNotAssigned } from '../formCreateUser/subcomponents/FormPatientNotAssigned';

export const FormModalPatientNotAssigned = ({
	isOpen,
	onClose,
	userIdSelect,
	isReloadPatientsByUser,
	setIsReloadPatientsByUser,
	setIsFinishGetAllPatients,
	setNeedReload,
}) => {
	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody>
						<FormPatientNotAssigned
							onClose={onClose}
							userIdSelect={userIdSelect}
							isReloadPatientsByUser={isReloadPatientsByUser}
							setIsReloadPatientsByUser={
								setIsReloadPatientsByUser
							}
							setIsFinishGetAllPatients={
								setIsFinishGetAllPatients
							}
							setNeedReload={setNeedReload}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};
