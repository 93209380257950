import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	HStack,
	InputRightElement,
	Stack,
	Box,
	useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { updateUser } from '../../../../services/UserServices';
import { errorToast, okToast } from '../../../../utils/toast';

export const FormModalEditProfile = ({ isOpen, onClose, userData }) => {
	const [showPassword, setShowPassword] = useState(false);
	const [inputCreateUser, setInputCreateUser] = useState(userData);
	const toast = useToast();
	const [clickOnSubmit, setClickOnSubmit] = useState(false);

	const handleChange = e => {
		const { name, value } = e.target;
		setInputCreateUser({ ...inputCreateUser, [name]: value });
	};

	const checkValue = (value, isOptional = true) => {
		if (isOptional) return clickOnSubmit && !value;
		return clickOnSubmit && (!value || value.trim() === '');
	};

	const onSubmit = async () => {
		setClickOnSubmit(true);
		try {
			const { name, lastname, password, confPassword } = inputCreateUser;

			if (
				name.trim() === '' ||
				lastname.trim() === '' ||
				password !== confPassword
			) {
				return errorToast(
					toast,
					'Campos vacíos o contraseñas no coinciden',
					'Por favor revise los campos obligatorios',
				);
			}

			const updatedUser = await updateUser(inputCreateUser);
			if (!updatedUser) {
				errorToast(toast, 'Error al actualizar usuario');
				return;
			}

			okToast(toast, 'Usuario actualizado correctamente');
			onClose();
		} catch (error) {
			console.error(error);
			if (error === 'You are not authorized to update this user') {
				errorToast(toast, 'No está autorizado para editar el usuario');
			}
		} finally {
			setClickOnSubmit(false);
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='xl'>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Editar Perfil</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Stack spacing={4}>
						<HStack>
							<Box>
								<FormControl
									id='name'
									isRequired
									isInvalid={checkValue(
										inputCreateUser.name,
										false,
									)}
								>
									<FormLabel>Nombre:</FormLabel>
									<Input
										name='name'
										type='text'
										errorBorderColor='crimson'
										value={inputCreateUser?.name}
										onChange={handleChange}
									/>
								</FormControl>
							</Box>
							<Box>
								<FormControl
									id='lastname'
									isRequired
									isInvalid={checkValue(
										inputCreateUser.lastname,
										false,
									)}
								>
									<FormLabel>Apellidos:</FormLabel>
									<Input
										name='lastname'
										type='text'
										errorBorderColor='crimson'
										value={inputCreateUser?.lastname}
										onChange={handleChange}
									/>
								</FormControl>
							</Box>
						</HStack>
						<FormControl
							id='password'
							isInvalid={checkValue(
								inputCreateUser.password,
								false,
							)}
						>
							<FormLabel>Password:</FormLabel>
							<InputGroup>
								<Input
									name='password'
									type={showPassword ? 'text' : 'password'}
									value={inputCreateUser?.password}
									onChange={handleChange}
								/>
								<InputRightElement h={'full'}>
									<Button
										variant={'ghost'}
										onClick={() =>
											setShowPassword(!showPassword)
										}
									>
										{showPassword ? (
											<ViewIcon />
										) : (
											<ViewOffIcon />
										)}
									</Button>
								</InputRightElement>
							</InputGroup>
						</FormControl>
						<FormControl id='confPassword'>
							<FormLabel>Repetir password:</FormLabel>
							<InputGroup>
								<Input
									name='confPassword'
									type={showPassword ? 'text' : 'password'}
									value={inputCreateUser?.confPassword}
									onChange={handleChange}
								/>
								<InputRightElement h={'full'}>
									<Button
										variant={'ghost'}
										onClick={() =>
											setShowPassword(!showPassword)
										}
									>
										{showPassword ? (
											<ViewIcon />
										) : (
											<ViewOffIcon />
										)}
									</Button>
								</InputRightElement>
							</InputGroup>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						size='lg'
						w={'100%'}
						bg={'teal300'}
						transition={'0.5s'}
						_hover={{
							bg: 'gray.600',
							color: 'white',
						}}
						color={'white'}
						colorScheme='teal'
						onClick={onSubmit}
						isLoading={clickOnSubmit}
					>
						Actualizar
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
