import { useState } from 'react';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { FormCreatePatient } from '../formCreatePatient/FormCreatePatient';

export const FormModalPatient = ({
	onClose,
	isOpen,
	setInputUser,
	inputUser,
	isUpdate,
	setIsUpdate,
	isReload,
	setIsReload,
	updatePatients,
}) => {
	const [resetTrigger, setResetTrigger] = useState(false);

	const handleResetForm = () => {
		setInputUser({});
		setResetTrigger(!resetTrigger);
	};

	const handleClose = () => {
		updatePatients();
		setIsUpdate(false);
		handleResetForm();
		onClose();
	};

	return (
		<>
			<Modal
				size={'6xl'}
				closeOnOverlayClick={false}
				onClose={handleClose}
				isOpen={isOpen}
				isCentered
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						{isUpdate ? 'Editar' : 'Crear'} paciente
					</ModalHeader>
					<ModalCloseButton onClick={handleClose} />
					<ModalBody>
						<FormCreatePatient
							onClose={onClose}
							setInputUser={setInputUser}
							inputUser={inputUser}
							isUpdate={isUpdate}
							setIsUpdate={setIsUpdate}
							setIsReload={setIsReload}
							isReload={isReload}
							updatePatients={updatePatients}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};
