import {
	Text,
	Tag,
	TagLabel,
	IconButton,
	Tooltip,
	Stack,
	useToast,
	useDisclosure,
	Grid,
	GridItem,
	Box,
} from '@chakra-ui/react';
import { FaRegEye, FaRegEyeSlash, FaArrowUp } from 'react-icons/fa';
import { RxCross1 } from 'react-icons/rx';
import { useEffect, useState } from 'react';
import { convertDateAndTimeNow } from '../../../utils/ConvertDateNow';
import { calculateTimePassed } from '../../../utils/CalculateTimePassed';
import { deletedEvent, markAsReadEvent } from '../../../services/EventServices';
import { errorToast, okToast } from '../../../utils/toast';
import { ViewPatientModal } from '../../patients/modal/ViewPatientModal';
import { getPatient } from '../../../services/PatientServices';
import { AlertDeleteEvent } from './AlertDeleteEvent';

export const CardEvent = ({ event, setIsReload, isReload }) => {
	const {
		isOpen: isOpenModalViewPatient,
		onOpen: onOpenModalViewPatient,
		onClose: onCloseModalViewPatient,
	} = useDisclosure();
	const {
		isOpen: isOpenAlert,
		onOpen: onOpenAlert,
		onClose: onCloseAlert,
	} = useDisclosure();
	const toast = useToast();
	const [patient, setPatient] = useState({});
	const [activeAlert, setActiveAlert] = useState('white');
	const [value, setValue] = useState('');
	const [title, setTitle] = useState('');
	const [colorTag, setColorTag] = useState('white');
	const [bgColorTag, setBgColorTag] = useState('teal300');

	useEffect(() => {
		switch (event?.name) {
			case 'SHOCK':
				setValue(event?.value?.heartRate);
				setTitle('Pulsaciones anormales');
				setColorTag('black');
				setBgColorTag('white');
				setActiveAlert('red');
				break;
			case 'LOW_BATTERY':
				setValue(`${event?.value?.powerPercentage} %`);
				setTitle('Bateria baja');
				setColorTag('black');
				setBgColorTag('yellow');
				setActiveAlert('white');
				break;
			case 'FALLDOWN':
				setTitle('Caída');
				setColorTag('white');
				setBgColorTag('blue');
				setActiveAlert('red');
				break;
			case 'GEO_FENCE':
				setTitle('Aviso geovalla');
				setValue(
					`${event?.value?.distance} metros fuera del perímetro`,
				);
				setColorTag('black');
				setBgColorTag('white');
				setActiveAlert('red');
				break;
			case 'MEDICINE_REMINDER':
				setTitle('Recordatorio medicación');
				setValue(event?.value?.reminderText || '');
				setActiveAlert('white');
				break;
			case 'OXYGEN':
				setTitle('Saturación oxígeno baja');
				setValue(event?.value?.oxygen);
				setColorTag('black');
				setBgColorTag('white');
				setActiveAlert('red');
				break;
			case 'BLOOD_PRESSURE':
				setTitle('Alerta presión arterial');
				setValue(
					`${event?.value?.bloodPressure?.SYSTOLIC}/${event?.value?.bloodPressure?.DIASTOLIC} mmHg`,
				);
				setColorTag('black');
				setBgColorTag('white');
				setActiveAlert('red');
				break;
			case 'TEMPERATURE':
				setTitle('Alerta Temperatura');
				setValue(`${event?.value?.temperature} ºC`);
				setColorTag('black');
				setBgColorTag('white');
				setActiveAlert('red');
				break;
			case 'POWER_OFF':
				setTitle('Apagado');
				setActiveAlert('white');
				break;
			case 'WATCH_REMOVED':
				setTitle('Desprendido');
				setActiveAlert('white');
				break;
			case 'WATCH_CONNECTED':
				setTitle('Conectado');
				setActiveAlert('white');
				break;
			case 'WATCH_DISCONNECTED':
				setTitle('Desconectado');
				setActiveAlert('white');
				break;
			default:
				break;
		}
	}, [
		event?.name,
		event?.value?.heartRate,
		event?.value?.powerPercentage,
		event?.value?.bloodPressure,
		event?.value?.reminderText,
		event?.value?.temperature,
		event?.value?.distance,
		event?.value?.oxygen,
		isReload,
	]);

	const markAsRead = async () => {
		try {
			await markAsReadEvent(event?.eventId);
			setIsReload(true);
		} catch (error) {
			errorToast(toast, 'Error al marcar como leído el evento', error);
		}
	};

	const deleteEvent = async () => {
		try {
			await deletedEvent(event?.eventId);
			setIsReload(true);
			okToast(toast, 'Evento eliminado');
		} catch (error) {
			errorToast(toast, 'Error al eliminar el evento', error);
		}
	};

	const openProfilePatient = () => {
		getPatient(event?.Patient?.patientId)
			.then(data => {
				setPatient(data);
				onOpenModalViewPatient();
			})
			.catch(error => {
				errorToast(toast, 'Error al obtener el paciente', error);
			});
	};

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	return (
		<>
			<Box position='fixed' bottom='20px' right='20px'>
				<IconButton
					aria-label='Scroll to top'
					icon={<FaArrowUp />}
					onClick={scrollToTop}
				/>
			</Box>
			<Grid
				w={'100%'}
				h={'100%'}
				templateColumns={{
					base: '1fr',
					xl: 'repeat(9, 2fr)',
				}}
				templateRows={{
					base: 'repeat(5, 1fr)',
					xl: 'repeat(1, 1fr)',
				}}
				bg={activeAlert}
				opacity={event?.read === true ? '0.5' : '1'}
				p={3}
				gap={4}
				alignItems={'center'}
				color={activeAlert === 'red' ? 'white' : 'black'}
				borderRadius={5}
			>
				<GridItem
					colSpan={{ base: 1, xl: 2 }}
					textAlign={{ base: 'center', xl: 'left' }}
				>
					<Tag
						size={'lg'}
						borderRadius='full'
						bgColor={bgColorTag}
						color={colorTag}
					>
						<TagLabel fontSize={'md'} textAlign={'center'}>
							{title}
						</TagLabel>
					</Tag>
				</GridItem>
				<GridItem
					colSpan={{ base: 1, xl: 2 }}
					textAlign={{ base: 'center', xl: 'left' }}
				>
					<Text
						fontSize={{ base: 'xl', xl: 'sm' }}
						fontWeight={'bold'}
					>
						{convertDateAndTimeNow(event?.createdAt)}
					</Text>
					<Text fontSize={'sm'}>
						hace {calculateTimePassed(event?.createdAt)}
					</Text>
				</GridItem>
				<GridItem
					colSpan={{ base: 1, xl: 2 }}
					onClick={openProfilePatient}
					cursor={'pointer'}
					textAlign={{ base: 'center', xl: 'left' }}
				>
					<Text fontWeight={'bold'}>
						{event?.Patient?.name} {event?.Patient?.lastname}
					</Text>
					<Text fontSize={'sm'}>IMEI: {event?.SmartWatch?.imei}</Text>
				</GridItem>
				<GridItem
					colSpan={{ base: 1, xl: 2 }}
					textAlign={{ base: 'center', xl: 'center' }}
				>
					{value !== '' && <Text fontSize={'sm'}>{value}</Text>}
				</GridItem>
				<GridItem colSpan={{ base: 1, xl: 1 }}>
					<Stack direction={'row'} justifyContent={'center'}>
						<Tooltip
							label={
								!event?.read
									? 'Marcar como leído'
									: 'Desmarcar como leído'
							}
						>
							<IconButton
								aria-label='Mark as read'
								icon={
									!event?.read ? (
										<FaRegEye />
									) : (
										<FaRegEyeSlash />
									)
								}
								onClick={markAsRead}
							/>
						</Tooltip>
						<Tooltip label={'Borrar'}>
							<>
								<IconButton
									aria-label='Delete'
									icon={<RxCross1 />}
									onClick={() => {
										onOpenAlert();
									}}
								/>
								<AlertDeleteEvent
									isOpen={isOpenAlert}
									onClose={onCloseAlert}
									onDelete={() => deleteEvent(event?.eventId)}
								/>
							</>
						</Tooltip>
					</Stack>
				</GridItem>
			</Grid>
			<ViewPatientModal
				patient={patient}
				onClose={onCloseModalViewPatient}
				isOpen={isOpenModalViewPatient}
			/>
		</>
	);
};
