import { Box, Button, Icon, Select, VStack } from '@chakra-ui/react';
import {
	BsBell,
	BsBuildings,
	BsCameraVideo,
	BsHouse,
	BsPeople,
	BsPerson,
	BsSmartwatch,
} from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCenter } from '../../../../redux/reducers/SelectCenterReducer';
import { useEffect } from 'react';

export const NavItemSup = ({ onClose }) => {
	const { user } = useSelector(state => state.auth);
	const { selectCenter } = useSelector(state => state.selectedCenter);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const hadlerNavigate = path => {
		navigate(path);
		onClose();
	};

	const onChangeSelectCenter = e => {
		dispatch(getCenter({ userId: user.userId, centerId: e.target.value }));
		navigate('/home');
	};

	useEffect(() => {
		if (user?.centers?.length === 1) {
			dispatch(
				getCenter({
					userId: user.userId,
					centerId: user.centers[0].centerId,
				}),
			);
		}
	}, [user, dispatch]);

	return (
		<VStack px={5}>
			{user?.isAdmin === false && (
				<Select
					variant='flushed'
					value={selectCenter?.centerId || ''}
					placeholder='Selecciona un centro'
					onChange={onChangeSelectCenter}
					color={'teal300'}
				>
					{user?.centers?.length === 0 ? (
						<option value=''>No hay centros</option>
					) : (
						user?.centers?.map(center => {
							return (
								<option
									key={center.centerId}
									value={center.centerId}
									style={{
										color: 'black',
									}}
								>
									{center.name}
								</option>
							);
						})
					)}
				</Select>
			)}

			<Button
				variant={'aowSidebar'}
				onClick={() => hadlerNavigate('/home')}
			>
				<Icon mr='4' fontSize='16' as={BsHouse} />
				<Box as='span' flex='1' textAlign='left'>
					Inicio
				</Box>
			</Button>
			{user?.isAdmin && (
				<Button
					variant='aowSidebar'
					onClick={() => hadlerNavigate('/centers')}
				>
					<Icon mr='4' fontSize='16' as={BsBuildings} />
					<Box as='span' flex='1' textAlign='left'>
						Centros
					</Box>
				</Button>
			)}
			{(user?.isAdmin || selectCenter) && (
				<>
					{(selectCenter?.role === 'admin' || user?.isAdmin) && (
						<Button
							variant='aowSidebar'
							onClick={() => hadlerNavigate('/users')}
						>
							<Icon mr='4' fontSize='16' as={BsPerson} />
							<Box as='span' flex='1' textAlign='left'>
								Usuarios
							</Box>
						</Button>
					)}

					<Button
						variant='aowSidebar'
						onClick={() => hadlerNavigate('/events')}
					>
						<Icon mr='4' fontSize='16' as={BsBell} />
						<Box as='span' flex='1' textAlign='left'>
							Eventos
						</Box>
					</Button>
					<Button
						variant='aowSidebar'
						onClick={() => hadlerNavigate('/patients')}
					>
						<Icon mr='4' fontSize='16' as={BsPeople} />
						<Box as='span' flex='1' textAlign='left'>
							Pacientes
						</Box>
					</Button>
					{(user?.isAdmin || selectCenter?.role !== 'user') && (
						<>
							<Button
								variant='aowSidebar'
								onClick={() => hadlerNavigate('/devices')}
							>
								<Icon mr='4' fontSize='16' as={BsSmartwatch} />
								<Box as='span' flex='1' textAlign='left'>
									Dispositivos
								</Box>
							</Button>
							<Button
								variant='aowSidebar'
								onClick={() => hadlerNavigate('/cameras')}
							>
								<Icon mr='4' fontSize='16' as={BsCameraVideo} />
								<Box as='span' flex='1' textAlign='left'>
									Cámaras
								</Box>
							</Button>
						</>
					)}
				</>
			)}
		</VStack>
	);
};
