import axios from 'axios';

/**
 * @name getCountEvent
 * @description Get the count of events
 * @returns
 */
export const getCountEvent = async () => {
	try {
		const response = await axios.get(`/events/count/all`);

		return response.data.message;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getAllEventsNotRead
 * @description Get all events not read
 * @returns
 */
export const getAllEventsNotRead = async () => {
	try {
		const response = await axios.get(`/events/all`);

		return response.data.message;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name markAsReadEvent
 * @description Mark as read an event
 * @param {string} eventId
 * @returns
 */
export const markAsReadEvent = async eventId => {
	try {
		const response = await axios.patch(`/events/read/${eventId}`);

		return response.data.message;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name markAsReadAllEvent
 * @description Mark all events as read.
 * @returns
 *  @creator Daniel Moreno Acedo, Web Developer at Unknown Gravity
 */
export const markAsReadAllEvent = async () => {
	try {
		const response = await axios.patch('/events/setRead/all/');

		return response.data.message;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name deleteEvent
 * @description Delete an event
 * @param {string} eventId
 * @returns
 */
export const deletedEvent = async eventId => {
	try {
		const response = await axios.delete(`/events/delete/${eventId}`);

		return response.data.message;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name deleteAllReadEvents
 * @description Delete all read events
 * @returns {string}
 * @creator Daniel Moreno Acedo, Web Developer at Unknown Gravity
 */
export const deleteAllReadEvents = async () => {
	try {
		const response = await axios.delete('events/patient/delete/allRead');

		return response.data.message;
	} catch (error) {
		throw error.response.data.message;
	}
};
