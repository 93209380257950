import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import AppRoutes from './routes/AppRoutes';
import { theme } from './theme/awoTheme';
import './theme/app.css';
import { useSelector } from 'react-redux';

function App() {
	const { isSuccess } = useSelector(state => state.auth);

	const extendedTheme = extendTheme({
		...theme,
		styles: {
			global: {
				body: {
					backgroundColor: isSuccess ? 'white' : 'black',
				},
			},
		},
	});

	return (
		<ChakraProvider theme={extendedTheme}>
			<AppRoutes />
		</ChakraProvider>
	);
}

export default App;
