export const heartRate = {
	min: 60,
	max: 101,
};

export const oxygen = {
	min: 70,
	max: 101,
};

export const bloodPressure = {
	min: 0,
	max: 200,
};
