import axios from 'axios';
import { decodeToken, encodeToken } from '../utils/TokenUtils';

/**
 *
 * @name createCenter
 * @description Creates a new center
 * @param {object} data The data object
 * @returns {token}
 *
 */
export const createCenter = async data => {
	try {
		const token = encodeToken(data);
		const formData = new FormData();
		formData.append('file', data.logoUrl);
		formData.append('token', token);

		const response = await axios.post('/center/create', formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});

		return response.data;
	} catch (error) {
		throw error.response.data;
	}
};

/**
 *
 * @name getAllCenter
 * @description Get all centers data.
 * @param {object} data The data object
 * @returns {token}
 *
 */
export const getAllCenter = async () => {
	try {
		const response = await axios.get('/center/all');
		const { token } = response.data.data;
		const centers = decodeToken(token);

		return centers;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name deletedCenter
 * @description Deleted a one center
 * @param {string} cifDni The cifDni center
 * @returns {token}
 *
 */
export const deletedCenter = async centerId => {
	try {
		const response = await axios.delete(`/center/delete/${centerId}`);

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name updateCenter
 * @description Creates a new center
 * @param {object} data The data object
 * @returns {token}
 *
 */
export const updateCenter = async data => {
	try {
		const { centerId } = data;
		const token = encodeToken(data);
		const formData = new FormData();
		formData.append('file', data.logoUrl);
		formData.append('token', token);

		const response = await axios.patch(
			`/center/update/${centerId}`,
			formData,
			{
				headers: { 'Content-Type': 'multipart/form-data' },
			},
		);

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};
