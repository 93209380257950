import axios from 'axios';

/**
 *
 * @name getProvincies
 * @description Request all provincies.
 * @returns {Array} provincies.
 *
 */
export const getProvincies = async () => {
	try {
		const response = await axios.get(`/provincies`);

		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};
