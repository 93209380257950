import {
	Flex,
	FormControl,
	FormLabel,
	Input,
	Textarea,
	VStack,
	Stack,
	useBreakpointValue,
} from '@chakra-ui/react';
import { formatDateTime } from '../../../../utils/FormatDateTime';
import { formatDate } from '../../../../utils/FormatDate';

export const FormOtherData = ({ handleChangeUser, inputUser }) => {
	const stackDirection = useBreakpointValue({ base: 'column', md: 'row' });

	return (
		<Flex gap={5}>
			<VStack w={'100%'}>
				<Stack w={'100%'} direction={stackDirection} spacing={4}>
					<FormControl>
						<FormLabel htmlFor='initiateDate' fontWeight={'normal'}>
							Fecha de entrada:
						</FormLabel>
						<Input
							id='initiateDate'
							name='initiateDate'
							type='date'
							value={
								inputUser?.initiateDate
									? formatDate(inputUser.initiateDate)
									: ''
							}
							onChange={handleChangeUser}
						/>
					</FormControl>
					<FormControl>
						<FormLabel htmlFor='finallyDate' fontWeight={'normal'}>
							Fecha de alta:
						</FormLabel>
						<Input
							id='finallyDate'
							name='finallyDate'
							type='datetime-local'
							min={
								inputUser?.initiateDate
									? formatDateTime(inputUser.initiateDate)
									: ''
							}
							isDisabled={!inputUser?.initiateDate}
							value={
								inputUser?.finallyDate
									? formatDateTime(inputUser.finallyDate)
									: ''
							}
							onChange={handleChangeUser}
						/>
					</FormControl>
				</Stack>

				<FormControl>
					<FormLabel htmlFor='remarks' fontWeight={'normal'}>
						Observaciones
					</FormLabel>
					<Textarea
						id='remarks'
						name='remarks'
						h={'150px'}
						resize={'none'}
						value={inputUser?.remarks || ''}
						onChange={handleChangeUser}
					/>
				</FormControl>
			</VStack>
		</Flex>
	);
};
