import { IS_PRODUCTION_API_ROUTE } from '../data/CONSTANTS';

export const checkEmail = email => {
	const re = /\S+@\S+\.\S+/;
	return re.test(email);
};

export const checkPostalCode = postalCode => {
	let check = false;

	if (postalCode.length === 5) {
		check = true;
	}
	return check;
};

export const checkCifDni = cifDni => {
	if (IS_PRODUCTION_API_ROUTE === 'http://localhost:3333') return true;
	if (cifDni.length > 9 || cifDni.length < 1) return false;

	// Validación si es un DNI
	if (isNaN(cifDni[8]) && cifDni.length === 9) {
		const validLetters = 'TRWAGMYFPDXBNJZSQVHLCKE';
		const dni = parseInt(cifDni.slice(0, 8));
		const letter = cifDni.slice(-1);
		const calculatedLetter = validLetters[dni % 23];
		return letter === calculatedLetter;
	}

	// Validación si es un CIF
	if (isNaN(cifDni[0]) && cifDni.length === 9) return true;

	return false;
};

export const checkImei = imei => {
	if (imei.length === 10) return true;
};
