import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux/store/store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// ----------------- Cookie login -----------------
import axios from 'axios';
import { API_ROUTE } from './data/CONSTANTS';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = API_ROUTE;
// ------------------------------------------------

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
	<BrowserRouter>
		<Provider store={store}>
			<App />
		</Provider>
	</BrowserRouter>,
);

serviceWorkerRegistration.register({
	onUpdate: async registration => {
		// Corremos este código si hay una nueva versión de nuestra app
		// Detalles en: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
		if (registration && registration.waiting) {
			await registration.unregister();
			registration.waiting.postMessage({ type: 'SKIP_WAITING' });
			// Des-registramos el SW para recargar la página y obtener la nueva versión. Lo cual permite que el navegador descargue lo nuevo y que invalida la cache que tenía previamente.
			window.location.reload();
		}
	},
});
