import axios from 'axios';
import { decodeToken, encodeToken } from '../utils/TokenUtils';

/**
 * @name getAllCameras
 * @description This function returns all cameras
 * @return {array} cameras - An array of cameras
 */
export const getAllCameras = async () => {
	try {
		const response = await axios.get('/camera/all');
		const { token } = response.data.data;
		const data = decodeToken(token);

		return data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getCameraById
 * @description This function returns a camera by its id
 * @param {string} id
 * @return {object} camera - A camera object
 */
export const getCameraById = async cameraId => {
	try {
		const response = await axios.get(`/camera/id/${cameraId}`);
		const { token } = response.data.data;
		const data = decodeToken(token);

		return data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getAllCamerasToCenter
 * @description This function returns all cameras to center
 * @param {string} centerId
 * @return {array} data - An array of cameras
 */
export const getAllCamerasToCenter = async centerId => {
	try {
		const response = await axios.get(`/camera/all/${centerId}`);
		const { token } = response.data.data;
		const data = decodeToken(token);

		return data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getCameraToSerial
 * @description This function returns a camera by its serial
 * @param {string} serial
 * @returns {object} data - A camera object
 */
export const getCameraToSerial = async serial => {
	try {
		const response = await axios.get(`/camera/serial/${serial}`);
		const { token } = response.data.data;
		const data = decodeToken(token);

		return data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name createCameras
 * @description This function creates a camera
 * @param {object} data
 * @return
 */
export const createCameras = async data => {
	try {
		const token = encodeToken(data);
		const response = await axios.post('/camera/create', { token });

		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name assingCamera
 * @description This function assigns a camera
 * @param {object} data
 * @returns
 */
export const assingCamera = async data => {
	try {
		const { cameraId, centerId, patientId } = data;

		// if (!centerId) throw new Error('Debe seleccionar un centro');

		const response = await axios.patch(
			`/camera/assing/patientCenter/${cameraId}`,
			{
				centerId,
				patientId,
			},
		);

		// if (patientId) {
		// 	responseCenter = await axios.patch(
		// 		`/camera/assign/center/${cameraId}`,
		// 		{ centerId },
		// 	);
		// 	responsePatient = await axios.patch(
		// 		`/camera/assign/patient/${cameraId}`,
		// 		{ patientId },
		// 	);
		// } else {
		// 	responseCenter = await axios.patch(
		// 		`/camera/assign/center/${cameraId}`,
		// 		{ centerId },
		// 	);
		// }

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name updateCamera
 * @description This function updates a camera
 * @param {string} id
 * @param {object} data
 * @return
 */
export const updateCamera = async (cameraId, data) => {
	try {
		const token = encodeToken(data);
		const response = await axios.patch(`/camera/update/${cameraId}`, {
			token,
		});

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name deleteCamera
 * @description This function deletes a camera
 * @param {string} id
 * @return
 */
export const deleteCamera = async cameraId => {
	try {
		const response = await axios.delete(`/camera/delete/${cameraId}`);

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getAllModels
 * @description This function returns all models
 * @return {array} models - An array of models
 */
export const getAllModels = async () => {
	try {
		const response = await axios.get('/camera/models');
		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};
