import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Select,
	Stack,
	useToast,
} from '@chakra-ui/react';
import { MultiSelect } from 'chakra-multiselect';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPacientsToCenter } from '../../../../services/PatientServices';
import { formatedLastName } from '../../../../utils/FormatedLastName';
import {
	assingCenterAndPatients,
	getAllCenterByUser,
} from '../../../../services/UserServices';
import { errorToast, okToast } from '../../../../utils/toast';

export const FormPatientNotAssigned = ({
	onClose,
	userIdSelect,
	setIsReloadPatientsByUser,
	isReloadPatientsByUser,
	setIsFinishGetAllPatients,
	setNeedReload,
}) => {
	const { user } = useSelector(state => state.auth);
	const { selectCenter } = useSelector(state => state.selectedCenter);
	const toast = useToast();
	const [inputLinkCenter, setLinkCenter] = useState({
		centerId: '',
		userId: userIdSelect,
		patients: [],
	});
	const [centers, setCenters] = useState([]);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		const AllCenters = async () => {
			setCenters(await getAllCenterByUser(userIdSelect));
		};

		if (selectCenter?.role === 'admin') {
			setLinkCenter({
				...inputLinkCenter,
				centerId: selectCenter.centerId,
			});
			AllPacientsToCenter(selectCenter.centerId);
		} else {
			AllCenters();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const AllPacientsToCenter = async centerId => {
		const allPacients = [];

		const pacientsData = await getPacientsToCenter(centerId, userIdSelect);

		pacientsData.forEach(patient => {
			allPacients.push({
				label: `${patient.name} ${formatedLastName(patient.lastname)} (${patient.dni})`,
				value: patient.patientId,
			});
		});

		setOptions(allPacients);
	};

	const onChangeCenters = e => {
		const { value } = e.target;

		if (e.target.value !== '') {
			setLinkCenter({ ...inputLinkCenter, centerId: value });
			AllPacientsToCenter(value);
		}
	};

	const onChangePatients = e => {
		setLinkCenter({ ...inputLinkCenter, patients: e });
	};

	const assingToUser = async () => {
		try {
			delete inputLinkCenter.centerId;
			const result = await assingCenterAndPatients(inputLinkCenter);

			if (result) {
				okToast(toast, 'Asignación correcta');
				setIsReloadPatientsByUser(!isReloadPatientsByUser);
				setIsFinishGetAllPatients(false);
				setNeedReload(false);
				onClose();
			}
		} catch (error) {
			return errorToast(toast, 'Error al asignar pacientes', error);
		}
	};

	const onSubmitAssingCenterAndPacients = () => {
		const { centerId } = inputLinkCenter;

		if (!centerId) {
			return errorToast(toast, 'Selecciona un centro');
		}

		assingToUser();
	};

	return (
		<Flex align={'center'} justify={'center'}>
			<Stack spacing={8} mx={'auto'} maxW={'lg'}>
				<Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
					<FormControl id='centerId' isRequired>
						<FormLabel>Centro:</FormLabel>
						<Select
							name='centerId'
							type='select'
							value={inputLinkCenter.centerId}
							cursor={'pointer'}
							onChange={onChangeCenters}
							isDisabled={
								selectCenter?.role === 'admin' && !user?.isAdmin
							}
							placeholder={
								selectCenter?.role === 'admin'
									? selectCenter?.name
									: 'Selecciona un centro'
							}
						>
							{centers?.map(center => {
								return (
									<option
										key={center?.centerId}
										value={center?.centerId}
									>
										{center?.name}
									</option>
								);
							})}
						</Select>
					</FormControl>

					<FormControl
						id='patients'
						isRequired
						isDisabled={inputLinkCenter === ''}
					>
						<FormLabel>Pacientes:</FormLabel>
						<MultiSelect
							placeholder='Selecciona paciente/pacientes'
							options={options}
							value={inputLinkCenter.patients}
							onChange={onChangePatients}
							selectionVisibleIn={inputLinkCenter.patients}
						/>
					</FormControl>
				</Stack>
				<Box rounded={'lg'} bg={'white'} p={3}>
					<Flex w='100%' justifyContent={'space-between'} gap={4}>
						<Button
							size='lg'
							w={'100%'}
							bg={'red'}
							color={'white'}
							transition={'0.5s'}
							_hover={{
								bg: 'gray.600',
								color: 'white',
							}}
							onClick={() => {
								onClose();
							}}
						>
							Cancelar
						</Button>

						<Button
							loadingText='Submitting'
							size='lg'
							w={'100%'}
							bg={'teal300'}
							color={'white'}
							transition={'0.5s'}
							_hover={{
								bg: 'gray.600',
								color: 'white',
							}}
							onClick={onSubmitAssingCenterAndPacients}
						>
							Asignar pacientes
						</Button>
					</Flex>
				</Box>
			</Stack>
		</Flex>
	);
};
