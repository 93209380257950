import { Box, Heading, Image, Stack, Text, Link } from '@chakra-ui/react';

export const Home = () => {
	return (
		<Box
			h={{
				base: 'calc(100vh - 187px)',
				sm: 'calc(100vh - 189px)',
				lg: 'calc(100vh - 220px)',
			}}
			display={'flex'}
			justifyContent={'center'}
			alignItems={'center'}
		>
			<Stack spacing={6} textAlign={'center'}>
				<Heading fontSize={'5xl'} mb={6}>
					Bienvenido a
				</Heading>
				<Image src='./logoBlack.png' />
				<Text fontSize='xl' as='b'>
					Si quiere contratar un reloj u obtener más información{' '}
					<Link
						href='https://www.angelsonwatch.com/'
						isExternal
						color='teal.500'
					>
						visite este enlace
					</Link>
				</Text>
			</Stack>
		</Box>
	);
};
