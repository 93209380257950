import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { FormCreateWatch } from '../formCreateWatch/FormCreateWatch';
import { initialWatch } from '../../../data/initData';
export const FormModalWatch = ({
	onClose,
	isOpen,
	isReload,
	setIsReload,
	inputWatch,
	setInputWatch,
	isUpdate,
	setIsUpdate,
}) => {
	const handleClose = () => {
		setIsUpdate(false);
		setInputWatch(initialWatch);
		onClose();
	};
	return (
		<Modal
			size={'2xl'}
			closeOnOverlayClick={false}
			onClose={handleClose}
			isOpen={isOpen}
			isCentered
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					{isUpdate ? 'Editar dispositivo' : 'Crear dispositivo'}
				</ModalHeader>
				<ModalCloseButton
					onClick={() => {
						setInputWatch(initialWatch);
					}}
				/>
				<ModalBody>
					<FormCreateWatch
						onClose={handleClose}
						inputWatch={inputWatch}
						setInputWatch={setInputWatch}
						isReload={isReload}
						setIsReload={setIsReload}
						isUpdate={isUpdate}
						setIsUpdate={setIsUpdate}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
