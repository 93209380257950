import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useToast,
} from '@chakra-ui/react';

import { updatePatient } from '../../../services/PatientServices';
import { okToast, errorToast } from '../../../utils/toast';

export const RestoreModalPatients = ({
	isOpen,
	onCloseRestoreModalPatient,
	onCloseFormCreatePatientModal,
	inputUser,
	patientIdRestored,
	isReload,
	setIsReload,
}) => {
	const toast = useToast();
	const { cifDni, name, lastname, centerId } = inputUser;
	const handleRestorePatient = async () => {
		try {
			const response = await updatePatient({
				patientId: patientIdRestored,
				cifDni,
				name,
				lastname,
				centerId,
				notDeleted: true,
			});

			if (!response) {
				return errorToast(toast, 'Error al restaurar paciente');
			}

			onCloseRestoreModalPatient();
			onCloseFormCreatePatientModal();
			setIsReload(!isReload);
			okToast(toast, 'Paciente restaurado con éxito');
		} catch (error) {
			errorToast(toast, 'Error al restaurar el paciente', error.message);
		}
	};
	return (
		<>
			<Modal
				closeOnOverlayClick={false}
				isOpen={isOpen}
				onClose={onCloseRestoreModalPatient}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader display={'flex'} justifyContent={'center'}>
						Recuperación paciente
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<Text>
							El paciente que está intentando crear ya existía
							pero fue eliminado.{' '}
							<Text as='b'>¿Desea restaurarlo?</Text>
						</Text>
					</ModalBody>

					<ModalFooter
						display={'flex'}
						flexDirection={'row'}
						justifyContent={'center'}
					>
						<Button
							variant={'teal300'}
							onClick={handleRestorePatient}
						>
							Restaurar
						</Button>
						<Button
							variant={'redAow'}
							onClick={onCloseRestoreModalPatient}
							ml={3}
						>
							Cancelar
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
