import { extendTheme } from '@chakra-ui/react';
import '@fontsource/inter';
import { MultiSelectTheme } from 'chakra-multiselect';
import { accordionTheme } from './components/accordionTheme';

const styles = {
	body: {
		height: '100vh',
	},
};

const fonts = {
	heading: 'inter',
	body: 'inter',
};

const config = {
	initialColorMode: 'light',
	useSystemColorMode: false,
};

const colors = {
	teal300: '#4FD1C5',
	red: '#E53E3E',
	blackAlpha50: '#F5F5F5',
	grayAow: '#4A5568',
	warning: '#FFA500',
};

const colorSheme = {
	teal300: '#4FD1C5',
};

const components = {
	Input: {
		variants: {},
	},
	NumberInput: {
		variants: {},
	},
	Button: {
		variants: {
			teal300: {
				bg: '#4FD1C5',
				color: 'white',
				_hover: {
					bg: 'grayAow',
					color: 'white',
				},
			},
			redAow: {
				bg: 'red',
				color: 'white',
				_hover: {
					bg: 'grayAow',
					color: 'white',
				},
			},
			aowSidebar: {
				w: 'full',
				bg: 'gray.600',
				color: 'teal300',
				_hover: {
					bg: 'gray.500',
				},
			},
		},
	},
	Select: {
		variants: {},
	},

	Heading: {
		baseStyle: {},
		variants: {},
	},
	Checkbox: {
		colorSheme: {
			teal300: '#4FD1C5',
		},
	},

	Switch: {
		baseStyle: {
			thumb: {
				cursor: 'default',
			},
			track: {
				cursor: 'default',
			},
		},
	},

	Accordion: accordionTheme,
	MultiSelect: MultiSelectTheme,
};

export const theme = extendTheme({
	config,
	styles,
	components,
	fonts,
	colors,
	colorSheme,
});
