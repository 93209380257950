export const initiateDataPacient = {
	dni: '',
	name: '',
	lastname: '',
	birthdate: '',
	email: '',
	phone: '',
	sex: '',
	age: '',
	address: '',
	country: '',
	city: '',
	postalCode: '',
	inss: '',
	privateInsurance: '',
	diseases: '',
	vitalTreatments: '',
	treatments: '',
	medicalHistory: '',
	remarks: '',
	diagnosis: '',
	initiateDate: '',
	finallyDate: '',
	contactName: '',
	addressContact: '',
	phoneContact: '',
	emailContact: '',
	notes: '',
	covidSymptoms: false,
	pcr: false,
	antigen: false,
	testQuick: false,
	centerId: '',
	refLocation: {
		lat: null,
		lon: null,
		accuracy: null,
	},
	defaultValueAlarmConfig: {
		lowBattery: {
			lowBatteryThreshold: '',
		},
		shock: {
			shockThresholdMin: '',
			shockThresholdMax: '',
		},
		oxygen: {
			oxygenThreshold: '',
		},
		bloodPressure: {
			thresholdMinSystolic: '',
			thresholdMaxSystolic: '',
			thresholdMinDiastolic: '',
			thresholdMaxDiastolic: '',
		},
		location: {
			radiusThreshold: '',
		},
	},
};

export const initialCreateUser = {
	name: '',
	lastname: '',
	email: '',
	password: '',
	confPassword: '',
	imageUrl: '',
};

export const initialUserCenterLink = {
	centerId: '',
	userId: '',
	role: '',
	viewAll: true,
	patients: [],
};

export const initialCreateCenter = {
	name: '',
	email: '',
	cifDni: '',
	provinceId: '',
	address: '',
	cityId: '',
	postalCode: '',
	phoneNumber: '',
	websiteUrl: '',
	logoUrl: '',
};

export const initialCreateCamera = {
	model: '',
	serial: '',
	ip: '',
	user: '',
	password: '',
	centerId: '',
	patientId: '',
};

export const initialSelectAssing = {
	cameraId: '',
	centerId: '',
	patientId: '',
};

export const initialWatch = {
	company: '3G',
	model: '3G',
	imei: '',
	phoneNumber: '',
	phoneAlert1: '',
	phoneAlert2: '',
	phoneAlertCentral: '',
	patientId: '',
	patientName: '',
	patientLastname: '',
	patientDni: '',
	centerId: '',
	centerName: '',
};

export const initialSelectAssingWatch = {
	watchId: '',
	centerId: '',
	patientId: '',
};
