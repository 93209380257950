import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { decodeToken, encodeToken } from '../../utils/TokenUtils';

const initialState = {
	user: null,
	selectCenter: null,
	isError: false,
	isLoading: false,
	isSuccess: false,
	message: '',
};

/**
 *
 * @name loginUser
 * @description The login user thunk
 * @param {Object} user The user object
 * @param {Object} thunkAPI The thunk API
 * @returns {Promise} The promise
 * @version 1.0.0
 *
 */
export const loginUser = createAsyncThunk(
	'user/loginUser',
	async (user, thunkAPI) => {
		const token = encodeToken(user);
		try {
			const response = await axios.post('/login', { token });
			const user = decodeToken(response.data.data);

			return user;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data.message);
		}
	},
);

/**
 *
 * @name getMe
 * @description The get me thunk
 * @param {Object} _ The user object
 * @param {Object} thunkAPI The thunk API
 * @returns {Promise} The promise
 * @version 1.0.0
 *
 */
export const getMe = createAsyncThunk('user/getMe', async (_, thunkAPI) => {
	try {
		const response = await axios.get('me');

		return response.data.data;
	} catch (error) {
		if (error.response) {
			return thunkAPI.rejectWithValue(error.response.data.message);
		}
	}
});

/**
 *
 * @name logout
 * @description The logout thunk
 * @returns {Promise} The promise
 * @version 1.0.0
 *
 */
export const logout = createAsyncThunk('user/logout', async () => {
	await axios.delete('logout');
});

/**
 *
 * @name authSlice
 * @description The auth slice
 * @version 1.0.0
 *
 */
export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		reset: () => initialState,
	},
	extraReducers: builder => {
		builder.addCase(loginUser.pending, state => {
			state.isLoading = true;
		});
		builder.addCase(loginUser.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.user = action.payload;
		});
		builder.addCase(loginUser.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.message = action.payload;
		});

		builder.addCase(getMe.pending, state => {
			state.isLoading = true;
		});
		builder.addCase(getMe.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.user = action.payload;
		});
		builder.addCase(getMe.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.message = action.payload;
		});
	},
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
