import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useToast,
} from '@chakra-ui/react';

import { updateUser } from '../../../services/UserServices';
import { okToast, errorToast } from '../../../utils/toast';
import { useSelector } from 'react-redux';

export const RestoreModalUsers = ({
	isOpen,
	onCloseRestoreModalUser,
	onCloseFormCreateUserModal,
	inputCreateUser,
	userIdRestored,
	isReload,
	setIsReload,
	setStep,
	resetInputCreateUser,
}) => {
	const toast = useToast();
	const { user } = useSelector(state => state.auth);
	const { selectCenter } = useSelector(state => state.selectedCenter);
	const { name, lastname, password, email } = inputCreateUser;

	const handleRestoreUser = async () => {
		try {
			const role = selectCenter?.role === 'admin';
			const response = await updateUser({
				userId: userIdRestored,
				name,
				lastname,
				password,
				email,
				notDeleted: true,
				role,
				centerId: selectCenter?.centerId,
			});

			if (!response) {
				return errorToast(toast, 'Error al restaurar usuario');
			}

			if (user?.isAdmin) {
				setStep(2);
			}
			onCloseRestoreModalUser();
			onCloseFormCreateUserModal();
			setIsReload(!isReload);
			okToast(toast, 'Usuario restaurado con éxito');
		} catch (error) {
			errorToast(toast, 'Error al restaurar el usuario', error.message);
		}
	};

	const handleCancel = () => {
		resetInputCreateUser();
		onCloseRestoreModalUser();
	};

	return (
		<>
			<Modal
				closeOnOverlayClick={false}
				isOpen={isOpen}
				onClose={handleCancel}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader display={'flex'} justifyContent={'center'}>
						Recuperación usuario
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<Text>
							El usuario que está intentando crear ya existía pero
							fue eliminado.{' '}
							<Text as='b'>¿Desea restaurarlo?</Text>
						</Text>
					</ModalBody>

					<ModalFooter
						gap={5}
						display={'flex'}
						flexDirection={'row'}
						justifyContent={'center'}
					>
						<Button
							variant={'redAow'}
							onClick={handleCancel}
							ml={3}
						>
							Cancelar
						</Button>
						<Button variant={'teal300'} onClick={handleRestoreUser}>
							Restaurar
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
