export const convertDateNow = date => {
	if (!date) return 'Sin datos';

	const onlyDate = new Date(date.split('T'));

	return onlyDate.toLocaleDateString();
};

export const convertDateAndTimeNow = date => {
	if (!date) return '-';

	const DateAndTime = new Date(date);

	return (
		DateAndTime.toLocaleDateString() +
		', ' +
		DateAndTime.toLocaleTimeString()
	);
};
