import { useState, useEffect } from 'react';
import {
	Button,
	ButtonGroup,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useDisclosure,
	useToast,
	Select,
	Divider,
	useMediaQuery,
} from '@chakra-ui/react';
import { checkCifDni } from '../../../utils/CheckingData';
import { initiateDataPacient } from '../../../data/initData';
import { errorToast, okToast } from '../../../utils/toast';
import {
	createPatient,
	updatePatient,
} from '../../../services/PatientServices';
import { AlertNotContactNotPhoneAlertPatient } from './subcomponents/AlertNotContactNotPhoneAlertPatient';
import { useSelector } from 'react-redux';
import { getCities } from '../../../services/getCities';
import { RestoreModalPatients } from '../modal/RestoreModalPatients';
import { decodeToken } from '../../../utils/TokenUtils';
import { FormDataPacient } from './subcomponents/FormDataPacient';
import { FormMedicalData } from './subcomponents/FormMedicalData';
import { FormContactData } from './subcomponents/FormContactData';
import { FormOtherData } from './subcomponents/FormOtherData';
import { FormMedicineReminder } from './subcomponents/FormMedicineReminder';
import { FormDeviceData } from './subcomponents/FormDeviceData';
import { FormGpsFenceData } from './subcomponents/FormGpsFenceData';

export const FormCreatePatient = ({
	onClose,
	inputUser,
	setInputUser,
	isUpdate,
	setIsUpdate,
	isReload,
	setIsReload,
	updatePatients,
}) => {
	const {
		isOpen: isOpenAlert,
		onOpen: onOpenAlert,
		onClose: onCloseAlert,
	} = useDisclosure();
	const {
		isOpen: isOpenRestoreModalPatient,
		onOpen: onOpenRestoreModalPatient,
		onClose: onCloseRestoreModalPatient,
	} = useDisclosure();
	const toast = useToast();
	const { user } = useSelector(state => state.auth);
	const { selectCenter } = useSelector(state => state.selectedCenter);
	const [cities, setCities] = useState([]);
	const [patientIdRestored, setPatientIdRestored] = useState(null);
	const [clickOnSubmit, setClickOnSubmit] = useState(false);
	const [selectProfileMenu, setSelectProfileMenu] = useState('personal');
	const [isLargerThanSm] = useMediaQuery('(max-width: 480px)');

	useEffect(() => {
		if (inputUser?.country) {
			const loadCities = async () => {
				const loadCities = await getCities(inputUser?.country);
				setCities(loadCities);
			};

			loadCities();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputUser?.country]);

	const handleChangeUser = e => {
		if (e.target.type === 'select-one' && e.target.name === 'country') {
			const loadCities = async () => {
				const loadCities = await getCities(e.target.value);
				setCities(loadCities);
			};

			loadCities();
		}

		const { name, value } = e.target;

		if (name === 'birthdate') {
			const selectedDate = new Date(value);
			const currentDate = new Date();

			if (selectedDate > currentDate) {
				errorToast(
					toast,
					'La fecha de nacimiento no puede ser posterior a la fecha actual.',
				);

				return;
			}
		}

		const nameParts = name.split('.');

		if (nameParts.length > 1) {
			setInputUser(prevState => {
				let tempState = prevState;

				for (let i = 0; i < nameParts.length - 1; i++) {
					tempState = tempState[nameParts[i]];
				}

				tempState[nameParts[nameParts.length - 1]] = value;

				return { ...prevState };
			});
		} else {
			setInputUser({ ...inputUser, [name]: value });
		}
	};

	const registerPatient = async () => {
		try {
			const register = await createPatient(inputUser);

			if (register) {
				onClose();
				okToast(toast, 'Paciente creado correctamente');
				setIsReload(!isReload);
				setInputUser(initiateDataPacient);
			}
		} catch (error) {
			const { message } = error;
			if (message === 'Patient already exists but is deleted.') {
				const patientId = decodeToken(error.data.token);
				setPatientIdRestored(patientId);
				onOpenRestoreModalPatient();
			} else {
				return errorToast(
					toast,
					'Error al crear el paciente',
					error.message,
				);
			}
		}
	};

	const update = async () => {
		try {
			const update = await updatePatient(inputUser);

			if (update) {
				onClose();
				setIsReload(!isReload);
				okToast(toast, 'Paciente actualizado correctamente');
				setInputUser(initiateDataPacient);
			}
		} catch (error) {
			if (error === 'Invalid JSON config.') {
				errorToast(
					toast,
					'Por favor, revise las valores del formulario Dispositivo, recuerde que los valores máximos no pueden ser inferiores a los valores mínimos ',
					undefined,
					6000,
				);
				setIsUpdate(true);
			} else {
				errorToast(toast, 'Error al actualizar el paciente');
				setIsUpdate(true);
			}
		}
	};

	const onSubmit = async () => {
		setClickOnSubmit(true);
		try {
			const {
				name,
				lastname,
				dni,
				contactName,
				phoneContact,
				centerId,
				phoneAlert1,
				phoneAlert2,
				phoneAlertCentral,
			} = inputUser;
			if (name.trim() === '' || lastname.trim() === '') {
				return errorToast(
					toast,
					'Por favor, rellene todos los campos obligatorios',
				);
			}

			if (user?.isAdmin && !centerId) {
				return errorToast(
					toast,
					'Por favor, rellene todos los campos obligatorios',
				);
			}

			const checkingCifDni = checkCifDni(dni);
			if (!checkingCifDni)
				return errorToast(
					toast,
					'DNI incorrecto',
					'Por favor, inserte un DNI válido',
				);

			if (!user?.isAdmin && !isUpdate) {
				setInputUser({
					...inputUser,
					centerId: selectCenter?.centerId,
				});
			}

			if (
				!contactName ||
				!phoneContact ||
				!phoneAlert1 ||
				!phoneAlert2 ||
				!phoneAlertCentral
			) {
				return onOpenAlert();
			}

			if (isUpdate) {
				await update();
				setIsUpdate(true);
			} else {
				await registerPatient();
			}
		} catch (error) {
			errorToast(toast, 'Error al crear el paciente', error);
		}
	};

	const handleChangeSelect = e => {
		setSelectProfileMenu(e.target.value);
	};

	const handleClose = () => {
		updatePatients();
		onClose();
		setInputUser(initiateDataPacient);
		setIsUpdate();
	};

	return (
		<>
			{isLargerThanSm ? (
				<>
					<Select
						size='sm'
						value={selectProfileMenu}
						onChange={handleChangeSelect}
					>
						<option value='personal'>Personales</option>
						<option value='medical'>Médicos</option>
						<option value='contact'>Contacto</option>
						<option value='device'>Dispositivo</option>
						<option value='medicine'>Recordatorios</option>
						<option value='gpsFence'>Geovalla</option>
						<option value='other'>Otros</option>
					</Select>
					<Divider />
					{selectProfileMenu === 'personal' && (
						<FormDataPacient
							handleChangeUser={handleChangeUser}
							inputUser={inputUser}
							cities={cities}
							clickOnSubmit={clickOnSubmit}
						/>
					)}
					{selectProfileMenu === 'medical' && (
						<FormMedicalData
							handleChangeUser={handleChangeUser}
							setInputUser={setInputUser}
							inputUser={inputUser}
							clickOnSubmit={clickOnSubmit}
						/>
					)}
					{selectProfileMenu === 'contact' && (
						<FormContactData
							handleChangeUser={handleChangeUser}
							inputUser={inputUser}
							clickOnSubmit={clickOnSubmit}
						/>
					)}
					{selectProfileMenu === 'device' && (
						<FormDeviceData
							handleChangeUser={handleChangeUser}
							inputUser={inputUser}
							clickOnSubmit={clickOnSubmit}
						/>
					)}
					{selectProfileMenu === 'medicine' && (
						<FormMedicineReminder
							inputUser={inputUser}
							setInputUser={setInputUser}
						/>
					)}
					{selectProfileMenu === 'gpsFence' && (
						<FormGpsFenceData
							handleChangeUser={handleChangeUser}
							inputUser={inputUser}
						/>
					)}
					{selectProfileMenu === 'other' && (
						<FormOtherData
							handleChangeUser={handleChangeUser}
							inputUser={inputUser}
							clickOnSubmit={clickOnSubmit}
						/>
					)}
				</>
			) : (
				<Tabs isFitted variant='enclosed'>
					<TabList mb='1em'>
						<Tab>Personales</Tab>
						<Tab>Médicos</Tab>
						<Tab>Contacto</Tab>
						<Tab>Dispositivo</Tab>
						<Tab>Recordatorios</Tab>
						<Tab>Geovalla</Tab>
						<Tab>Otros</Tab>
					</TabList>
					<TabPanels minH={'60vh'}>
						<TabPanel>
							<FormDataPacient
								handleChangeUser={handleChangeUser}
								inputUser={inputUser}
								cities={cities}
								clickOnSubmit={clickOnSubmit}
							/>
						</TabPanel>
						<TabPanel>
							<FormMedicalData
								handleChangeUser={handleChangeUser}
								setInputUser={setInputUser}
								inputUser={inputUser}
								clickOnSubmit={clickOnSubmit}
							/>
						</TabPanel>
						<TabPanel>
							<FormContactData
								handleChangeUser={handleChangeUser}
								inputUser={inputUser}
								clickOnSubmit={clickOnSubmit}
							/>
						</TabPanel>
						<TabPanel>
							<FormDeviceData
								handleChangeUser={handleChangeUser}
								inputUser={inputUser}
								clickOnSubmit={clickOnSubmit}
							/>
						</TabPanel>
						<TabPanel>
							<FormMedicineReminder
								inputUser={inputUser}
								setInputUser={setInputUser}
							/>
						</TabPanel>
						<TabPanel>
							<FormGpsFenceData
								handleChangeUser={handleChangeUser}
								inputUser={inputUser}
							/>
						</TabPanel>
						<TabPanel>
							<FormOtherData
								handleChangeUser={handleChangeUser}
								inputUser={inputUser}
								clickOnSubmit={clickOnSubmit}
							/>
						</TabPanel>
					</TabPanels>
				</Tabs>
			)}

			<ButtonGroup my={5} w='100%'>
				<Button
					w={'100%'}
					onClick={handleClose}
					colorScheme='red'
					variant='redAow'
				>
					Cancelar
				</Button>
				<Button
					w={'100%'}
					colorScheme='redAow'
					variant='teal300'
					onClick={() => onSubmit()}
				>
					{isUpdate ? 'Actualizar' : 'Guardar'}
				</Button>
			</ButtonGroup>
			<AlertNotContactNotPhoneAlertPatient
				isOpen={isOpenAlert}
				onClose={onCloseAlert}
				registerPatient={registerPatient}
				isUpdate={isUpdate}
				updatePatient={update}
			/>
			<RestoreModalPatients
				isOpen={isOpenRestoreModalPatient}
				onCloseRestoreModalPatient={onCloseRestoreModalPatient}
				onCloseFormCreatePatientModal={onClose}
				patientIdRestored={patientIdRestored}
				inputUser={inputUser}
				isReload={isReload}
				setIsReload={setIsReload}
			/>
		</>
	);
};
