import { Box, Button, HStack, Heading } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

// eslint-disable-next-line react/prop-types
export const HeaderAll = ({ title, onOpen }) => {
	const { user } = useSelector(state => state.auth);
	const { selectCenter } = useSelector(state => state.selectedCenter);

	const notAddAdmin = ['Pacientes', 'Usuarios'];
	const addIsAdmin = [
		'Pacientes',
		'Usuarios',
		'Centros',
		'Dispositivos',
		'Cámaras',
	];

	return (
		<HStack justify={'space-between'}>
			<Box>
				<Heading>{title}</Heading>
			</Box>
			<Box>
				{((user?.isAdmin && addIsAdmin.includes(title)) ||
					(selectCenter?.role === 'admin' &&
						notAddAdmin.includes(title))) && (
					<Button
						size={'lg'}
						bg='#4FD1C5'
						color='white'
						_hover={{ bg: '#4A5568', color: 'white' }}
						onClick={onOpen}
					>
						+ Agregar
					</Button>
				)}
			</Box>
		</HStack>
	);
};
