import { useState } from 'react';
import {
	Center,
	Divider,
	HStack,
	Stack,
	VStack,
	Text,
	Box,
	Button,
	Collapse,
} from '@chakra-ui/react';
import { convertDateNow } from '../../../../../utils/ConvertDateNow';
import { calculateAge } from '../../../../../utils/CalculateAge';

export const DataPersonalPatientProfile = ({ patient }) => {
	const [showAlertInfo, setShowAlertInfo] = useState(false);

	const haveContact =
		patient?.contactName ||
		patient?.phoneContact ||
		patient?.addressContact ||
		patient?.emailContact;

	const havePhoneAlert =
		patient?.phoneAlert1 ||
		patient?.phoneAlert2 ||
		patient?.phoneAlertCentral;

	return (
		<Stack flexDirection={'column'} gap={{ base: 5, xl: 5 }}>
			<Stack
				flexDirection={{ base: 'column', xl: 'row' }}
				justifyContent={'center'}
			>
				<Stack flexDirection={'column'} gap={0}>
					<HStack>
						<Text fontWeight={'bold'}>DNI / NIE:</Text>
						<Text>{patient?.dni}</Text>
					</HStack>
					<HStack>
						<Text fontWeight={'bold'}>Nombre y Apellidos:</Text>
						<Text>
							{patient?.name} {patient?.lastname}
						</Text>
					</HStack>
					<HStack>
						<Text fontWeight={'bold'}>Sexo:</Text>
						<Text>{patient?.sex ? patient?.sex : 'Sin datos'}</Text>
					</HStack>
					<HStack>
						<Text fontWeight={'bold'}>Edad:</Text>
						<Text>
							{patient?.birthdate
								? `${calculateAge(patient?.birthdate)} años`
								: 'Sin datos'}
						</Text>
					</HStack>
					<HStack>
						<Text fontWeight={'bold'}>F. Nacimiento:</Text>
						<Text>{convertDateNow(patient?.birthdate)}</Text>
					</HStack>
				</Stack>
			</Stack>

			<Stack
				flexDirection={{ base: 'column', xl: 'row' }}
				gap={{ base: 5, lg: 10 }}
				justifyContent={'center'}
			>
				<Stack flexDirection={'column'} gap={0}>
					<HStack>
						<Text textDecoration={'underline'} fontWeight={'bold'}>
							DATOS MÉDICOS
						</Text>
					</HStack>

					<HStack>
						<Text fontWeight={'bold'}>INSS:</Text>
						<Text>
							{patient?.inss ? patient?.inss : 'Sin datos'}
						</Text>
					</HStack>
					<HStack>
						<Text fontWeight={'bold'}>Historial médico:</Text>
						<Text>
							{patient?.medicalHistory
								? patient?.medicalHistory
								: 'Sin datos'}
						</Text>
					</HStack>
					<HStack>
						<Text fontWeight={'bold'}>Seguro Privado</Text>
						<Text>
							{patient?.privateInsurance
								? patient?.privateInsurance
								: 'Sin datos'}
						</Text>
					</HStack>
				</Stack>
				<Center>
					<Divider
						orientation={{ base: 'horizontal', xl: 'vertical' }}
						w={{ base: '100%', xl: '0px' }}
						h={{ base: '0px', xl: '100px' }}
						border={'2px solid gray'}
					/>
				</Center>
				<Stack flexDirection={'column'} gap={0}>
					<HStack>
						<Text textDecoration={'underline'} fontWeight={'bold'}>
							DATOS DE CONTACTO
						</Text>
					</HStack>

					<HStack>
						<Text fontWeight={'bold'}>Email:</Text>
						<Text>{patient?.email}</Text>
					</HStack>
					<HStack>
						<Text fontWeight={'bold'}>Teléfono:</Text>
						<Text>
							{patient?.phone === 0
								? 'Sin datos'
								: patient?.phone}
						</Text>
					</HStack>
				</Stack>
			</Stack>
			{(haveContact || havePhoneAlert) && (
				<>
					<Button
						onClick={() => setShowAlertInfo(!showAlertInfo)}
						mt={5}
						colorScheme='teal'
						width={{ base: '100%', xl: '100%' }}
					>
						{showAlertInfo ? 'Ocultar' : 'Mostrar'} + Información
					</Button>
					<Collapse in={showAlertInfo} animateOpacity>
						<Box
							bgColor={'gray.700'}
							p={5}
							borderRadius={10}
							width={'100%'}
							mt={{ base: 5, xl: 0 }}
							overflowY='auto'
							maxHeight={{ base: 'auto', sm: '300px' }}
						>
							<Text
								textDecoration={'underline'}
								fontWeight={'bold'}
								color={'white'}
								textAlign={'center'}
							>
								PERSONA DE CONTACTO Y TELÉFONOS DE ALERTA
							</Text>

							<Stack
								direction={{ base: 'column', xl: 'row' }}
								spacing={{ base: 5, xl: 10 }}
								mt={5}
							>
								<VStack
									alignItems='center'
									spacing={3}
									flexBasis={{ base: '100%', xl: '50%' }}
								>
									<HStack>
										<Text
											fontWeight={'bold'}
											color={'teal.300'}
										>
											Nombre:
										</Text>
										<Text color={'white'}>
											{patient?.contactName ||
												'No especificado'}
										</Text>
									</HStack>

									<HStack>
										<Text
											fontWeight={'bold'}
											color={'teal.300'}
										>
											Teléfono:
										</Text>
										<Text color={'white'}>
											{patient?.phoneContact ||
												'No especificado'}
										</Text>
									</HStack>

									<HStack>
										<Text
											fontWeight={'bold'}
											color={'teal.300'}
										>
											Dirección:
										</Text>
										<Text color={'white'}>
											{patient?.addressContact ||
												'No especificado'}
										</Text>
									</HStack>

									<HStack>
										<Text
											fontWeight={'bold'}
											color={'teal.300'}
										>
											Email:
										</Text>
										<Text color={'white'}>
											{patient?.emailContact ||
												'No especificado'}
										</Text>
									</HStack>
								</VStack>

								<VStack
									alignItems='center
'
									spacing={3}
									flexBasis={{ base: '100%', xl: '50%' }}
								>
									<HStack>
										<Text
											fontWeight={'bold'}
											color={'teal.300'}
										>
											Teléfono de Alerta 1:
										</Text>
										<Text color={'white'}>
											{patient?.phoneAlert1 ||
												'No especificado'}
										</Text>
									</HStack>

									<HStack>
										<Text
											fontWeight={'bold'}
											color={'teal.300'}
										>
											Teléfono de Alerta 2:
										</Text>
										<Text color={'white'}>
											{patient?.phoneAlert2 ||
												'No especificado'}
										</Text>
									</HStack>

									<HStack>
										<Text
											fontWeight={'bold'}
											color={'teal.300'}
										>
											Alerta Central:
										</Text>
										<Text color={'white'}>
											{patient?.phoneAlertCentral ||
												'No especificado'}
										</Text>
									</HStack>
								</VStack>
							</Stack>
						</Box>
					</Collapse>
				</>
			)}
		</Stack>
	);
};
