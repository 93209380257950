import { Button, Heading, Image, Stack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const PageError = () => {
	const navigate = useNavigate();

	return (
		<Stack
			w={'100vw'}
			h={'100vh'}
			gap={5}
			direction={'column'}
			justify={'center'}
			alignItems={'center'}
		>
			<Image src={'/logoBlack.png'} alt='Logo' size={'sm'} />
			<Heading as='h1' size='2xl'>
				404
			</Heading>
			<Text>La página no existe</Text>
			<Button variant={'teal300'} onClick={() => navigate('/')}>
				Volver a inicio
			</Button>
		</Stack>
	);
};
