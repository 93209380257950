import {
	Flex,
	FormControl,
	FormLabel,
	HStack,
	Input,
	Stack,
	Select,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllCenter } from '../../../../services/CenterServices';
import { getProvincies } from '../../../../services/getProvincies';

export const FormDataPacient = ({
	handleChangeUser,
	inputUser,
	cities,
	clickOnSubmit,
}) => {
	const { user } = useSelector(state => state.auth);
	const [allCenters, setAllCenters] = useState([]);
	const [provincies, setProvincies] = useState([]);
	const [maxDate, setMaxDate] = useState('');

	useEffect(() => {
		const AllCenters = async () => {
			const centers = await getAllCenter();
			setAllCenters(centers);
		};

		if (user?.isAdmin) {
			AllCenters();
		}
	}, [user?.isAdmin]);

	useEffect(() => {
		const loadProvinces = async () => {
			const loadProvinces = await getProvincies();
			setProvincies(loadProvinces);
		};

		loadProvinces();
	}, []);

	useEffect(() => {
		const today = new Date();

		const year = today.getFullYear();
		const month = ('0' + (today.getMonth() + 1)).slice(-2);
		const day = ('0' + today.getDate()).slice(-2);
		const currentDate = `${year}-${month}-${day}`;

		setMaxDate(currentDate);
	}, []);

	const checkValue = (value, isOptional = true) => {
		if (isOptional) return clickOnSubmit && !value;
		return clickOnSubmit && (!value || value.trim() === '');
	};

	return (
		<Flex
			gap={{ base: 2, xl: 5 }}
			flexDirection={'column'}
			alignItems={'center'}
		>
			<HStack w={'100%'}>
				<Stack flexDirection={'row'} w={'100%'}>
					<FormControl
						isRequired
						isInvalid={checkValue(inputUser.dni, false)}
					>
						<FormLabel htmlFor='dni' fontWeight={'normal'}>
							DNI
						</FormLabel>
						<Input
							id='dni'
							name='dni'
							errorBorderColor='crimson'
							value={inputUser?.dni}
							onChange={handleChangeUser}
						/>
					</FormControl>
					<FormControl
						isRequired
						isInvalid={checkValue(inputUser.name, false)}
					>
						<FormLabel htmlFor='name' fontWeight={'normal'}>
							Nombre
						</FormLabel>
						<Input
							id='name'
							name='name'
							errorBorderColor='crimson'
							value={inputUser?.name}
							onChange={handleChangeUser}
						/>
					</FormControl>
				</Stack>
				<Stack flexDirection={'row'} w={'50%'}>
					<FormControl
						isRequired
						isInvalid={checkValue(inputUser.lastname, false)}
					>
						<FormLabel htmlFor='lastname' fontWeight={'normal'}>
							Apellido
						</FormLabel>
						<Input
							id='lastname'
							name='lastname'
							errorBorderColor='crimson'
							value={inputUser?.lastname}
							onChange={handleChangeUser}
						/>
					</FormControl>
				</Stack>
			</HStack>

			<Stack w={'100%'} direction={{ base: 'column', md: 'row' }}>
				<Stack
					direction={{ base: 'column', sm: 'row' }}
					w={{ base: '100%', md: '50%' }}
				>
					<FormControl>
						<FormLabel htmlFor='birthdate' fontWeight={'normal'}>
							Fecha de nacimiento
						</FormLabel>
						<Input
							id='birthdate'
							name='birthdate'
							type='date'
							value={inputUser?.birthdate || ''}
							onChange={handleChangeUser}
							max={maxDate}
						/>
					</FormControl>

					<FormControl>
						<FormLabel htmlFor='sex' fontWeight={'normal'}>
							Sexo
						</FormLabel>
						<Select
							id='sex'
							name='sex'
							placeholder='Seleccione una opción'
							value={inputUser?.sex}
							onChange={handleChangeUser}
						>
							<option value={'hombre'}>Hombre</option>
							<option value={'mujer'}>Mujer</option>
						</Select>
					</FormControl>
				</Stack>

				<Stack flexDirection={'row'} w={{ base: '100%', md: '50%' }}>
					<FormControl>
						<FormLabel htmlFor='phone' fontWeight={'normal'}>
							Teléfono
						</FormLabel>
						<Input
							id='phone'
							name='phone'
							value={inputUser?.phone || ''}
							onChange={handleChangeUser}
						/>
					</FormControl>

					<FormControl>
						<FormLabel htmlFor='email' fontWeight={'normal'}>
							Email
						</FormLabel>
						<Input
							id='email'
							name='email'
							value={inputUser?.email}
							onChange={handleChangeUser}
						/>
					</FormControl>
				</Stack>
			</Stack>
			<Stack w={'100%'} direction={{ base: 'column', md: 'row' }}>
				<Stack flexDirection={'row'} w={{ base: '100%', md: '50%' }}>
					<FormControl>
						<FormLabel htmlFor='address' fontWeight={'normal'}>
							Dirección
						</FormLabel>
						<Input
							id='address'
							name='address'
							value={inputUser?.address}
							onChange={handleChangeUser}
						/>
					</FormControl>
				</Stack>
				<Stack
					direction={{ base: 'column', sm: 'row' }}
					w={{ base: '100%', md: '50%' }}
				>
					<FormControl id='country'>
						<FormLabel>Provincia</FormLabel>
						<Select
							name='country'
							onChange={handleChangeUser}
							value={inputUser?.country}
						>
							<option value={''}>{''}</option>
							{provincies.map(provincie => (
								<option
									key={provincie?.provinceId}
									value={provincie?.provinceId}
								>
									{provincie?.province}
								</option>
							))}
						</Select>
					</FormControl>
					<FormControl id='city'>
						<FormLabel>Ciudad</FormLabel>
						<Select
							name='city'
							value={inputUser?.city}
							isDisabled={inputUser?.country === ''}
							onChange={handleChangeUser}
						>
							<option value={''}>{''}</option>
							{cities?.map(city => (
								<option key={city?.id} value={city?.id}>
									{city?.city}
								</option>
							))}
						</Select>
					</FormControl>
					<FormControl id='postalCode'>
						<FormLabel>Código postal</FormLabel>
						<Input
							name='postalCode'
							type='text'
							value={inputUser?.postalCode}
							onChange={handleChangeUser}
						/>
					</FormControl>
				</Stack>
			</Stack>
			<Stack direction={{ base: 'column', md: 'row' }} w={'100%'}>
				<FormControl>
					<FormLabel htmlFor='inss' fontWeight={'normal'}>
						Número de Seg. social
					</FormLabel>
					<Input
						id='inss'
						name='inss'
						value={inputUser?.inss}
						onChange={handleChangeUser}
					/>
				</FormControl>
				<FormControl>
					<FormLabel htmlFor='privateInsurance' fontWeight={'normal'}>
						Número de Seg. privada
					</FormLabel>
					<Input
						id='privateInsurance'
						name='privateInsurance'
						value={inputUser?.privateInsurance}
						onChange={handleChangeUser}
					/>
				</FormControl>
				<FormControl>
					<FormLabel htmlFor='medicalHistory' fontWeight={'normal'}>
						Número histórico
					</FormLabel>
					<Input
						id='medicalHistory'
						name='medicalHistory'
						value={inputUser?.medicalHistory}
						onChange={handleChangeUser}
					/>
				</FormControl>
			</Stack>

			{user?.isAdmin && (
				<FormControl
					isRequired
					isInvalid={clickOnSubmit && !inputUser.centerId}
				>
					<FormLabel htmlFor='centerId' fontWeight={'normal'}>
						Asignar un centro
					</FormLabel>
					<Select
						placeholder='Seleccione un centro'
						value={inputUser?.centerId}
						id='centerId'
						name='centerId'
						errorBorderColor='crimson'
						onChange={handleChangeUser}
					>
						{allCenters.map(center => (
							<option
								key={center.centerId}
								value={center.centerId}
							>
								{center.name}
							</option>
						))}
					</Select>
				</FormControl>
			)}
		</Flex>
	);
};
