import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	HStack,
	Icon,
	Spacer,
	Text,
	VStack,
	useDisclosure,
} from '@chakra-ui/react';
import { IoLogInOutline } from 'react-icons/io5';
import { BsPencil } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../redux/reducers/AuthReducer';
import { useNavigate } from 'react-router-dom';
import { FormModalEditProfile } from '../modal/FormModalEditProfile';
import { formatedLastName } from '../../../../utils/FormatedLastName';
import { shortenEmail } from '../../../../utils/FormatShortenEmail';

export const FinalItem = () => {
	const dispatch = useDispatch();
	const { user } = useSelector(state => state.auth);
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleLogout = () => {
		dispatch(logout()).then(() => {
			// Borrar centro seleccionado
			localStorage.removeItem('selectedCenter');

			// Navegar y actualizar
			navigate('/');
			window.location.reload();
		});
	};

	return (
		<>
			<Accordion
				allowToggle
				py={3}
				display={'flex'}
				alignItems={'center'}
			>
				<AccordionItem border={0} w={'100%'}>
					<AccordionButton
						_hover={{ backgroundColor: 'transparent' }}
					>
						<HStack w={'100%'}>
							<VStack
								w={'100%'}
								display={'flex'}
								alignItems='center'
								gap={0}
								color={'teal300'}
							>
								<HStack
									gap={1}
									w={'100%'}
									justifyContent='center'
								>
									<Text fontSize='md'>{user?.name}</Text>
									<Text fontSize='md'>
										{formatedLastName(user?.lastname)}
									</Text>
								</HStack>
								<Text fontSize='xs'>
									{shortenEmail(user?.email)}
								</Text>
							</VStack>
							<Spacer />
							<AccordionIcon />
						</HStack>
					</AccordionButton>

					<AccordionPanel bg={'transparent'}>
						<Button variant={'aowSidebar'} onClick={onOpen} mb={2}>
							<Icon mr='4' fontSize='16' as={BsPencil} />
							<Box as='span' flex='1' textAlign='left'>
								Editar Perfil
							</Box>
						</Button>
						<Button variant={'aowSidebar'} onClick={handleLogout}>
							<Icon mr='4' fontSize='16' as={IoLogInOutline} />
							<Box as='span' flex='1' textAlign='left'>
								Cerrar Sesión
							</Box>
						</Button>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
			<FormModalEditProfile
				isOpen={isOpen}
				onClose={onClose}
				userData={user}
			/>
		</>
	);
};
