import {
	Flex,
	FormControl,
	FormLabel,
	Input,
	Stack,
	VStack,
} from '@chakra-ui/react';

export const FormContactData = ({
	handleChangeUser,
	inputUser,
	clickOnSubmit,
}) => {
	return (
		<Flex>
			<VStack w={'100%'} gap={5}>
				<Stack flexDirection={'row'} w={'100%'}>
					<FormControl
						isRequired
						isInvalid={clickOnSubmit && !inputUser.contactName}
					>
						<FormLabel htmlFor='contactName' fontWeight={'normal'}>
							Nombre de contacto
						</FormLabel>
						<Input
							id='contactName'
							name='contactName'
							errorBorderColor='warning'
							value={inputUser?.contactName}
							onChange={handleChangeUser}
						/>
					</FormControl>
				</Stack>
				<Stack w={'100%'} direction={'column'}>
					<Stack direction={{ base: 'column', sm: 'row' }} w={'100%'}>
						<FormControl
							isRequired
							isInvalid={clickOnSubmit && !inputUser.phoneContact}
						>
							<FormLabel
								htmlFor='phoneContact'
								fontWeight={'normal'}
							>
								Teléfono de contacto
							</FormLabel>
							<Input
								id='phoneContact'
								name='phoneContact'
								errorBorderColor='warning'
								value={inputUser?.phoneContact || ''}
								onChange={handleChangeUser}
							/>
							{clickOnSubmit && !inputUser.phoneContact}
						</FormControl>

						<FormControl>
							<FormLabel
								htmlFor='emailContact'
								fontWeight={'normal'}
							>
								Email de contacto
							</FormLabel>
							<Input
								id='emailContact'
								name='emailContact'
								type='email'
								value={inputUser?.emailContact}
								onChange={handleChangeUser}
							/>
						</FormControl>
					</Stack>
					<Stack flexDirection={'row'} w={'100%'}>
						<FormControl>
							<FormLabel
								htmlFor='addressContact'
								fontWeight={'normal'}
							>
								Dirección de contacto
							</FormLabel>
							<Input
								id='addressContact'
								name='addressContact'
								value={inputUser?.addressContact}
								onChange={handleChangeUser}
							/>
						</FormControl>
					</Stack>
				</Stack>
			</VStack>
		</Flex>
	);
};
