import { Stack, Text, List, ListItem, Switch } from '@chakra-ui/react';
import { scrollbar } from '../../../../../data/CONSTANTS';
import { parseReminderSetting } from '../../../../../utils/ParseReminderSetting';

export const DataMedicineReminderPatientProfile = ({ reminders }) => {
	return (
		<Stack
			spacing={4}
			maxWidth='auto'
			maxHeight='500px'
			overflowY='auto'
			sx={scrollbar}
		>
			{reminders.length === 0 ? (
				<Text fontWeight={'bold'}>
					No hay ningún recordatorio pendiente.
				</Text>
			) : (
				<List spacing={3}>
					{reminders.map(reminder => (
						<ListItem
							key={reminder.reminderId}
							display='flex'
							alignItems='center'
							justifyContent='space-between'
							borderWidth='1px'
							borderColor='teal.300'
							borderRadius='md'
							p={2}
							flexDirection={{ base: 'column', md: 'row' }}
						>
							<Switch
								size='sm'
								isChecked={reminder.active}
								isReadOnly
								colorScheme='green'
							/>
							<Text
								flex='3'
								ml={4}
								textAlign='left'
								textTransform='uppercase'
							>
								{reminder.reminderText}
							</Text>
							<Text flex='1' fontSize='sm' textAlign='center'>
								<strong>
									{
										parseReminderSetting(
											reminder.reminderSetting,
										).time
									}
								</strong>
							</Text>
							<Text flex='2' fontSize='sm' textAlign='center'>
								Repetir alarma:{' '}
								<strong>
									{!reminder.notRepeat ? 'Sí' : 'No'}
								</strong>
							</Text>
							<Text flex='1' fontSize='sm' textAlign='center'>
								{parseReminderSetting(
									reminder.reminderSetting,
								).days.join(', ')}
							</Text>
						</ListItem>
					))}
				</List>
			)}
		</Stack>
	);
};
