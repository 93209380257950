export const shortenEmail = email => {
	const parts = email.split('@');
	if (parts.length !== 2) {
		return email;
	}

	let localPart = parts[0];
	const domainPart = parts[1];

	if (localPart.length > 7) {
		localPart = localPart.substring(0, 7) + '...';
	}

	const shortenedEmail = `${localPart}@${domainPart}`;

	return shortenedEmail;
};
