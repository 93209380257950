import {
	Text,
	Button,
	HStack,
	useToast,
	useDisclosure,
	Stack,
	GridItem,
	Grid,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { errorToast, okToast } from '../../../utils/toast';
import { deletedUser } from '../../../services/UserServices';
import { AlertDeleteUser } from './AlertDeleteUser';
import { formatedLastNameCardsUser } from '../../../utils/FormatedLastNameCardsUser';

export const CardsUsers = ({
	user2,
	isAdmin,
	isReload,
	setIsReload,
	onOpenFormModal,
	onOpenFormModalViewCenters,
	onOpenFormModalViewPatients,
	setIsUpdateData,
	setDataUserUpdate,
	setStep,
	setUserIdSelect,
}) => {
	const {
		isOpen: isOpenAlert,
		onOpen: onOpenAlert,
		onClose: onCloseAlert,
	} = useDisclosure();
	const toast = useToast();
	const { userId, name, lastname, email } = user2;
	const { selectCenter } = useSelector(state => state.selectedCenter);

	const onDelete = async () => {
		try {
			const deleted = await deletedUser(userId);

			if (deleted) {
				okToast(toast, 'Usuario eliminado con éxito');
				setIsReload(!isReload);
			}
		} catch (error) {
			errorToast(toast, 'Error al eliminar usuario', error.message);
		}
	};

	const openFormUpdateModal = () => {
		setIsUpdateData(true);
		setDataUserUpdate(user2);
		setStep(1);
		onOpenFormModal();
	};

	const isCenterAdmin = selectCenter?.role === 'admin';

	return (
		<Stack
			direction={{ base: 'column', lg: 'row' }}
			w={'100%'}
			p={{ base: 3, lg: 5 }}
			gap={{ base: 5, lg: 10 }}
			bg={'white'}
			justify={'space-between'}
			borderRadius={5}
		>
			<Grid
				templateColumns={{ base: 'repeat(1,1fr)', xl: 'repeat(2,2fr)' }}
				templateRows={{ base: 'repeat(2,1fr)', xl: 'repeat(1,1fr)' }}
				w={'70%'}
				alignContent={'center'}
			>
				<GridItem>
					<HStack>
						<Text fontWeight={'bold'}>Nombre:</Text>
						<Text>
							{name} {formatedLastNameCardsUser(lastname)}
						</Text>
					</HStack>
				</GridItem>
				<GridItem>
					<HStack>
						<Text fontWeight={'bold'}>Email:</Text>
						<Text>{email}</Text>
					</HStack>
				</GridItem>
			</Grid>

			<Stack
				direction={'row'}
				gap={2}
				justifyContent={'center'}
				alignItems={'center'}
			>
				{!isAdmin && !isCenterAdmin && (
					<Button
						w={'full'}
						onClick={() => {
							onOpenFormModalViewCenters();
							setUserIdSelect(userId);
						}}
					>
						Centros
					</Button>
				)}
				<Button
					w={'full'}
					onClick={() => {
						onOpenFormModalViewPatients();
						setUserIdSelect(userId);
					}}
				>
					Pacientes
				</Button>

				<Stack
					direction={{ base: 'column', xl: 'row' }}
					gap={2}
					w={'100%'}
				>
					<Button
						w={'full'}
						bg={'teal300'}
						color={'white'}
						rounded={'md'}
						_hover={{
							transform: 'translateY(-2px)',
							boxShadow: 'lg',
						}}
						onClick={() => openFormUpdateModal()}
					>
						Editar
					</Button>
					<Button
						w={'full'}
						bg={'red'}
						color={'white'}
						rounded={'md'}
						_hover={{
							transform: 'translateY(-2px)',
							boxShadow: 'lg',
						}}
						onClick={() => onOpenAlert()}
					>
						Eliminar
					</Button>
				</Stack>

				<AlertDeleteUser
					isOpen={isOpenAlert}
					onClose={onCloseAlert}
					onDelete={onDelete}
				/>
			</Stack>
		</Stack>
	);
};
