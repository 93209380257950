import { Stack, useDisclosure } from '@chakra-ui/react';
import { HeaderAll } from '../general/HeaderAll';
// import { FilterNavbar } from './subcomponents/FilterNavbar';
import { useEffect, useState } from 'react';
import { getAllWatches } from '../../services/WatchService';
import { FormModalWatch } from './modal/FormModalWatch';
import { initialWatch } from '../../data/initData';
import { CardDevice } from './subcomponents/CardDevice';

export const ViewAllDevices = () => {
	const {
		isOpen: isOpenModalCreateSmartWatch,
		onOpen: onOpenModalCreateSmartWatch,
		onClose: onCloseModalCreateSmartWatch,
	} = useDisclosure();
	const [smartwatches, setSmartwatches] = useState([]);
	const [isReload, setIsReload] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [inputWatch, setInputWatch] = useState(initialWatch);

	useEffect(() => {
		const allSmartwatches = async () => {
			const smartwatches = await getAllWatches();
			setSmartwatches(smartwatches);
		};

		allSmartwatches();
	}, [isReload]);

	return (
		<>
			<Stack m={{ base: 5, lg: 10 }}>
				<HeaderAll
					title='Dispositivos'
					onOpen={onOpenModalCreateSmartWatch}
				/>
				<Stack>
					{/* <FilterNavbar /> */}
					{smartwatches?.map((smartwatch, index) => (
						<CardDevice
							key={index}
							smartwatch={smartwatch}
							isReload={isReload}
							setIsReload={setIsReload}
							setIsUpdate={setIsUpdate}
							onOpen={onOpenModalCreateSmartWatch}
							setInputWatch={setInputWatch}
						/>
					))}
				</Stack>
			</Stack>
			{isOpenModalCreateSmartWatch && (
				<FormModalWatch
					isOpen={isOpenModalCreateSmartWatch}
					onClose={onCloseModalCreateSmartWatch}
					isReload={isReload}
					setIsReload={setIsReload}
					inputWatch={inputWatch}
					setInputWatch={setInputWatch}
					isUpdate={isUpdate}
					setIsUpdate={setIsUpdate}
				/>
			)}
		</>
	);
};
