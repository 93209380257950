import { Stack, useDisclosure } from '@chakra-ui/react';
import { HeaderAll } from '../general/HeaderAll';
// import { FilterNavbar } from './subcomponents/FilterNavbar';
import { useEffect, useState } from 'react';
import { getAllCameras } from '../../services/CameraServices';
import { CardCamera } from './subcomponents/CardCamera';
import { FormModalCamera } from './modal/FormModalCamera';
import { initialCreateCamera } from '../../data/initData';

export const ViewAllCameras = () => {
	const {
		isOpen: isOpenModalCreateCamera,
		onOpen: onOpenModalCreateCamera,
		onClose: onCloseModalCreateCamera,
	} = useDisclosure();
	const [cameras, setCameras] = useState([]);
	const [isReload, setIsReload] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [inputCamera, setInputCamera] = useState(initialCreateCamera);

	useEffect(() => {
		const AllCameras = async () => {
			const cameras = await getAllCameras();
			setCameras(cameras);
		};

		AllCameras();
	}, [isReload]);

	return (
		<>
			<Stack m={{ base: 5, lg: 10 }}>
				<HeaderAll title='Cámaras' onOpen={onOpenModalCreateCamera} />
				<Stack>
					{/* <FilterNavbar /> */}
					{cameras?.map(camera => (
						<CardCamera
							key={camera.cameraId}
							camera={camera}
							onOpenModalCreateCamera={onOpenModalCreateCamera}
							setIsReload={setIsReload}
							isReload={isReload}
							setIsUpdate={setIsUpdate}
							setInputCamera={setInputCamera}
						/>
					))}
				</Stack>
			</Stack>

			<FormModalCamera
				isOpen={isOpenModalCreateCamera}
				onClose={onCloseModalCreateCamera}
				isUpdate={isUpdate}
				setIsUpdate={setIsUpdate}
				setInputCamera={setInputCamera}
				inputCamera={inputCamera}
				isReload={isReload}
				setIsReload={setIsReload}
			/>
		</>
	);
};
