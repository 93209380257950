import { Stack } from '@chakra-ui/react';
import { CameraStream } from '../../../../cameras/CameraStream';

export const DataCameraPatientProfile = ({ cameraId }) => {
	return (
		<Stack w={'100%'} h={{ base: '100%', xl: '452px' }}>
			<CameraStream id={cameraId} />;
		</Stack>
	);
};
