/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Flex, HStack, Text, Box } from '@chakra-ui/react';
import { FiMenu } from 'react-icons/fi';
import { BsPhoneVibrate } from 'react-icons/bs';
import { Clock } from './Clock';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCountEvent } from '../../../../services/EventServices';
import { useSelector } from 'react-redux';

export const MobileNav = ({ onOpen, ...rest }) => {
	const { user } = useSelector(state => state.auth);
	const { selectCenter } = useSelector(state => state.selectedCenter);
	const [numerEvent, setNumerEvent] = useState(0);
	const navigate = useNavigate();

	const countEvent = async () => {
		await getCountEvent()
			.then(data => {
				setNumerEvent(data);
			})
			.catch(() => {});
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			if (user && !user.isAdmin && selectCenter?.role === 'user') {
				countEvent();
			}
		}, 10000); // Fetch data every 10 seconds

		// Limpiar el intervalo cuando el componente se desmonte
		return () => clearInterval(intervalId);
	}, [selectCenter]);

	useEffect(() => {
		if (user && !user.isAdmin && selectCenter?.role === 'user') {
			countEvent();
		}
	}, [selectCenter]);

	return (
		<Flex
			ml={{ base: 0, xl: 60 }}
			px={{ base: 4, xl: 4 }}
			height='80px'
			alignItems='center'
			bg={'teal300'}
			borderBottomWidth='1px'
			borderBottomColor={'gray.200'}
			justifyContent={{ base: 'space-between', xl: 'flex-end' }}
			{...rest}
		>
			<IconButton
				display={{ base: 'flex', xl: 'none' }}
				onClick={onOpen}
				bgColor={'white'}
				aria-label='open menu'
				icon={<FiMenu />}
			/>

			<HStack>
				<Clock />
				{user && !user.isAdmin && selectCenter?.role === 'user' && (
					<HStack
						bg={'gray.600'}
						p={3}
						borderRadius={10}
						justifyContent={'center'}
						cursor={numerEvent <= 0 ? 'arrow' : 'pointer'}
						onClick={() => navigate('/events')}
					>
						<BsPhoneVibrate
							color={numerEvent <= 0 ? 'teal300' : 'red'}
							fontSize={'35px'}
						/>

						<Box
							display={'flex'}
							alignItems={'center'}
							w={'35px'}
							h={'35px'}
							justifyContent={'center'}
							border={'2px solid white'}
							rounded={'full'}
							bgColor={numerEvent <= 0 ? 'teal300' : 'red'}
							p={1}
						>
							<Text fontSize='md' p={0.01}>
								{numerEvent}
							</Text>
						</Box>
					</HStack>
				)}
			</HStack>
		</Flex>
	);
};
