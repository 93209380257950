import { useState } from 'react';
import {
	Button,
	Center,
	HStack,
	Spacer,
	Spinner,
	Stack,
	Text,
	useToast,
} from '@chakra-ui/react';
import { errorToast, okToast } from '../../../../utils/toast';
import { unassignPatient } from '../../../../services/UserServices';
import { AlertDeleteUserPatient } from './AlertDeteleUserPatient';

export const FormPageViewPatients = ({
	allPatients,
	isFinishGetAllPatients,
	setNeedReload,
	userIdSelect,
}) => {
	const toast = useToast();
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const [patientToDelete, setIsPatientToDelete] = useState(null);

	const onDelete = async patientId => {
		try {
			await unassignPatient(userIdSelect, patientId);
			okToast(toast, 'Paciente eliminado con éxito!');
			setNeedReload(true);
		} catch (error) {
			errorToast(toast, 'Error al eliminar paciente');
		}
	};

	const handleConfirmDelete = () => {
		setIsAlertOpen(false);
		if (patientToDelete) onDelete(patientToDelete);
	};

	const handleCancelDelete = () => {
		setIsAlertOpen(false);
	};

	const handleOpenAlert = patientId => {
		setIsPatientToDelete(patientId);
		setIsAlertOpen(true);
	};

	return (
		<Stack
			mb={5}
			p={5}
			gap={5}
			bg={'blackAlpha50'}
			h={'300px'}
			overflowY='scroll'
			width={'100%'}
			className='custom-scrollbar'
		>
			{!isFinishGetAllPatients ? (
				<Center>
					<Spinner />
				</Center>
			) : isFinishGetAllPatients && allPatients.length === 0 ? (
				<Text textAlign={'center'}>Ningún paciente asignado</Text>
			) : null}

			{allPatients.map(patient => (
				<HStack
					w={'100%'}
					justify={'space-between'}
					key={patient.patientId}
				>
					<Text>{`${patient?.name} ${patient?.lastname} (${patient?.dni})`}</Text>
					<Spacer />
					<Button
						size={'sm'}
						variant={'redAow'}
						onClick={() => {
							handleOpenAlert(patient.patientId);
						}}
					>
						Eliminar
					</Button>
				</HStack>
			))}
			<AlertDeleteUserPatient
				isOpen={isAlertOpen}
				onClose={handleCancelDelete}
				onDelete={handleConfirmDelete}
			/>
		</Stack>
	);
};
