import { useState } from 'react';
import {
	Button,
	Center,
	HStack,
	Spacer,
	Spinner,
	Stack,
	Text,
	useToast,
} from '@chakra-ui/react';
import { errorToast, okToast } from '../../../../utils/toast';
import '../../../../theme/scrollTheme.css';
import { unassignCenter } from '../../../../services/UserServices';
import { AlertDeleteUserCenter } from '../subcomponents/AlertDeleteUserCenter';

export const FormPageViewCenters = ({
	allCenters,
	isFinishGetAllPatients,
	userIdSelect,
	isReload,
	setIsReload,
}) => {
	const toast = useToast();
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const [centerToDelete, setCenterToDelete] = useState(null);

	const onDelete = async centerId => {
		try {
			await unassignCenter(userIdSelect, centerId);
			okToast(toast, 'Centro eliminado con éxito!');
			setIsReload(!isReload);
		} catch (error) {
			errorToast(toast, 'Error al eliminar centro');
		}
	};
	const handleConfirmDelete = () => {
		setIsAlertOpen(false);
		if (centerToDelete) {
			onDelete(centerToDelete);
		}
	};

	const handleCancelDelete = () => {
		setIsAlertOpen(false);
	};

	const handleOpenAlert = centerId => {
		setCenterToDelete(centerId);
		setIsAlertOpen(true);
	};
	return (
		<Stack
			mb={5}
			p={5}
			gap={5}
			bg={'blackAlpha50'}
			borderRadius={10}
			h={'300px'}
			overflowY='scroll'
			className='custom-scrollbar'
		>
			{!isFinishGetAllPatients ? (
				<Center>
					<Spinner />
				</Center>
			) : isFinishGetAllPatients && allCenters.length === 0 ? (
				<Text textAlign={'center'}>Ningún centro asignado</Text>
			) : null}

			{allCenters.map(center => (
				<HStack
					w={'100%'}
					justify={'space-around'}
					key={center.centerId}
				>
					<Text>
						{center?.name} ({center?.users[0]?.UsersCenters?.role})
					</Text>
					<Spacer />
					<Button
						size={'sm'}
						variant={'redAow'}
						onClick={() => {
							handleOpenAlert(center.centerId);
						}}
					>
						Eliminar
					</Button>
				</HStack>
			))}
			<AlertDeleteUserCenter
				isOpen={isAlertOpen}
				onClose={handleCancelDelete}
				onDelete={handleConfirmDelete}
			/>
		</Stack>
	);
};
