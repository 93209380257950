import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { FormViewPatients } from '../formViewPatients/FormViewPatients';

export const FormModalViewPatients = ({
	onClose,
	isOpen,
	allPatients,
	setAllPatients,
	setUserIdSelect,
	isFinishGetAllPatients,
	setIsFinishGetAllPatients,
	onOpenModalUser,
	setStep,
	userIdSelect,
	isReloadPatientsByUser,
	setIsReloadPatientsByUser,
	setOnCloseStatus,
}) => {
	return (
		<>
			<Modal onClose={onClose} isOpen={isOpen} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalBody>
						<FormViewPatients
							onClose={onClose}
							allPatients={allPatients}
							setAllPatients={setAllPatients}
							setUserIdSelect={setUserIdSelect}
							isFinishGetAllPatients={isFinishGetAllPatients}
							setIsFinishGetAllPatients={
								setIsFinishGetAllPatients
							}
							onOpenModalUser={onOpenModalUser}
							setStep={setStep}
							userIdSelect={userIdSelect}
							isReloadPatientsByUser={isReloadPatientsByUser}
							setIsReloadPatientsByUser={
								setIsReloadPatientsByUser
							}
							setOnCloseStatus={setOnCloseStatus}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};
