import { Heading, Progress, Text, VStack } from '@chakra-ui/react';

export const ProgressBarSteps = ({ dataPatient }) => {
	return (
		<>
			<VStack w={'100%'} spacing={0}>
				<Text>Pasos</Text>
				<Heading>{dataPatient?.steps}</Heading>
			</VStack>
			<VStack w={'100%'} spacing={0}>
				<Progress
					w={'50%'}
					borderRadius={'full'}
					colorScheme={'teal'}
					value={dataPatient?.steps}
					max={10000}
				/>
				<Text fontWeight={'bold'}>Objetivo 10.000</Text>
			</VStack>
		</>
	);
};
