import axios from 'axios';

/**
 * @name createReminder
 * @description Create a new reminder
 * @param {object} data
 * @returns {object} response data
 * @creator Daniel Moreno Acedo, Web Developer at Unknown Gravity
 */

export const createReminder = async data => {
	try {
		const response = await axios.post('/reminders/create', data);
		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name deleteReminder
 * @description Delete a reminder
 * @param {string} reminderId
 * @returns {object} response data
 * @creator Daniel Moreno Acedo, Web Developer at Unknown Gravity
 */

export const deleteReminder = async reminderId => {
	try {
		const response = await axios.delete(`/reminders/delete/${reminderId}`);
		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name updateReminder
 * @description Update a reminder
 * @param {string} reminderId
 * @param {object} data
 * @returns {object} response data
 * @creator Daniel Moreno Acedo, Web Developer at Unknown Gravity
 */

export const updateReminder = async (reminderId, data) => {
	try {
		const response = await axios.patch(
			`/reminders/update/${reminderId}`,
			data,
		);
		return response.data;
	} catch (error) {
		throw error.response.data.mesage;
	}
};

/**
 * @name toggleReminder
 * @description Toggle the active state of a reminder.
 * @param {string} reminderId
 * @returns {object} response data
 * @creator Daniel Moreno Acedo, Web Developer at Unknown Gravity
 */

export const toggleReminder = async reminderId => {
	try {
		const response = await axios.patch(`/reminders/toggle/${reminderId}`);
		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getAllRemindersByPatient
 * @description Gets all reminders for a patient by their ID
 * @param {string} patientID
 * @returns {Array} response data
 * @creator Daniel Moreno Acedo, Web Developer at Unknown Gravity
 */

export const getAllRemindersByPatient = async patientId => {
	try {
		const response = await axios.get(`/reminders/getAll/${patientId}`);
		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getReminderById
 * @description Gets a reminder by its ID
 * @param {string} reminderId
 * @returns {object}
 * @creator Daniel Moreno Acedo, Web Developer at Unknown Gravity
 */

export const getReminderById = async reminderId => {
	try {
		const response = await axios.get(`/reminders/get/${reminderId}`);
		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};
