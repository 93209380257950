import {
	Stack,
	Select,
	FormControl,
	FormLabel,
	RadioGroup,
	Radio,
} from '@chakra-ui/react';
import { MultiSelect } from 'chakra-multiselect';
import { useEffect, useState } from 'react';
import { getAllCenter } from '../../../../services/CenterServices';
import { getPacientsToCenter } from '../../../../services/PatientServices';
import { formatedLastName } from '../../../../utils/FormatedLastName';
import { useSelector } from 'react-redux';

export const FormAssingCentersAndPacients = ({
	inputLinkCenter,
	setLinkCenter,
	selectRadio,
	setSelectRadio,
}) => {
	const { user } = useSelector(state => state.auth);
	const { selectCenter } = useSelector(state => state.selectedCenter);
	const [selectInputCenter, setSelectInputCenter] = useState('');
	const [selectRole, setSelectRole] = useState('');
	const [centers, setCenters] = useState([]);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		const AllCenters = async () => {
			setCenters(await getAllCenter());
		};

		if (selectCenter?.role === 'admin') {
			setLinkCenter({
				...inputLinkCenter,
				centerId: selectCenter.centerId,
			});
		} else {
			AllCenters();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeCenters = e => {
		const AllPacientsToCenter = async centerId => {
			const allPacients = [];

			const pacientsData = await getPacientsToCenter(
				centerId,
				inputLinkCenter?.userId,
			);

			pacientsData.forEach(patient => {
				allPacients.push({
					label: `${patient.name} ${formatedLastName(patient.lastname)} (${patient.dni})`,
					value: patient.patientId,
				});
			});

			setOptions(allPacients);
		};

		const { name, value } = e.target;

		if (e.target.value !== '') {
			setLinkCenter({ ...inputLinkCenter, [name]: value });
			AllPacientsToCenter(value);
		}
		setSelectInputCenter(value);
	};

	const onChangePatients = e => {
		setLinkCenter({ ...inputLinkCenter, patients: e });
	};

	const onChangeSelect = e => {
		e === 'all'
			? setLinkCenter({ ...inputLinkCenter, viewAll: true, patients: [] })
			: setLinkCenter({ ...inputLinkCenter, viewAll: false });
		setSelectRadio(e);
	};

	const handlerChangeRole = e => {
		const { name, value } = e.target;
		setLinkCenter({ ...inputLinkCenter, [name]: value });
		setSelectRole(value);
	};

	return (
		<Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
			<FormControl id='centerId' isRequired>
				<FormLabel>Centro:</FormLabel>
				<Select
					name='centerId'
					type='select'
					value={inputLinkCenter.centerId}
					cursor={'pointer'}
					onChange={onChangeCenters}
					isDisabled={
						selectCenter?.role === 'admin' && !user?.isAdmin
					}
					placeholder='Selecciona un centro'
				>
					{centers?.map(center => {
						return (
							<option
								key={center.centerId}
								value={center.centerId}
							>
								{center.name}
							</option>
						);
					})}
				</Select>
			</FormControl>

			<FormControl id='role' isRequired>
				<FormLabel>Rol:</FormLabel>
				<Select
					name='role'
					type='select'
					cursor={'pointer'}
					value={inputLinkCenter?.role}
					onChange={handlerChangeRole}
					placeholder='Selecciona un rol'
				>
					<option value='admin'>Administrador</option>
					<option value='user'>Usuario</option>
				</Select>
			</FormControl>
			{selectInputCenter !== '' && selectRole !== '' && (
				<RadioGroup onChange={onChangeSelect} value={selectRadio}>
					<>
						<FormLabel>Asigna pacientes al usuario:</FormLabel>
						<Stack direction='row'>
							<Radio value='all'>Asignar todos</Radio>
							<Radio value='selection'>
								Seleccionar pacientes
							</Radio>
						</Stack>
					</>
				</RadioGroup>
			)}
			{selectRadio !== 'all' && (
				<MultiSelect
					placeholder='Selecciona paciente/pacientes'
					options={options}
					value={inputLinkCenter.patients}
					onChange={onChangePatients}
					selectionVisibleIn={inputLinkCenter.patients}
				/>
			)}
		</Stack>
	);
};
