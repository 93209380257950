import { Box, Button, Flex, Stack, useToast } from '@chakra-ui/react';
import { FormDataCamera } from './subcomponent/FormDataCamera';
import { errorToast, okToast } from '../../../utils/toast';
import { createCameras, updateCamera } from '../../../services/CameraServices';
import { initialCreateCamera } from '../../../data/initData';
import { useState } from 'react';

export const FormCreateCamera = ({
	onClose,
	isUpdate,
	setIsUpdate,
	setInputCamera,
	inputCamera,
	isReload,
	setIsReload,
}) => {
	const toast = useToast();
	const [clickOnSubmit, setClickOnSubmit] = useState(false);

	const hadlerChangeCreateCamera = e => {
		const { name, value } = e.target;
		setInputCamera({ ...inputCamera, [name]: value });
	};

	const update = async () => {
		try {
			const { cameraId } = inputCamera;
			const update = await updateCamera(cameraId, inputCamera);

			if (update) {
				onClose();
				setIsReload(!isReload);
				okToast(toast, 'Cámara actualizada correctamente');
				setIsUpdate(false);
				setInputCamera(initialCreateCamera);
			}
		} catch (error) {
			errorToast(toast, 'Error al actualizar la cámara');
		}
	};

	const registerCamera = async () => {
		try {
			const register = await createCameras(inputCamera);

			if (register) {
				onClose();
				okToast(toast, 'Cámara creada correctamente');
				setIsReload(!isReload);
				setInputCamera(initialCreateCamera);
			}
		} catch (error) {
			errorToast(toast, 'Error al crear la cámara');
		}
	};

	const onSubmit = async () => {
		setClickOnSubmit(true);
		try {
			const { serial, model, ip, user, password, centerId } = inputCamera;
			if (
				serial.trim() === '' ||
				!model ||
				!centerId ||
				ip.trim() === '' ||
				user.trim() === ''
			) {
				return errorToast(toast, 'Rellena los campos requeridos');
			}

			if (isUpdate) {
				await update();
			} else {
				if (password.trim() === '') {
					return errorToast(toast, 'Rellena los campos requeridos');
				}
				await registerCamera();
			}
		} catch (error) {
			errorToast(toast, 'Error al crear la cámara', error);
		}
	};

	return (
		<Flex align={'center'} justify={'center'}>
			<Stack spacing={8} maxW={'lg'}>
				<Box rounded={'lg'} bg={'white'}>
					<FormDataCamera
						hadlerChangeCreateCamera={hadlerChangeCreateCamera}
						inputCamera={inputCamera}
						isUpdate={isUpdate}
						clickOnSubmit={clickOnSubmit}
					/>
					<Flex w='100%' gap={4} py={8}>
						<Button
							loadingText='Submitting'
							size='lg'
							w={'80%'}
							variant={'redAow'}
							color={'white'}
							transition={'0.5s'}
							_hover={{
								bg: 'gray.600',
								color: 'white',
							}}
							onClick={() => {
								onClose();
								setIsUpdate(false);
								setInputCamera(initialCreateCamera);
							}}
						>
							Cancelar
						</Button>
						<Button
							loadingText='Submitting'
							size='lg'
							w={'100%'}
							color={'white'}
							bg={'teal300'}
							transition={'0.5s'}
							_hover={{
								bg: 'gray.600',
								color: 'white',
							}}
							onClick={() => onSubmit()}
						>
							{isUpdate ? 'Actualizar' : 'Guardar'}
						</Button>
					</Flex>
				</Box>
			</Stack>
		</Flex>
	);
};
