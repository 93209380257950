import {
	FormControl,
	FormLabel,
	HStack,
	Input,
	Select,
	Stack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getAllCenter } from '../../../../services/CenterServices';

export const FormWatchData = ({
	hadlerChangeCreateWatch,
	inputWatch,
	isUpdate,
	clickOnSubmit,
}) => {
	const [centers, setCenters] = useState([]);

	useEffect(() => {
		const AllCenters = async () => {
			const centers = await getAllCenter();

			setCenters(centers);
		};

		AllCenters();
	}, []);

	const checkValue = (value, isOptional = true) => {
		if (isOptional) return clickOnSubmit && !value;
		return clickOnSubmit && (!value || value.trim() === '');
	};

	return (
		<Stack spacing={8} maxW={'lg'}>
			<HStack>
				<FormControl w={'50%'} id='company' isRequired>
					<FormLabel>Compañía:</FormLabel>
					<Input
						name='company'
						type='text'
						value={'3G'}
						isDisabled
						onChange={hadlerChangeCreateWatch}
					/>
				</FormControl>

				<FormControl isRequired w={'50%'}>
					<FormLabel htmlFor='model' fontWeight={'normal'}>
						Modelo
					</FormLabel>
					<Input
						name='model'
						type='text'
						value={'3G'}
						isDisabled
						onChange={hadlerChangeCreateWatch}
					/>
				</FormControl>
			</HStack>
			<Stack flexDirection={'row'}>
				<FormControl
					id='imei'
					isRequired
					isInvalid={checkValue(inputWatch.imei, false)}
				>
					<FormLabel>IMEI:</FormLabel>
					<Input
						name='imei'
						type='text'
						errorBorderColor='crimson'
						value={inputWatch?.imei}
						onChange={hadlerChangeCreateWatch}
					/>
				</FormControl>
				<FormControl
					id='phoneNumber'
					isRequired
					isInvalid={checkValue(inputWatch.phoneNumber)}
				>
					<FormLabel>Teléfono:</FormLabel>
					<Input
						name='phoneNumber'
						type='number'
						errorBorderColor='crimson'
						value={inputWatch?.phoneNumber}
						onChange={hadlerChangeCreateWatch}
					/>
				</FormControl>
			</Stack>
			{!isUpdate && (
				<FormControl
					isRequired
					isInvalid={clickOnSubmit && !inputWatch.centerId}
				>
					<FormLabel htmlFor='centerId' fontWeight={'normal'}>
						Asignar un centro:
					</FormLabel>
					<Select
						placeholder={
							inputWatch?.centerName && isUpdate
								? inputWatch?.centerName
								: 'Seleccione un centro'
						}
						id='centerId'
						name='centerId'
						errorBorderColor='crimson'
						onChange={hadlerChangeCreateWatch}
					>
						{centers.map(center => (
							<option
								key={center.centerId}
								value={center.centerId}
							>
								{center.name}
							</option>
						))}
					</Select>
				</FormControl>
			)}
		</Stack>
	);
};
