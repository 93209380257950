import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	Button,
} from '@chakra-ui/react';
import { useRef } from 'react';

export const AlertDeleteUser = ({ isOpen, onClose, onDelete }) => {
	const cancelRef = useRef();

	return (
		<>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							Eliminar Usuario
						</AlertDialogHeader>

						<AlertDialogBody>
							¿Está seguro de eliminar este Usuario? Esta acción
							no podrá ser restaurada.
						</AlertDialogBody>

						<AlertDialogFooter
							display={'flex'}
							flexDirection={'row'}
							justifyContent={'center'}
							gap={5}
						>
							<Button
								variant={'redAow'}
								onClick={() => {
									onClose();
									onDelete();
								}}
								ml={3}
							>
								Eliminar
							</Button>
							<Button
								variant={'teal300'}
								ref={cancelRef}
								onClick={onClose}
							>
								Cancelar
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};
