import axios from 'axios';
import { decodeToken, encodeToken } from '../utils/TokenUtils';

/**
 *
 * @name getAllPacients
 * @description Get all patients data.
 * @param {object} data The data object
 * @returns {token}
 *
 */
export const getAllPacients = async () => {
	try {
		const response = await axios.get('/patient/all');
		const { token } = response.data.data;
		const data = decodeToken(token);

		return data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name getAllPacientsToCenter
 * @description Get all patients to center data.
 * @param {array} data The data array
 * @returns {token}
 *
 */
export const getAllPacientsToCenter = async (centerId, device = false) => {
	try {
		let response;
		if (device) {
			response = await axios.get(
				`/patient/all/smartwatch/notAssigned/${centerId}`,
			);
		} else {
			response = await axios.get(
				`/patient/all/camera/notAssigned/${centerId}`,
			);
		}
		const { token } = response.data.data;
		const data = decodeToken(token);

		return data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name getPacientsToCenter
 * @description Get all patients data to one center.
 * @param {object} data The data object
 * @returns {token}
 *
 */
export const getPacientsToCenter = async (centerId, userId) => {
	try {
		const response = await axios.get(
			`/user/patiensNotAssigned/${userId}/${centerId}`,
		);

		const { token } = response.data.data;
		const patients = decodeToken(token);

		return patients;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getPatient
 * @description Get a patient.
 * @param {string} patientId
 * @return {object} data - The patient data.
 */
export const getPatient = async patientId => {
	try {
		const response = await axios.get(`/patient/${patientId}`);
		const { token } = response.data.data;
		const data = decodeToken(token);

		return data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name createPatient
 * @description Create a new patient.
 * @param {object} data
 * @returns
 */
export const createPatient = async data => {
	try {
		const token = encodeToken(data);
		const response = await axios.post('/patient/create', { token });

		return response.data;
	} catch (error) {
		throw error.response.data;
	}
};

/**
 * @name updatePatient
 * @description Update a patient.
 * @param {object} data
 * @returns
 */
export const updatePatient = async data => {
	try {
		const token = encodeToken(data);
		const response = await axios.patch(
			`/patient/update/${data.patientId}`,
			{
				token,
			},
		);

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name deletedPatient
 * @description Delete a patient.
 * @param {string} patientId
 * @returns
 */
export const deletedPatient = async patientId => {
	try {
		const response = await axios.delete(`/patient/delete/${patientId}`);

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getDataSmartWatchPatient
 * @description Get data from smartwatch patient.
 * @param {string} patientId
 * @returns
 */
export const getDataSmartWatchPatient = async imei => {
	try {
		const response = await axios.get(`/watch/get/all/data/${imei}`);
		const { token } = response.data.data;
		const data = decodeToken(token);

		return data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getDataSmartWatchRefreshPatient
 * @description Get data from smartwatch patient.
 * @param {string} patientId
 * @returns
 */
export const getDataSmartWatchRefreshPatient = async imei => {
	try {
		const response = await axios.post(
			`/watch/socket/update/vitalsAndLocationData/${imei}`,
		);

		return response;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getAllPatientVitalsData
 * @description Get all vitals data of a patient from the specified initiation date.
 * @param {string} patientId - The ID of the patient.
 * @param {string} initiateDate - The initiation date from which to fetch the vitals data.
 * @creator Daniel Moreno Acedo, Web Developer at Unknown Gravity
 */
export const getAllPatientVitalsData = async (patientId, initiateDate) => {
	try {
		const response = await axios.post(
			`/patient/get/all/vitalsData/${patientId}`,
			{ initiateDate },
		);

		const { token } = response.data.data;
		const data = decodeToken(token);

		return data;
	} catch (error) {
		throw error.response.data.message;
	}
};
