import {
	Box,
	Button,
	Flex,
	Heading,
	Stack,
	useDisclosure,
} from '@chakra-ui/react';
import { FormPageViewPatients } from './subcomponents/FormPageViewPatients';
import { FormModalPatientNotAssigned } from '../modal/FormModalPatientNotAssigned';
import { useEffect, useState } from 'react';
import { getAllPatientsByUser } from '../../../services/UserServices';
import { useSelector } from 'react-redux';

export const FormViewPatients = ({
	onClose,
	setUserIdSelect,
	isFinishGetAllPatients,
	setIsFinishGetAllPatients,
	userIdSelect,
	isReloadPatientsByUser,
	setIsReloadPatientsByUser,
}) => {
	const { user } = useSelector(state => state.auth);
	const { selectCenter } = useSelector(state => state.selectedCenter);
	const [allPatients, setAllPatients] = useState([]);
	const [needReload, setNeedReload] = useState(true);
	const {
		isOpen: isOpenModalPatientNotAssigned,
		onOpen: onOpenModalPatientNotAssigned,
		onClose: onCloseModalPatientNotAssigned,
	} = useDisclosure();

	useEffect(() => {
		const getAllCenter = async () => {
			setNeedReload(false);
			const patients = await getAllPatientsByUser(userIdSelect);
			setAllPatients(patients);
			setIsFinishGetAllPatients(true);
			setIsReloadPatientsByUser(!isReloadPatientsByUser);
		};

		if (!isFinishGetAllPatients || needReload) {
			getAllCenter();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		setIsFinishGetAllPatients,
		userIdSelect,
		isReloadPatientsByUser,
		needReload,
	]);

	return (
		<>
			<Flex align={'center'} justify={'center'}>
				<Stack spacing={8} mx={'auto'} maxW={'lg'} w='100%'>
					<Box rounded={'lg'} bg={'white'} p={3}>
						<Heading textAlign={'center'} fontSize={'md'} mb={5}>
							Pacientes Asignados
						</Heading>
						<FormPageViewPatients
							allPatients={allPatients}
							isFinishGetAllPatients={isFinishGetAllPatients}
							setNeedReload={setNeedReload}
							userIdSelect={userIdSelect}
						/>
						<Flex w='100%' gap={4}>
							{(selectCenter?.role === 'admin' ||
								user?.isAdmin) && (
								<Button
									loadingText='Submitting'
									size='lg'
									w={'70%'}
									bg={'teal300'}
									color={'white'}
									transition={'0.5s'}
									_hover={{
										bg: 'gray.600',
										color: 'white',
									}}
									onClick={onOpenModalPatientNotAssigned}
								>
									Añadir más pacientes
								</Button>
							)}

							<Button
								loadingText='Submitting'
								size='lg'
								w={'30%'}
								bg={'gray.700'}
								color={'white'}
								transition={'0.5s'}
								_hover={{
									bg: 'gray.600',
									color: 'white',
								}}
								onClick={() => {
									onClose();
									setAllPatients([]);
									setUserIdSelect('');
									setIsFinishGetAllPatients(false);
								}}
							>
								Cerrar
							</Button>
						</Flex>
					</Box>
				</Stack>
			</Flex>
			<FormModalPatientNotAssigned
				isOpen={isOpenModalPatientNotAssigned}
				onClose={onCloseModalPatientNotAssigned}
				userIdSelect={userIdSelect}
				isReloadPatientsByUser={isReloadPatientsByUser}
				setIsReloadPatientsByUser={setIsReloadPatientsByUser}
				setIsFinishGetAllPatients={setIsFinishGetAllPatients}
				setNeedReload={setNeedReload}
			/>
		</>
	);
};
