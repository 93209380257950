import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getMe } from '../../redux/reducers/AuthReducer';
import { AbsoluteCenter, Container, Heading, Spinner } from '@chakra-ui/react';

export const AuthGuard = () => {
	const [needReload, setNeedReload] = useState(true);
	const { isSuccess, isLoading } = useSelector(state => state.auth);
	const dispatch = useDispatch();

	useEffect(() => {
		if (needReload && !isSuccess) {
			setNeedReload(false);
			dispatch(getMe());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [needReload, isLoading, isSuccess]);

	return isLoading ? (
		<Container
			position={'relative'}
			w={'100vw'}
			h={'100vh'}
			textAlign={'center'}
		>
			<AbsoluteCenter>
				<Spinner size={'xl'} color='teal300' />
				<Heading size={'md'}>Cargando...</Heading>
			</AbsoluteCenter>
		</Container>
	) : isSuccess ? (
		<Outlet />
	) : (
		<Navigate replace to='/' />
	);
};
