import { useState, useEffect, useRef } from 'react';
import {
	Box,
	Button,
	Input,
	VStack,
	Text,
	List,
	ListItem,
	Checkbox,
	HStack,
	Switch,
	FormControl,
	FormLabel,
	useToast,
	IconButton,
	Tooltip,
	useDisclosure,
	Stack,
	useBreakpointValue,
	Flex,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { errorToast, okToast } from '../../../../utils/toast';
import { RxCross1 } from 'react-icons/rx';
import { FaRegEdit } from 'react-icons/fa';
import { weekdays, weekDays, scrollbar } from '../../../../data/CONSTANTS';
import {
	createReminder,
	deleteReminder,
	updateReminder,
	toggleReminder,
} from '../../../../services/ReminderServices';
import { AlertDeleteReminder } from './AlertDeleteReminder';
import { parseReminderSetting } from '../../../../utils/ParseReminderSetting';
import { formatReminderData } from '../../../../utils/FormatReminderData';

export const FormMedicineReminder = ({ inputUser, setInputUser }) => {
	const {
		isOpen: isOpenAlert,
		onOpen: onOpenAlert,
		onClose: onCloseAlert,
	} = useDisclosure();
	const [title, setTitle] = useState('');
	const [time, setTime] = useState('');
	const [repeatAlarm, setRepeatAlarm] = useState(true);
	const [days, setDays] = useState([]);
	const [reminders, setReminders] = useState(inputUser.reminders || []);
	const [allDaysChecked, setAllDaysChecked] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [editIndex, setEditIndex] = useState(null);
	const [reminderToDelete, setReminderToDelete] = useState(null);
	const toast = useToast();
	const containerRef = useRef(null);
	const isMobile = useBreakpointValue({ base: true, md: false });

	useEffect(() => {
		setReminders(inputUser.reminders || []);
	}, [inputUser.reminders]);

	const handleAddOrSaveReminder = async () => {
		try {
			if (!title || !time) {
				errorToast(toast, 'Rellene los campos obligatorios.');
				return;
			}

			const newReminder = {
				title,
				time,
				repeatAlarm,
				days,
				active: true,
			};
			const formattedReminder = formatReminderData(
				newReminder,
				inputUser,
			);
			let updatedReminders = reminders;

			if (isEditing) {
				const reminderId = reminders[editIndex].reminderId;
				await updateReminder(reminderId, formattedReminder);

				const updatedList = updatedReminders.filter(
					_reminder => _reminder.reminderId !== reminderId,
				);
				updatedList.push({ reminderId, ...formattedReminder });
				updatedReminders = updatedList;
				okToast(
					toast,
					'El recordatorio se ha actualizado correctamente.',
				);
				setIsEditing(false);
				setEditIndex(null);
			} else {
				const response = await createReminder(formattedReminder);
				const reminderId = response.data.reminderId;
				updatedReminders.push({ reminderId, ...formattedReminder });
				okToast(toast, 'El recordatorio se ha añadido correctamente.');

				setIsEditing(false);
				setEditIndex(updatedReminders.length - 1);
			}

			setReminders(updatedReminders);
			setInputUser({ ...inputUser, reminders: updatedReminders });
			resetForm();
		} catch (error) {
			if (error === 'Not imei found for patient') {
				errorToast(
					toast,
					'Reloj desconectado, no se puede añadir recordatorio',
				);
			} else {
				errorToast(toast, 'Error al actualizar el recordatorio');
			}
		}
	};

	const handleDeleteReminder = async () => {
		try {
			await deleteReminder(reminderToDelete);
			const updatedReminders = reminders.filter(
				reminder => reminder.reminderId !== reminderToDelete,
			);
			setReminders(updatedReminders);
			setInputUser({ ...inputUser, reminders: updatedReminders });
			okToast(toast, 'El recordatorio se ha eliminado correctamente.');
			onCloseAlert();
		} catch (error) {
			errorToast(toast, 'Error al eliminar el recordatorio');
		}
	};

	const handleEditReminder = reminderId => {
		const reminder = reminders.find(
			reminder => reminder.reminderId === reminderId,
		);
		const parsedReminder = parseReminderSetting(reminder.reminderSetting);
		setTitle(reminder.reminderText);
		setTime(parsedReminder.time);
		setRepeatAlarm(!reminder.notRepeat);
		setDays([...parsedReminder.days]);
		setAllDaysChecked(parsedReminder.days.includes('Todos los días'));
		setIsEditing(true);
		setEditIndex(reminders.findIndex(rem => rem.reminderId === reminderId));

		if (isMobile && containerRef.current) {
			containerRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const handleToggleActive = async reminderId => {
		try {
			const updatedReminders = reminders.map(reminder => {
				if (reminder.reminderId === reminderId) {
					return {
						...reminder,
						active: !reminder.active,
					};
				}
				return reminder;
			});

			setReminders(updatedReminders);
			setInputUser({ ...inputUser, reminders: updatedReminders });

			await toggleReminder(reminderId);

			okToast(toast, `El recordatorio se ha modificado correctamente.`);
		} catch (error) {
			errorToast(toast, 'Error al cambiar el estado del recordatorio');
		}
	};

	const resetForm = () => {
		setTitle('');
		setTime('');
		setRepeatAlarm(true);
		setDays([]);
		setAllDaysChecked(false);
	};

	const handleCheckboxChange = day => {
		if (day === 'Todos los días') {
			const newAllDaysChecked = !allDaysChecked;
			setAllDaysChecked(newAllDaysChecked);
			setDays(newAllDaysChecked ? weekdays : []);
			setRepeatAlarm(newAllDaysChecked);
		} else {
			const updatedDays = days.includes(day)
				? days.filter(d => d !== day)
				: [...days, day];
			setDays(updatedDays);

			if (updatedDays.length === weekDays.length) {
				setAllDaysChecked(true);
				setRepeatAlarm(true);
			} else {
				setAllDaysChecked(false);
			}
		}
	};

	return (
		<Box p={4} ref={containerRef}>
			<VStack spacing={4} align='stretch'>
				<FormControl isRequired>
					<FormLabel htmlFor='title'>
						Título del Recordatorio
					</FormLabel>
					<Stack
						spacing={4}
						direction={{ base: 'column', md: 'row' }}
					>
						<Input
							id='title'
							placeholder='Anota aquí el recordatorio'
							maxLength='35'
							value={title}
							onChange={e => setTitle(e.target.value)}
						/>
						<Input
							id='time'
							type='time'
							value={time}
							onChange={e => setTime(e.target.value)}
							width={{ base: '100%', md: '30%' }}
						/>
					</Stack>
				</FormControl>
				<FormControl>
					<VStack spacing={2} align='start'>
						<Checkbox
							isChecked={allDaysChecked}
							onChange={() =>
								handleCheckboxChange('Todos los días')
							}
						>
							<strong>Todos los días</strong>
						</Checkbox>
					</VStack>
					<VStack spacing={2} align='start' mt='2'>
						<Stack direction={{ base: 'column', md: 'row' }}>
							{weekDays.map(({ name, initial }, index) => (
								<Checkbox
									key={index}
									isChecked={days.includes(initial)}
									onChange={() =>
										handleCheckboxChange(initial)
									}
									opacity={allDaysChecked ? 0.5 : 1}
									pointerEvents={
										allDaysChecked ? 'none' : 'auto'
									}
								>
									{name}
								</Checkbox>
							))}
						</Stack>
					</VStack>
				</FormControl>
				<FormControl>
					<FormLabel>Repetir Semanalmente</FormLabel>
					<Switch
						id='repeat-alarm'
						isChecked={repeatAlarm}
						onChange={e => {
							if (!allDaysChecked) {
								setRepeatAlarm(e.target.checked);
							}
						}}
						isDisabled={allDaysChecked}
					/>
				</FormControl>
				<Button
					colorScheme='teal'
					onClick={handleAddOrSaveReminder}
					leftIcon={<AddIcon />}
				>
					{isEditing ? 'Guardar' : 'Añadir'} Recordatorio en la lista
				</Button>
			</VStack>
			<Box mt={8}>
				<Text fontSize='xl' mb={4}>
					Recordatorios
				</Text>
				<List
					spacing={3}
					maxHeight={{ base: 'none', md: '290px' }}
					overflowY={{ base: 'auto', md: 'auto' }}
					sx={scrollbar}
				>
					{reminders.map((reminder, index) => (
						<ListItem
							key={index}
							borderRadius='md'
							borderWidth='1px'
							p={2}
							display='flex'
							alignItems={{ base: 'stretch', md: 'center' }}
							justifyContent={{
								base: 'space-between',
								md: 'space-between',
							}}
							flexDirection={{ base: 'column', md: 'row' }}
						>
							{isMobile ? (
								<Flex
									flexDirection='row'
									justifyContent='space-between'
									width='100%'
								>
									<Flex
										flexDirection='column'
										alignItems='center'
										flex='1'
										mr={2}
									>
										<Text
											fontSize='sm'
											fontWeight='bold'
											textTransform='uppercase'
											whiteSpace='nowrap'
											overflow='hidden'
											textOverflow='ellipsis'
											textAlign='center'
											maxWidth='120px'
										>
											{reminder.reminderText}
										</Text>
										<Text
											fontSize='2xl'
											fontWeight='bold'
											textAlign='center'
										>
											{
												parseReminderSetting(
													reminder.reminderSetting,
												).time
											}
										</Text>
										<Text fontSize='xs' textAlign='center'>
											Repetir semanalmente:{' '}
											{reminder.notRepeat ? 'No' : 'Sí'}
										</Text>
									</Flex>
									<Flex
										flexDirection='column'
										alignItems='center'
										justifyContent='space-between'
										flex='1'
										ml={2}
									>
										<Text fontSize='xs' textAlign='center'>
											{parseReminderSetting(
												reminder.reminderSetting,
											).days.join(', ')}
										</Text>
										<Switch
											isChecked={reminder.active}
											onChange={() =>
												handleToggleActive(
													reminder.reminderId,
												)
											}
											colorScheme='green'
											mb={2}
											size='lg'
										/>
										<HStack spacing={1}>
											<Tooltip label='Editar'>
												<IconButton
													icon={<FaRegEdit />}
													onClick={() =>
														handleEditReminder(
															reminder.reminderId,
														)
													}
													size='sm'
													aria-label='Editar'
												/>
											</Tooltip>
											<Tooltip label='Eliminar'>
												<IconButton
													icon={<RxCross1 />}
													onClick={() => {
														setReminderToDelete(
															reminder.reminderId,
														);
														onOpenAlert();
													}}
													size='sm'
													aria-label='Eliminar'
												/>
											</Tooltip>
										</HStack>
									</Flex>
								</Flex>
							) : (
								<Box
									display='flex'
									width='100%'
									justifyContent='space-between'
									alignItems='center'
									flexWrap='wrap'
									flexDirection={{
										base: 'column',
										md: 'row',
									}}
								>
									<Switch
										isChecked={reminder.active}
										onChange={() =>
											handleToggleActive(
												reminder.reminderId,
											)
										}
										colorScheme='green'
										mb={{ base: 2, md: 0 }}
										size='md'
									/>
									<Text
										flex='3'
										ml={4}
										fontSize={{ base: 'sm', md: 'sx' }}
										mb={{ base: 2, md: 0 }}
										fontWeight='bold'
										textTransform='uppercase'
									>
										{reminder.reminderText}
									</Text>
									<Text
										flex='1'
										fontSize={{ base: 'lg', md: 'lg' }}
										fontWeight='bold'
										mb={{ base: 2, md: 0 }}
										textAlign='right'
									>
										{
											parseReminderSetting(
												reminder.reminderSetting,
											).time
										}
									</Text>
									<Text
										flex='2'
										fontSize={{ base: 'xs', md: 'sm' }}
										mb={{ base: 2, md: 0 }}
										textAlign='right'
									>
										Repetir semanalmente:{' '}
										{reminder.notRepeat ? 'No' : 'Sí'}
									</Text>
									<Text
										flex='2'
										fontSize={{ base: 'xs', md: 'sm' }}
										mb={{ base: 2, md: 0 }}
										textAlign='center'
									>
										{parseReminderSetting(
											reminder.reminderSetting,
										).days.join(', ')}
									</Text>
									<HStack
										spacing={2}
										flex='0.5'
										mb={{ base: 2, md: 0 }}
									>
										<Tooltip label='Editar'>
											<IconButton
												icon={<FaRegEdit />}
												onClick={() =>
													handleEditReminder(
														reminder.reminderId,
													)
												}
												size='sm'
												aria-label='Editar'
											/>
										</Tooltip>
										<Tooltip label='Eliminar'>
											<IconButton
												icon={<RxCross1 />}
												onClick={() => {
													setReminderToDelete(
														reminder.reminderId,
													);
													onOpenAlert();
												}}
												size='sm'
												aria-label='Eliminar'
											/>
										</Tooltip>
									</HStack>
								</Box>
							)}
						</ListItem>
					))}
				</List>
			</Box>
			<AlertDeleteReminder
				isOpen={isOpenAlert}
				onClose={onCloseAlert}
				onDelete={handleDeleteReminder}
			/>
		</Box>
	);
};
