import axios from 'axios';
import { decodeToken, encodeToken } from '../utils/TokenUtils';

export const getAllWatches = async () => {
	try {
		const response = await axios.get('/watch/all');
		const { token } = response.data.data;
		const data = decodeToken(token);

		return data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getWatchById
 * @description This function returns a watch by its id
 * @param {string} watchId
 * @return {object} watch - A watch object
 */
export const getWatchById = async watchId => {
	try {
		const response = await axios.get(`/watch/id/${watchId}`);
		const { token } = response.data.data;
		const data = decodeToken(token);

		return data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getWatchByImei
 * @description This function returns a watch by its imei
 * @param {string} imei
 * @return {object} watch - A watch object
 */
export const getWatchByImei = async imei => {
	try {
		const response = await axios.get(`/watch/${imei}`);
		const { token } = response.data.data;
		const data = decodeToken(token);

		return data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getAllWatchesToCenter
 * @description This function returns all watches to center
 * @param {string} centerId
 * @return {array} watches - An array of watches
 */
export const getAllWatchesToCenter = async centerId => {
	try {
		const response = await axios.get(`/watch/all/${centerId}`);
		const { token } = response.data.data;
		const data = decodeToken(token);

		return data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name createWatch
 * @description This function creates a new watch
 * @param {object} data
 * @return
 */
export const createWatch = async data => {
	try {
		const token = encodeToken(data);
		const response = await axios.post('/watch/create', { token });

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name updateWatch
 * @description This function updates a watch
 * @param {string} watchId
 * @param {object} data
 * @return
 */
export const updateWatch = async data => {
	try {
		const { watchId } = data;
		const token = encodeToken(data);
		const response = await axios.patch(`/watch/update/${watchId}`, {
			token,
		});

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name deleteWatch
 * @description This function deletes a watch
 * @param {string} watchId
 * @return
 */
export const deleteWatch = async watchId => {
	try {
		const response = await axios.delete(`/watch/delete/${watchId}`);

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name assingSmartWatch
 * @description This function assigns a smartwatch
 * @param {object} data
 * @returns
 */
export const assingSmartWatch = async data => {
	try {
		// let responsePatient, responseCenter;
		const { watchId, centerId, patientId } = data;

		// if (!centerId) {
		// 	throw new Error('Debe seleccionar un centro');
		// }

		const response = await axios.patch(
			`/watch/assing/patientCenter/${watchId}`,
			{
				centerId,
				patientId,
			},
		);

		// if (patientId) {
		// 	responseCenter = await axios.patch(
		// 		`/watch/assign/center/${watchId}`,
		// 		{
		// 			centerId,
		// 		},
		// 	);

		// 	responsePatient = await axios.patch(
		// 		`/watch/assign/patient/${watchId}`,
		// 		{
		// 			patientId,
		// 		},
		// 	);
		// } else {
		// 	responseCenter = await axios.patch(
		// 		`/watch/assign/center/${watchId}`,
		// 		{ centerId },
		// 	);
		// }

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};
