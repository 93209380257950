const hostname = window.location.hostname;

// 1. PROD -> app.angelsonwatch.com
// 2. DEV -> dev.angelsonwatch.com
// 3. LOCAL -> localhost:3000
const PROD_ROUTE = 'app.angelsonwatch.com';
const DEV_ROUTE = 'dev.angelsonwatch.com';

let route;
if (hostname === PROD_ROUTE) {
	route = 'api.angelsonwatch.com';
} else if (hostname === DEV_ROUTE) {
	route = 'devapi.angelsonwatch.com';
} else {
	route = `${hostname}:3333`;
}

export const IS_PRODUCTION_API_ROUTE =
	!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
		? `http://${route}`
		: `https://${route}`;

export const IS_PRODUCTION_WS_ROUTE =
	!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
		? `ws://${route}`
		: `wss://${route}`;

export const API_ROUTE = IS_PRODUCTION_API_ROUTE;
export const WS_ROUTE = IS_PRODUCTION_WS_ROUTE;
console.log(API_ROUTE, WS_ROUTE);

export const weekdays = [
	'Domingo',
	'Lunes',
	'Martes',
	'Miércoles',
	'Jueves',
	'Viernes',
	'Sábado',
];

export const weekDays = [
	{
		name: 'Domingo',
		initial: 'D',
	},
	{
		name: 'Lunes',
		initial: 'L',
	},
	{
		name: 'Martes',
		initial: 'M',
	},
	{
		name: 'Miércoles',
		initial: 'X',
	},
	{
		name: 'Jueves',
		initial: 'J',
	},
	{
		name: 'Viernes',
		initial: 'V',
	},
	{
		name: 'Sábado',
		initial: 'S',
	},
];
export const weekdayInitials = ['D', 'L', 'M', 'X', 'J', 'V', 'S'];

export const scrollbar = {
	'&::-webkit-scrollbar': {
		width: '8px',
	},
	'&::-webkit-scrollbar-track': {
		background: '#f1f1f1',
	},
	'&::-webkit-scrollbar-thumb': {
		background: '#888',
	},
	'&::-webkit-scrollbar-thumb:hover': {
		background: '#555',
	},
};
