import {
	Box,
	Center,
	Spinner,
	Stack,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { HeaderAll } from '../general/HeaderAll';
// import { FilterNavbar } from './subcomponents/FilterNavbar';
import { useEffect, useState } from 'react';
import { getAllPacients } from '../../services/PatientServices';
import { CardPatient } from './subcomponents/CardPatient';
import { FormModalPatient } from './modal/FormModalPatient';
import { useSelector } from 'react-redux';
import { initiateDataPacient } from '../../data/initData';

export const ViewAllPatients = () => {
	const [inputUser, setInputUser] = useState(initiateDataPacient);
	const { user } = useSelector(state => state.auth);
	const { selectCenter } = useSelector(state => state.selectedCenter);
	const [patients, setPatients] = useState([]);
	const [isReload, setIsReload] = useState(false);
	const [isFinishGetAllPatients, setIsFinishGetAllPatients] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const {
		isOpen: isOpenModalCreatePatient,
		onOpen: onOpenModalCreatePatient,
		onClose: onCloseModalCreatePatient,
	} = useDisclosure();
	const AllPatients = async () => {
		const data = await getAllPacients();
		setPatients(data);

		setIsFinishGetAllPatients(true);
	};

	/* const AllPatientsAssignedToUser = async () => {}; */

	useEffect(() => {
		AllPatients();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectCenter, user, isReload]);

	/* const onChangeSwitch = e => {
		const { checked } = e.target;
		if (!checked) {
			AllPatients();
		} else {
			AllPatientsAssignedToUser();
		}
	}; */

	return (
		<>
			<Stack m={{ base: 5, lg: 10 }}>
				<HeaderAll
					title='Pacientes'
					onOpen={onOpenModalCreatePatient}
				/>
				<Stack>
					{/* <FilterNavbar onChangeSwitch={onChangeSwitch} /> */}
					{!isFinishGetAllPatients ? (
						<Center p={5}>
							<Spinner
								thickness='4px'
								speed='0.65s'
								emptyColor='gray.200'
								color='teal300'
								size='xl'
							/>
						</Center>
					) : (
						<>
							{patients.length === 0 && isFinishGetAllPatients ? (
								<Center minW={'100%'} py={4}>
									<Text fontSize='2xl'>
										No hay pacientes asignados
									</Text>
								</Center>
							) : (
								patients.map(patient => (
									<Box key={patient.patientId}>
										<CardPatient
											patient={patient}
											isReload={isReload}
											setIsReload={setIsReload}
											setIsUpdate={setIsUpdate}
											onOpen={onOpenModalCreatePatient}
											setInputUser={setInputUser}
										/>
									</Box>
								))
							)}
						</>
					)}
				</Stack>
			</Stack>
			<FormModalPatient
				isOpen={isOpenModalCreatePatient}
				onClose={onCloseModalCreatePatient}
				setInputUser={setInputUser}
				inputUser={inputUser}
				isUpdate={isUpdate}
				setIsUpdate={setIsUpdate}
				setIsReload={setIsReload}
				isReload={isReload}
				updatePatients={AllPatients}
			/>
		</>
	);
};
