export const okToast = (toast, title, message) => {
	toast({
		title,
		description: message,
		status: 'success',
		duration: 3000,
		isClosable: true,
		position: 'top',
		zindex: 9999,
	});
};

export const errorToast = (toast, title, message, duration) => {
	toast({
		title,
		description: message,
		status: 'warning',
		duration: duration ?? 3000,
		isClosable: true,
		position: 'top',
		zindex: 9999,
	});
};
