import { AbsoluteCenter, Spinner, Text } from '@chakra-ui/react';
import { loadPlayer } from 'rtsp-relay/browser';
import { useEffect, useRef, useState } from 'react';
import { WS_ROUTE } from '../../data/CONSTANTS';

export const CameraStream = ({ id }) => {
	const [isLoading, setIsLoading] = useState(true);
	const canvasRef = useRef(null);

	useEffect(() => {
		const player = loadPlayer({
			url: `${WS_ROUTE}/camera/stream/${id}`,
			canvas: canvasRef.current,
			onDisconnet: () => console.log('disconnected'),
		}).then(() => {
			setIsLoading(false);
		});

		return () => {
			player.finally();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCanvasClick = () => {
		const canvas = canvasRef.current;
		if (canvas) {
			if (canvas.requestFullscreen) {
				canvas.requestFullscreen();
			} else if (canvas.mozRequestFullScreen) {
				/* Firefox */
				canvas.mozRequestFullScreen();
			} else if (canvas.webkitRequestFullscreen) {
				/* Chrome, Safari & Opera */
				canvas.webkitRequestFullscreen();
			} else if (canvas.msRequestFullscreen) {
				/* IE/Edge */
				canvas.msRequestFullscreen();
			}
		}
	};

	return (
		<>
			{!id && (
				<AbsoluteCenter>
					<Text>No hay camara asignada</Text>
				</AbsoluteCenter>
			)}

			{isLoading && id && (
				<AbsoluteCenter>
					<Spinner size={'xl'} color='teal.300' />
				</AbsoluteCenter>
			)}
			<canvas
				style={{
					width: '100%',
					height: '100%',
					borderRadius: '10px',
					display: !isLoading && !id ? 'none' : 'block',
				}}
				ref={canvasRef}
				onClick={handleCanvasClick}
			/>
		</>
	);
};
