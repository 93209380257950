import {
	Heading,
	Text,
	Button,
	HStack,
	Image,
	VStack,
	Link,
	useToast,
	useDisclosure,
	Stack,
	GridItem,
	Grid,
} from '@chakra-ui/react';
import { errorToast, okToast } from '../../../utils/toast';
import { deletedCenter } from '../../../services/CenterServices';
import { AlertDeleteCenter } from './AlertDeleteCenter';

export const CardsCenters = ({
	center,
	isReload,
	setIsReload,
	onOpenFormModal,
	setIsUpdateData,
	setDataCenterUpdate,
}) => {
	const {
		isOpen: isOpenAlert,
		onOpen: onOpenAlert,
		onClose: onCloseAlert,
	} = useDisclosure();
	const toast = useToast();
	const {
		name,
		cifDni,
		email,
		logoUrl,
		phoneNumber,
		address,
		province,
		city,
		websiteUrl,
	} = center;

	const onDelete = async () => {
		try {
			const deleted = await deletedCenter(center.centerId);

			if (deleted) {
				okToast(toast, 'Centro eliminado con éxito');
				setIsReload(!isReload);
			}
		} catch (error) {
			errorToast(toast, 'Error al eliminar centro', error.message);
		}
	};

	const openFormUpdateModal = () => {
		setIsUpdateData(true);
		setDataCenterUpdate(center);
		onOpenFormModal();
	};

	return (
		<>
			<Grid
				templateColumns={{
					base: 'repeat(1,2fr)',
					xl: 'repeat(5, 1fr)',
				}}
				columnGap={3}
				bg={'white'}
				p={3}
				gap={3}
				h={'100%'}
				borderRadius={5}
			>
				<GridItem colSpan={{ base: 0, xl: 0 }}>
					<VStack gap={0}>
						<Image
							w={'100px'}
							rounded={'full'}
							bgSize='cover'
							src={`https://angels-on-watch.s3.eu-west-3.amazonaws.com/centers/${logoUrl}`}
							onError={e => {
								e.target.src =
									'https://angels-on-watch.s3.eu-west-3.amazonaws.com/centers/default.png';
							}}
							css={{
								border: '2px solid white',
							}}
						/>
						<Heading
							fontSize={'xl'}
							fontWeight={500}
							fontFamily={'body'}
							textAlign={'center'}
						>
							{name}
						</Heading>
						{websiteUrl !== '' && (
							<Link href={`https://${websiteUrl}`} isExternal>
								{websiteUrl}
							</Link>
						)}
					</VStack>
				</GridItem>

				<GridItem
					w={'100%'}
					colSpan={{ base: 1, xl: 1 }}
					alignContent={'center'}
				>
					<HStack justifyContent={'left'}>
						<Text fontWeight={'bold'}>CIF/DNI:</Text>
						<Text>{cifDni}</Text>
					</HStack>
					<HStack>
						<Text fontWeight={'bold'}>Email:</Text>
						<Text>{email}</Text>
					</HStack>
					<HStack>
						<Text fontWeight={'bold'}>Teléfono:</Text>
						<Text>{phoneNumber}</Text>
					</HStack>
				</GridItem>

				<GridItem
					w={'100%'}
					colSpan={{ base: 0, xl: 2 }}
					alignContent={'center'}
				>
					<HStack>
						<Text fontWeight={'bold'}>Dirección:</Text>
						<Text>{address}</Text>
					</HStack>
					<HStack>
						<Text fontWeight={'bold'}>Provincia:</Text>
						<Text>{province}</Text>
					</HStack>
					<HStack>
						<Text fontWeight={'bold'}>Ciudad:</Text>
						<Text>{city}</Text>
					</HStack>
				</GridItem>

				<GridItem colSpan={{ base: 0, xl: 0 }} alignContent={'center'}>
					<Stack
						direction={{ base: 'row', xl: 'column' }}
						justify={'center'}
					>
						<Button
							bg={'teal300'}
							color={'white'}
							rounded={'md'}
							_hover={{
								transform: 'translateY(-2px)',
								boxShadow: 'lg',
							}}
							onClick={() => openFormUpdateModal()}
						>
							Editar
						</Button>
						<Button
							bg={'red'}
							color={'white'}
							rounded={'md'}
							_hover={{
								transform: 'translateY(-2px)',
								boxShadow: 'lg',
							}}
							onClick={() => {
								onOpenAlert();
							}}
						>
							Eliminar
						</Button>
					</Stack>
				</GridItem>
			</Grid>

			<AlertDeleteCenter
				isOpen={isOpenAlert}
				onClose={onCloseAlert}
				onDelete={onDelete}
			/>
		</>
	);
};
