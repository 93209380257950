/**
 * @name formatDateTime
 * @description Formats a given date string into a 'yyyy-mm-ddTHH:MM' format suitable for use with `datetime-local` input fields.
 * This function takes a date string, converts it into a JavaScript Date object, and extracts the year, month, day,
 * hours, and minutes. It then formats these components into a string that adheres to the 'yyyy-mm-ddTHH:MM' format,
 * which is commonly used in HTML5 date-time inputs.
 *
 * @param {string} dateString - A date string that can be parsed by JavaScript's Date constructor.
 * @returns {string} A string in the 'yyyy-mm-ddTHH:MM' format representing the date and time.
 * @creator Daniel Moreno Acedo, Web Developer at Unknown Gravity
 */
export const formatDateTime = dateString => {
	const date = new Date(dateString);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	return `${year}-${month}-${day}T${hours}:${minutes}`;
};
