import {
	Box,
	CloseButton,
	Divider,
	Flex,
	Image,
	Spacer,
	useMediaQuery,
} from '@chakra-ui/react';
import { FinalItem } from './FinalItem';
import { NavItemSup } from './NavItemSup';

export const SidebarContent = ({ onClose, ...rest }) => {
	const [isLargerThanSm] = useMediaQuery('(max-width: 480px)');
	const [isLargerThanMd] = useMediaQuery('(max-width: 768px)');
	const [isLargerThanXl] = useMediaQuery('(max-width: 1200px)');
	return (
		<Box
			transition='3s ease'
			bg={'gray.600'}
			color={'white'}
			borderRight='1px'
			borderRightColor={'gray.200'}
			w={{ base: 'xs', xl: 60 }}
			pos='fixed'
			h='full'
			{...rest}
		>
			<Flex flexDirection={'column'} h='full'>
				{(isLargerThanSm || isLargerThanMd || isLargerThanXl) && (
					<Flex alignItems='center' p={5} pb={0} justifyContent='end'>
						<CloseButton
							display={{ base: 'flex', xl: 'none' }}
							onClick={onClose}
						/>
					</Flex>
				)}
				<Flex justifyContent='center' p={5}>
					<Image src={'/logoWhite.png'} />
				</Flex>
				<NavItemSup onClose={onClose} />
				<Spacer />
				<Divider />
				<FinalItem />
			</Flex>
		</Box>
	);
};
