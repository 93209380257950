import {
	FormControl,
	FormLabel,
	Input,
	VStack,
	Stack,
	Grid,
	GridItem,
	Spacer,
} from '@chakra-ui/react';

export const FormDeviceData = ({
	handleChangeUser,
	inputUser,
	clickOnSubmit,
}) => {
	const handleInputChange = e => {
		const { value } = e.target;
		if (
			(/^\d{0,3}$/.test(value) && value === '') ||
			(parseInt(value) >= 0 && parseInt(value) <= 200)
		) {
			handleChangeUser(e);
		}
	};

	const handlePhoneChange = e => {
		const { value } = e.target;
		const phonePattern = /^[0-9\b]+$/;
		if (value === '' || phonePattern.test(value)) {
			handleChangeUser(e);
		}
	};

	return (
		<Stack
			spacing={6}
			width='100%'
			direction={{ base: 'column', lg: 'row' }}
			justifyContent='space-between'
		>
			<Stack
				spacing={6}
				width={{ base: '100%', lg: '30%' }}
				alignSelf='start'
				direction={{ base: 'column', lg: 'column' }}
			>
				<VStack spacing={2} align='start'>
					<FormLabel
						htmlFor='shock'
						minWidth='70px'
						fontWeight={'bold'}
						fontSize={{ base: 'md', sm: 'md' }}
					>
						SHOCK
					</FormLabel>
					<Grid
						templateColumns={{
							base: '1fr',
							sm: 'repeat(2, 1fr)',
						}}
						gap={4}
						width='100%'
					>
						<GridItem>
							<FormLabel htmlFor='shockThresholdMin'>
								Min.
							</FormLabel>
							<Input
								id='shockThresholdMin'
								name='defaultValueAlarmConfig.shock.shockThresholdMin'
								type='number'
								value={
									inputUser?.defaultValueAlarmConfig?.shock
										?.shockThresholdMin
								}
								onChange={handleInputChange}
								maxLength={3}
								width='100%'
							/>
						</GridItem>
						<GridItem>
							<FormLabel htmlFor='shockThresholdMax'>
								Max.
							</FormLabel>
							<Input
								id='shockThresholdMax'
								name='defaultValueAlarmConfig.shock.shockThresholdMax'
								type='number'
								value={
									inputUser?.defaultValueAlarmConfig?.shock
										?.shockThresholdMax
								}
								onChange={handleInputChange}
								maxLength={3}
								width='100%'
							/>
						</GridItem>
					</Grid>
				</VStack>

				<Stack spacing={2}>
					<FormLabel
						htmlFor='oxygen'
						minWidth='80px'
						fontWeight={'bold'}
						fontSize={{ base: 'md', sm: 'md' }}
					>
						OXÍGENO
					</FormLabel>
					<Grid
						templateColumns={{
							base: '1fr',
							sm: 'repeat(1, 1fr)',
						}}
						gap={4}
						width='100%'
					>
						<GridItem>
							<FormLabel htmlFor='oxygenThreshold'>
								Min.
							</FormLabel>
							<Input
								id='oxygenThreshold'
								name='defaultValueAlarmConfig.oxygen.oxygenThreshold'
								type='number'
								value={
									inputUser?.defaultValueAlarmConfig?.oxygen
										?.oxygenThreshold
								}
								onChange={handleInputChange}
								maxLength={3}
								width='100%'
							/>
						</GridItem>
					</Grid>
				</Stack>
			</Stack>

			<Spacer />

			<Stack
				spacing={6}
				width={{ base: '100%', lg: '30%' }}
				alignSelf='start'
				direction={{ base: 'column', lg: 'column' }}
			>
				<Stack spacing={2}>
					<FormLabel
						htmlFor='lowBattery'
						minWidth='70px'
						fontWeight={'bold'}
						fontSize={{ base: 'md', sm: 'md' }}
					>
						BATERÍA BAJA
					</FormLabel>
					<Grid
						templateColumns={{
							base: '1fr',
							sm: 'repeat(1, 1fr)',
						}}
						gap={4}
						width='100%'
					>
						<GridItem>
							<FormLabel htmlFor='lowBatteryThreshold'>
								Min.
							</FormLabel>
							<Input
								id='lowBatteryThreshold'
								name='defaultValueAlarmConfig.lowBattery.lowBatteryThreshold'
								type='number'
								value={
									inputUser?.defaultValueAlarmConfig
										?.lowBattery?.lowBatteryThreshold
								}
								onChange={handleInputChange}
								maxLength={3}
								width='100%'
							/>
						</GridItem>
					</Grid>
				</Stack>

				<Stack spacing={2}>
					<FormLabel
						htmlFor='bloodPressure'
						minWidth='70px'
						fontWeight={'bold'}
						fontSize={{ base: 'md', sm: 'md' }}
					>
						PRESIÓN ARTERIAL
					</FormLabel>
					<Grid
						templateColumns={{
							base: '1fr',
							sm: 'repeat(2, 1fr)',
						}}
						gap={4}
						width='100%'
					>
						<GridItem>
							<FormLabel htmlFor='thresholdMinSystolic'>
								Sistólica Min.
							</FormLabel>
							<Input
								id='thresholdMinSystolic'
								name='defaultValueAlarmConfig.bloodPressure.thresholdMinSystolic'
								type='number'
								value={
									inputUser?.defaultValueAlarmConfig
										?.bloodPressure?.thresholdMinSystolic
								}
								onChange={handleInputChange}
								maxLength={3}
								width='100%'
							/>
						</GridItem>
						<GridItem>
							<FormLabel htmlFor='thresholdMaxSystolic'>
								Sistólica Max.
							</FormLabel>
							<Input
								id='thresholdMaxSystolic'
								name='defaultValueAlarmConfig.bloodPressure.thresholdMaxSystolic'
								type='number'
								value={
									inputUser?.defaultValueAlarmConfig
										?.bloodPressure?.thresholdMaxSystolic
								}
								onChange={handleInputChange}
								maxLength={3}
								width='100%'
							/>
						</GridItem>
						<GridItem>
							<FormLabel htmlFor='thresholdMinDiastolic'>
								Diastólica Min.
							</FormLabel>
							<Input
								id='thresholdMinDiastolic'
								name='defaultValueAlarmConfig.bloodPressure.thresholdMinDiastolic'
								type='number'
								value={
									inputUser?.defaultValueAlarmConfig
										?.bloodPressure?.thresholdMinDiastolic
								}
								onChange={handleInputChange}
								maxLength={3}
								width='100%'
							/>
						</GridItem>
						<GridItem>
							<FormLabel htmlFor='thresholdMaxDiastolic'>
								Diastólica Max.
							</FormLabel>
							<Input
								id='thresholdMaxDiastolic'
								name='defaultValueAlarmConfig.bloodPressure.thresholdMaxDiastolic'
								type='number'
								value={
									inputUser?.defaultValueAlarmConfig
										?.bloodPressure?.thresholdMaxDiastolic
								}
								onChange={handleInputChange}
								maxLength={3}
								width='100%'
							/>
						</GridItem>
					</Grid>
				</Stack>
			</Stack>

			<Spacer />

			<Stack
				spacing={6}
				width={{ base: '100%', lg: '30%' }}
				alignSelf='start'
				direction={{ base: 'column', lg: 'column' }}
			>
				<Stack spacing={2}>
					<FormLabel
						htmlFor='phoneAlerts'
						minWidth='80px'
						fontWeight={'bold'}
						fontSize={{ base: 'md', sm: 'md' }}
					>
						TELÉFONOS DE ALERTA
					</FormLabel>
					<FormControl
						isRequired
						isInvalid={clickOnSubmit && !inputUser.phoneAlert1}
					>
						<FormLabel htmlFor='phoneAlert1' minWidth='70px'>
							Teléfono Alerta 1
						</FormLabel>
						<Input
							id='phoneAlert1'
							name='phoneAlert1'
							type='tel'
							errorBorderColor='warning'
							value={inputUser?.phoneAlert1}
							onChange={handlePhoneChange}
							maxLength={15}
							width='100%'
						/>
					</FormControl>
				</Stack>

				<Stack spacing={2}>
					<FormControl
						isRequired
						isInvalid={clickOnSubmit && !inputUser.phoneAlert2}
					>
						<FormLabel htmlFor='phoneAlert2' minWidth='70px'>
							Teléfono Alerta 2
						</FormLabel>
						<Input
							id='phoneAlert2'
							name='phoneAlert2'
							type='tel'
							errorBorderColor='warning'
							value={inputUser?.phoneAlert2}
							onChange={handlePhoneChange}
							maxLength={15}
							width='100%'
						/>
					</FormControl>
				</Stack>

				<Stack spacing={2}>
					<FormControl
						isRequired
						isInvalid={
							clickOnSubmit && !inputUser.phoneAlertCentral
						}
					>
						<FormLabel htmlFor='phoneAlertCentral' minWidth='70px'>
							Tlf. de Alerta Central
						</FormLabel>
						<Input
							id='phoneAlertCentral'
							name='phoneAlertCentral'
							type='tel'
							errorBorderColor='warning'
							value={inputUser?.phoneAlertCentral}
							onChange={handlePhoneChange}
							maxLength={15}
							width='100%'
						/>
					</FormControl>
				</Stack>
			</Stack>
		</Stack>
	);
};
