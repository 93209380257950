import { useState } from 'react';
import { forgotPassword } from '../../services/UserServices';

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	FormControl,
	FormLabel,
	Input,
	useToast,
} from '@chakra-ui/react';
import { okToast, errorToast } from '../../utils/toast';
import { checkEmail } from '../../utils/CheckingData';
import { VerificationCodeModal } from './VerificationCodeModal';

export const ForgotPasswordModal = ({ isOpen, onClose }) => {
	const [email, setEmail] = useState('');
	const [emailToResend, setEmailToResend] = useState('');
	const [isVerificationOpen, setIsVerificationOpen] = useState(false);
	const toast = useToast();
	const handleChangeEmail = event => setEmail(event.target.value);

	const storeEmailToResend = () => {
		setEmailToResend(email);
	};

	const handleSubmit = async () => {
		try {
			const checkingEmail = checkEmail(email);
			if (!checkingEmail)
				return errorToast(
					toast,
					'Email incorrecto',
					'Por favor inserte un email válido',
				);

			const response = await forgotPassword(email);

			if (!response) {
				return errorToast(
					toast,
					'Error al intentar recuperar la contraseña',
				);
			}

			okToast(toast, 'Enviado email para restablecer contraseña');
			setEmail('');
			setIsVerificationOpen(true);
			storeEmailToResend();
		} catch (error) {
			errorToast(
				toast,
				'Error al intentar recuperar la contraseña',
				error.message,
			);
		}
	};

	const handleClose = () => {
		setEmail('');
		setIsVerificationOpen(false);
		onClose();
	};

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={handleClose}
				isCentered
				closeOnOverlayClick={false}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Recuperar Contraseña</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<p>Ingresa el email con el que te registraste.</p>
						<FormControl isRequired mt='4'>
							<FormLabel htmlFor='email'>Email</FormLabel>
							<Input
								id='email'
								type='email'
								value={email}
								onChange={handleChangeEmail}
							/>
						</FormControl>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme='teal'
							mr={3}
							onClick={handleSubmit}
						>
							Enviar
						</Button>
						<Button variant='redAow' onClick={onClose}>
							Cancelar
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<VerificationCodeModal
				isOpen={isVerificationOpen}
				onClose={handleClose}
				emailToResend={emailToResend}
			/>
		</>
	);
};
