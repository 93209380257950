import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { ProfilePatient } from './viewPatients/ProfilePatient';
export const ViewPatientModal = ({ onClose, isOpen, patient }) => {
	return (
		<>
			<Modal
				size={{ base: 'full', lg: '6xl' }}
				closeOnOverlayClick={false}
				onClose={onClose}
				isOpen={isOpen}
				isCentered
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Datos del paciente</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<ProfilePatient patient={patient} />
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};
