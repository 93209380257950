import {
	Flex,
	FormControl,
	FormLabel,
	Radio,
	RadioGroup,
	Stack,
	Textarea,
	VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export const FormMedicalData = ({
	handleChangeUser,
	setInputUser,
	inputUser,
}) => {
	const [covidSymptoms, setCovidSymptoms] = useState(
		inputUser?.covidSymptoms
			? inputUser?.covidSymptoms.toString()
			: 'false',
	);
	const [pcr, setPcr] = useState(
		inputUser?.pcr ? inputUser?.pcr.toString() : 'false',
	);
	const [antigen, setAntigen] = useState(
		inputUser?.antigen ? inputUser?.antigen.toString() : 'false',
	);
	const [testQuick, setTestQuick] = useState(
		inputUser?.testQuick ? inputUser?.testQuick.toString() : 'false',
	);

	useEffect(() => {
		setInputUser({
			...inputUser,
			covidSymptoms,
			pcr,
			antigen,
			testQuick,
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [antigen, pcr, covidSymptoms, testQuick]);

	return (
		<Flex gap={5}>
			<VStack w={'100%'}>
				<FormControl>
					<FormLabel htmlFor='diseases' fontWeight={'normal'}>
						Enfermedades
					</FormLabel>
					<Textarea
						id='diseases'
						name='diseases'
						resize={'none'}
						value={inputUser?.diseases}
						onChange={handleChangeUser}
					/>
				</FormControl>
				<FormControl>
					<FormLabel htmlFor='diagnosis' fontWeight={'normal'}>
						Diagnósticos
					</FormLabel>
					<Textarea
						id='diagnosis'
						name='diagnosis'
						resize={'none'}
						value={inputUser?.diagnosis}
						onChange={handleChangeUser}
					/>
				</FormControl>
				<FormControl>
					<FormLabel htmlFor='treatments' fontWeight={'normal'}>
						Tratamientos
					</FormLabel>
					<Textarea
						id='treatments'
						name='treatments'
						resize={'none'}
						value={inputUser?.treatments}
						onChange={handleChangeUser}
					/>
				</FormControl>
				<Stack
					w={'full'}
					justify={'space-around'}
					alignItems={'center'}
					direction={{ base: 'column', xl: 'row' }}
					gap={5}
				>
					<Stack
						w={'100%'}
						direction={'row'}
						justify={'space-around'}
					>
						<RadioGroup
							value={covidSymptoms}
							onChange={setCovidSymptoms}
						>
							<FormLabel>¿Síntomas covid?</FormLabel>
							<Stack direction='row' justify={'center'}>
								<Radio value={'true'}>Si</Radio>
								<Radio value={'false'}>No</Radio>
							</Stack>
						</RadioGroup>
						<RadioGroup value={pcr} onChange={setPcr}>
							<FormLabel>¿PCR hecha?</FormLabel>
							<Stack direction='row' justify={'center'}>
								<Radio value={'true'}>Si</Radio>
								<Radio value={'false'}>No</Radio>
							</Stack>
						</RadioGroup>
					</Stack>
					<Stack
						w={'100%'}
						direction={'row'}
						justify={'space-around'}
					>
						<RadioGroup value={antigen} onChange={setAntigen}>
							<FormLabel>¿Antígenos hecho?</FormLabel>
							<Stack direction='row' justify={'center'}>
								<Radio value={'true'}>Si</Radio>
								<Radio value={'false'}>No</Radio>
							</Stack>
						</RadioGroup>
						<RadioGroup value={testQuick} onChange={setTestQuick}>
							<FormLabel>¿Test rápido hecho?</FormLabel>
							<Stack direction='row' justify={'center'}>
								<Radio value={'true'}>Si</Radio>
								<Radio value={'false'}>No</Radio>
							</Stack>
						</RadioGroup>
					</Stack>
				</Stack>
			</VStack>
		</Flex>
	);
};
