import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { FormCreateCamera } from '../formCreateCamera/FormCreateCamera';

export const FormModalCamera = ({
	onClose,
	isOpen,
	isUpdate,
	setIsUpdate,
	setInputCamera,
	inputCamera,
	isReload,
	setIsReload,
}) => {
	return (
		<Modal
			size={'2xl'}
			closeOnOverlayClick={false}
			onClose={onClose}
			isOpen={isOpen}
			isCentered
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					{isUpdate ? 'Editar cámara' : 'Crear cámara'}
				</ModalHeader>
				<ModalCloseButton
					onClick={() => {
						setIsUpdate(false);
					}}
				/>
				<ModalBody>
					<FormCreateCamera
						onClose={onClose}
						isUpdate={isUpdate}
						setIsUpdate={setIsUpdate}
						inputCamera={inputCamera}
						setInputCamera={setInputCamera}
						isReload={isReload}
						setIsReload={setIsReload}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
