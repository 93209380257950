import {
	Flex,
	Box,
	Button,
	useToast,
	HStack,
	useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FormCenterData } from './subcomponents/FormCenterData';
import { errorToast, okToast } from '../../../utils/toast';
import { createCenter, updateCenter } from '../../../services/CenterServices';
import {
	checkCifDni,
	checkEmail,
	checkPostalCode,
} from '../../../utils/CheckingData';
import { initialCreateCenter } from '../../../data/initData';
import { RestoreModalCenters } from '../modal/RestoreModalCenters';
import { decodeToken } from '../../../utils/TokenUtils';

export const FormCreateCenter = ({
	onClose,
	isReload,
	setIsReload,
	isUpdateData,
	setIsUpdateData,
	dataCenterUpdate,
	setDataCenterUpdate,
}) => {
	const {
		isOpen: isOpenRestoreModalCenter,
		onOpen: onOpenRestoreModalCenter,
		onClose: onCloseRestoreModalCenter,
	} = useDisclosure();
	const toast = useToast();
	const [inputCreateCenter, setCreateCenter] = useState(initialCreateCenter);
	const [centerIdRestored, setCenterIdRestored] = useState(null);
	const [clickOnSubmit, setClickOnSubmit] = useState(false);

	const onSubmit = async () => {
		setClickOnSubmit(true);
		try {
			const { name, cifDni, phoneNumber, email, address, postalCode } =
				inputCreateCenter;
			if (
				!name ||
				!cifDni ||
				!phoneNumber ||
				!email ||
				!address ||
				!postalCode
			) {
				return errorToast(
					toast,
					'Por favor, rellene todos los campos obligatorios',
				);
			}

			if (
				name.trim() === '' ||
				address.trim() === '' ||
				phoneNumber.trim() === ''
			) {
				return errorToast(
					toast,
					'Por favor, rellene todos los campos obligatorios',
				);
			}

			const checkingEmail = checkEmail(email);
			if (!checkingEmail)
				return errorToast(
					toast,
					'Email incorrecto',
					'Por favor inserte un email válido',
				);

			if (postalCode) {
				const checkingPostalCode = checkPostalCode(postalCode);
				if (!checkingPostalCode)
					return errorToast(
						toast,
						'Código Postal incorrecto',
						'Por favor inserte un código postal válido',
					);
			}

			const checkingCifDni = checkCifDni(cifDni);
			if (!checkingCifDni)
				return errorToast(
					toast,
					'CIF/DNI incorrecto',
					'Por favor inserte un CIF/DNI válido',
				);

			let register, update;

			if (isUpdateData) {
				update = await updateCenter(inputCreateCenter);
				isUpdateData && setIsUpdateData(false);
				setDataCenterUpdate({});
				onClose();
			} else {
				register = await createCenter(inputCreateCenter);
			}

			if (register) {
				okToast(toast, 'Centro creado correctamente');
				setIsReload(!isReload);
				onClose();
			} else if (update) {
				okToast(toast, 'Centro actualizado correctamente');
				setIsReload(!isReload);
				onClose();
			}
		} catch (error) {
			if (isUpdateData) {
				errorToast(toast, 'Error al actualizar el centro');
			} else {
				if (
					error.message === 'Center already exists but is deleted' ||
					error.message === 'Email already exists but is deleted'
				) {
					const centerId = decodeToken(error.data.token);
					setCenterIdRestored(centerId);
					onOpenRestoreModalCenter();
				} else if (error.message === 'Center already exists')
					errorToast(
						toast,
						'Error al crear centro, ya existe o fue eliminado',
					);
			}
		}
	};

	return (
		<>
			<Flex w={'100%'} align={'center'} justify={'center'}>
				<Box rounded={'lg'} bg={'white'} p={8}>
					<FormCenterData
						inputCreateCenter={inputCreateCenter}
						setCreateCenter={setCreateCenter}
						isUpdateData={isUpdateData}
						dataCenterUpdate={dataCenterUpdate}
						clickOnSubmit={clickOnSubmit}
					/>

					<HStack mt={10} justifyContent={'center'}>
						<Button
							size='lg'
							w={'50%'}
							bg={'red'}
							color={'white'}
							transition={'0.5s'}
							_hover={{
								bg: 'gray.600',
								color: 'white',
							}}
							onClick={() => {
								isUpdateData && setIsUpdateData(false);
								setDataCenterUpdate({});
								onClose();
							}}
						>
							Cancelar
						</Button>
						{isUpdateData ? (
							<Button
								loadingText='Submitting'
								size='lg'
								w={'50%'}
								bg={'teal.300'}
								color={'white'}
								transition={'0.5s'}
								_hover={{
									bg: 'gray.600',
									color: 'white',
								}}
								onClick={onSubmit}
							>
								Actualizar centro
							</Button>
						) : (
							<Button
								loadingText='Submitting'
								size='lg'
								w={'50%'}
								bg={'teal.300'}
								color={'white'}
								transition={'0.5s'}
								_hover={{
									bg: 'gray.600',
									color: 'white',
								}}
								onClick={onSubmit}
							>
								Crear centro
							</Button>
						)}
					</HStack>
				</Box>
			</Flex>
			{isOpenRestoreModalCenter && (
				<RestoreModalCenters
					isOpen={isOpenRestoreModalCenter}
					onCloseRestoreModalCenter={onCloseRestoreModalCenter}
					onCloseFormCreateCenterModal={onClose}
					inputCreateCenter={inputCreateCenter}
					centerIdRestored={centerIdRestored}
					isReload={isReload}
					setIsReload={setIsReload}
				/>
			)}
		</>
	);
};
