import { weekDays } from '../data/CONSTANTS';

export const parseReminderSetting = reminderSetting => {
	const parts = reminderSetting.split('-');
	const time = parts[0];
	const reminderNumber = parts[1] === '1';
	const frequency = parts[2];
	let days = [];

	if (frequency === '1') {
		days = [];
	} else if (frequency === '2') {
		days = ['Todos los días'];
	} else if (frequency === '3') {
		const weekPattern = parts.slice(3).join('');
		const weekPatternArray = weekPattern.split('');

		weekPatternArray.forEach(
			(item, index) => item === '1' && days.push(weekDays[index].initial),
		);
	}

	return {
		time,
		reminderNumber,
		days,
	};
};
