import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalCloseButton,
	Button,
	HStack,
	Text,
	PinInput,
	PinInputField,
	useToast,
	Box,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { okToast, errorToast } from '../../utils/toast';
import { forgotPassword, verifyCode } from '../../services/UserServices';

export const VerificationCodeModal = ({ isOpen, onClose, emailToResend }) => {
	const [code, setCode] = useState('');
	const toast = useToast();
	const handleChangeCode = value => setCode(value);
	const [counter, setCounter] = useState(60);
	const [isResendAvailable, setIsResendAvailable] = useState(false);

	useEffect(() => {
		if (isOpen && counter > 0) {
			const timer = setInterval(() => {
				setCounter(prevCounter => prevCounter - 1);
			}, 1000);
			return () => clearInterval(timer);
		} else {
			setIsResendAvailable(true);
		}
	}, [isOpen, counter]);

	const handleSubmitCode = async () => {
		try {
			const response = await verifyCode(code);
			if (!response) {
				return errorToast(
					toast,
					'Código incorrecto',
					'Por favor verifique el código e inténtelo de nuevo',
				);
			}

			okToast(
				toast,
				'Código verificado con éxito, se ha enviado una nueva contraseña temporal a su email',
			);
			onClose();
		} catch (error) {
			errorToast(toast, 'Error al verificar el código');
		}
	};

	const handleResendCode = async () => {
		try {
			const response = await forgotPassword(emailToResend);

			if (!response) {
				return errorToast(
					toast,
					'Error al intentar reenviar el código',
				);
			}

			okToast(
				toast,
				'Código reenviado con éxito',
				'Vuelve a consultar tu email',
			);
			setCounter(60);
			setIsResendAvailable(true);
		} catch (error) {
			errorToast(
				toast,
				'Error al intentar recuperar la contraseña',
				error.message,
			);
		}
	};

	const handleClose = () => {
		setCode('');
		setCounter(60);
		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			isCentered
			closeOnOverlayClick={false}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Verificar Código</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<p>
						Hemos enviado un código de verificación a tu correo
						electrónico. Por favor, ingresa el código a
						continuación.
					</p>
					<HStack justifyContent='center' mt={4}>
						<PinInput
							size='lg'
							value={code}
							onChange={handleChangeCode}
							otp
						>
							{[1, 2, 3, 4, 5, 6].map(index => (
								<PinInputField key={index} />
							))}
						</PinInput>
					</HStack>
					<Box mt={5} minHeight='20px'>
						<Text mt={5}>
							{counter > 0 &&
								`Debe esperar ${counter} segundos para reenviar la solicitud`}
						</Text>
					</Box>
					<HStack justify='center' mt='4'>
						<Button variant={'teal300'} onClick={handleSubmitCode}>
							Verificar código
						</Button>

						<Button
							variant={'teal300'}
							onClick={handleResendCode}
							isDisabled={counter > 0}
						>
							Reenviar código
						</Button>
					</HStack>
				</ModalBody>
				<ModalFooter>
					{!isResendAvailable && (
						<>
							<Button
								variant={'teal300'}
								mr={3}
								onClick={handleSubmitCode}
							>
								Verificar Código
							</Button>
							<Button variant='redAow' onClick={handleClose}>
								Cancelar
							</Button>
						</>
					)}
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
