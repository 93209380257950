import {
	Text,
	Button,
	HStack,
	VStack,
	Heading,
	useToast,
	useDisclosure,
	Flex,
	Stack,
	Spacer,
	Image,
	GridItem,
	Grid,
} from '@chakra-ui/react';
import { errorToast, okToast } from '../../../utils/toast';
import { deletedPatient } from '../../../services/PatientServices';
import { ViewPatientModal } from '../modal/ViewPatientModal';
import { AlertDeletePatient } from './AlertDeletePatient';
import {
	bloodPressure,
	heartRate,
	oxygen,
} from '../../../data/dataAlertMinMax';
import { useSelector } from 'react-redux';

export const CardPatient = ({
	patient,
	setIsReload,
	isReload,
	setIsUpdate,
	onOpen,
	setInputUser,
}) => {
	const {
		isOpen: isOpenModalViewPatient,
		onOpen: onOpenModalViewPatient,
		onClose: onCloseModalViewPatient,
	} = useDisclosure();
	const {
		isOpen: isOpenAlert,
		onOpen: onOpenAlert,
		onClose: onCloseAlert,
	} = useDisclosure();
	const { user } = useSelector(state => state.auth);
	const { selectCenter } = useSelector(state => state.selectedCenter);
	const toast = useToast();

	const onDelete = async () => {
		try {
			const deleted = await deletedPatient(patient?.patientId);

			if (deleted) {
				okToast(toast, 'Paciente eliminado con éxito');
				setIsReload(!isReload);
			}
		} catch (error) {
			errorToast(toast, 'Error al eliminar paciente', error.message);
		}
	};

	return (
		<>
			<Grid
				templateColumns={{
					base: 'repeat(1,2fr)',
					xl: 'repeat(5, 1fr)',
				}}
				columnGap={3}
				bg={'white'}
				p={3}
				gap={3}
				h={'100%'}
				borderRadius={5}
			>
				<GridItem colSpan={{ base: 0, xl: 2 }}>
					<VStack gap={{ base: 1, lg: 0 }} alignItems={'left'}>
						<HStack>
							<Text fontWeight={'bold'}>DNI:</Text>

							<Text fontSize={'sm'}>{patient?.dni}</Text>
						</HStack>
						<HStack>
							<Text fontWeight={'bold'}>Nombre y Apellidos:</Text>
							<Text>
								{patient?.name} {patient?.lastname}
							</Text>
						</HStack>
						<HStack>
							<Text fontWeight={'bold'}>Nº Seg. Social:</Text>
							<Text>{patient?.inss}</Text>
						</HStack>

						<HStack>
							<Text fontWeight={'bold'}>Nº Seg. Privado:</Text>
							<Text>{patient?.privateInsurance}</Text>
						</HStack>

						<HStack>
							<Text fontWeight={'bold'}>Nº Histórico:</Text>
							<Text>{patient?.medicalHistory}</Text>
						</HStack>
					</VStack>
				</GridItem>
				<GridItem
					w={'100%'}
					colSpan={{ base: 1, xl: 1 }}
					alignContent={'center'}
				>
					<VStack
						w={'100%'}
						bg={'gray.600'}
						color={'white'}
						borderRadius={10}
						p={2}
						gap={2}
						alignItems={'left'}
					>
						<Heading
							textAlign={'center'}
							size={'sm'}
							textDecoration={'underline'}
							mb={1}
						>
							CONTACTO
						</Heading>
						<Stack gap={0}>
							<Flex gap={2}>
								<Text fontWeight={'bold'} fontSize={'sm'}>
									Nombre:
								</Text>
								<Text fontSize={'sm'}>
									{patient?.contactName}
								</Text>
							</Flex>

							<Flex gap={2}>
								<Text fontWeight={'bold'} fontSize={'sm'}>
									Email:
								</Text>
								<Text fontSize={'sm'}>
									{patient?.emailContact}
								</Text>
							</Flex>

							<Flex gap={2}>
								<Text fontWeight={'bold'} fontSize={'sm'}>
									Teléfono:
								</Text>
								<Text fontSize={'sm'}>
									{patient?.phoneContact === 0
										? ''
										: patient?.phoneContact}
								</Text>
							</Flex>
						</Stack>
					</VStack>
					<Spacer />
				</GridItem>
				<GridItem
					w={'100%'}
					colSpan={{ base: 0, xl: 1 }}
					alignContent={'center'}
				>
					<Grid
						templateColumns={'repeat(3,2fr)'}
						columnGap={3}
						bg={'teal300'}
						borderRadius={10}
						p={3}
						gap={3}
						color={'white'}
						py={4}
					>
						<GridItem>
							<Stack
								h={'full'}
								direction={'column'}
								justify={'center'}
								alignItems={'center'}
								gap={0}
							>
								<Image src='/heartRate.png' boxSize={10} />
								<Text
									color={
										heartRate.min >= patient?.heartRate ||
										heartRate.max <= patient?.heartRate
											? 'red'
											: 'white'
									}
									fontWeight={
										heartRate.min >= patient?.heartRate ||
										heartRate.max <= patient?.heartRate
											? 'bold'
											: null
									}
									fontSize={'md'}
								>
									{patient?.heartRate
										? patient?.heartRate
										: 0}
								</Text>
								<Text fontSize={'2xs'}>Pulsaciones</Text>
							</Stack>
						</GridItem>
						<GridItem>
							<Stack
								h={'full'}
								direction={'column'}
								justify={'center'}
								alignItems={'center'}
								gap={0}
							>
								<Image src='/sato2.png' boxSize={10} />
								<Text
									color={
										oxygen.min >= patient?.oxygen ||
										oxygen.max <= patient?.oxygen
											? 'red'
											: 'white'
									}
									fontWeight={
										oxygen.min >= patient?.oxygen ||
										oxygen.max <= patient?.oxygen
											? 'bold'
											: null
									}
									fontSize={'md'}
								>
									{patient?.oxygen ? patient?.oxygen : 0}
								</Text>
								<Text fontSize={'2xs'}>Oxígeno</Text>
							</Stack>
						</GridItem>
						<GridItem>
							<Stack
								h={'full'}
								direction={'column'}
								justify={'center'}
								alignItems={'center'}
								gap={0}
							>
								<Image src='/bloodPressure.png' boxSize={10} />
								<Text
									color={
										bloodPressure.min >=
											patient?.bloodPressure
												?.bloodPressure1 ||
										bloodPressure.max <=
											patient?.bloodPressure
												?.bloodPressure1 ||
										bloodPressure.min >=
											patient?.bloodPressure
												?.bloodPressure2 ||
										bloodPressure.max <=
											patient?.bloodPressure
												?.bloodPressure2
											? 'red'
											: 'white'
									}
									fontWeight={
										bloodPressure.min >=
											patient?.bloodPressure
												?.bloodPressure1 ||
										bloodPressure.max <=
											patient?.bloodPressure
												?.bloodPressure1 ||
										bloodPressure.min >=
											patient?.bloodPressure
												?.bloodPressure2 ||
										bloodPressure.max <=
											patient?.bloodPressure
												?.bloodPressure2
											? 'bold'
											: null
									}
									fontSize={'md'}
								>
									{patient?.bloodPressure?.bloodPressure1
										? patient?.bloodPressure?.bloodPressure1
										: '-'}
									/
									{patient?.bloodPressure?.bloodPressure2
										? patient?.bloodPressure?.bloodPressure2
										: '-'}
								</Text>
								<Text fontSize={'2xs'}>Presión</Text>
							</Stack>
						</GridItem>
					</Grid>
				</GridItem>
				<GridItem colSpan={{ base: 0, xl: 0 }} alignContent={'center'}>
					<VStack
						gap={2}
						justifyContent={'center'}
						alignItems={'center'}
					>
						<Button w={'full'} onClick={onOpenModalViewPatient}>
							Ver paciente
						</Button>
						<HStack w={'100%'}>
							<Button
								w={'100%'}
								bg={'teal300'}
								color={'white'}
								rounded={'md'}
								_hover={{
									transform: 'translateY(-2px)',
									boxShadow: 'lg',
								}}
								onClick={() => {
									setIsUpdate(true);
									setInputUser(patient);
									onOpen();
								}}
							>
								Editar
							</Button>
							{(selectCenter?.role === 'admin' ||
								user?.isAdmin) && (
								<Button
									w={'100%'}
									bg={'red'}
									color={'white'}
									rounded={'md'}
									_hover={{
										transform: 'translateY(-2px)',
										boxShadow: 'lg',
									}}
									onClick={() => {
										onOpenAlert();
									}}
								>
									Eliminar
								</Button>
							)}
						</HStack>
					</VStack>
				</GridItem>
			</Grid>
			<AlertDeletePatient
				isOpen={isOpenAlert}
				onClose={onCloseAlert}
				onDelete={onDelete}
			/>

			<ViewPatientModal
				patient={patient}
				onClose={onCloseModalViewPatient}
				isOpen={isOpenModalViewPatient}
			/>
		</>
	);
};
