import { memo } from 'react';

import SidebarWithHeader from './sidebarWithHeader/SidebarWithHeader';

// eslint-disable-next-line react/display-name
export const LayoutMenu = memo(({ children }) => {
	return (
		<>
			<SidebarWithHeader>{children}</SidebarWithHeader>
		</>
	);
});
