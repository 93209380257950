/**
 * @name formatLastname
 * @description Formats the lastname to display the first 7 characters followed by '...'
 * @param {string} lastname - The lastname to format
 * @returns {string} The formatted lastname with the first 7 characters followed by '...'
 */

export const formatedLastNameCardsUser = lastname => {
	if (!lastname) return '';
	if (lastname.length <= 7) return lastname.charAt(0).toUpperCase() + '.';

	return lastname.substring(0, 15) + '...';
};
