import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Text,
} from '@chakra-ui/react';
import { useRef } from 'react';

export const AlertNotContactNotPhoneAlertPatient = ({
	isOpen,
	onClose,
	isUpdate,
	registerPatient,
	updatePatient,
}) => {
	const cancelRef = useRef();
	return (
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						No hay información de contacto y tampoco teléfonos de
						Alerta
					</AlertDialogHeader>

					<AlertDialogBody>
						¿Seguro que desea guardar un paciente{' '}
						<Text as='span' fontWeight='bold'>
							sin una persona de contacto
						</Text>
						, o{' '}
						<Text as='span' fontWeight='bold'>
							sin alguno de los teléfonos de alerta?
						</Text>
					</AlertDialogBody>

					<AlertDialogFooter
						display={'flex'}
						flexDirection={'row'}
						justifyContent={'center'}
						gap={5}
					>
						<Button
							onClick={() => {
								onClose();
								if (isUpdate) {
									updatePatient();
								} else {
									registerPatient();
								}
							}}
							ml={3}
						>
							Aceptar
						</Button>
						<Button
							variant={'teal300'}
							ref={cancelRef}
							onClick={onClose}
						>
							Cancelar
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
