import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	Button,
} from '@chakra-ui/react';
import { useRef } from 'react';

export const AlertDeleteAllReadEvents = ({ isOpen, onClose, onConfirm }) => {
	const cancelRef = useRef();

	return (
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						Eliminar todos los eventos leídos
					</AlertDialogHeader>

					<AlertDialogBody>
						¿Estás seguro de eliminar todos los eventos leídos? Esta
						acción no se puede deshacer y{' '}
						<strong>
							afectará a todos los eventos de la plataforma.
						</strong>
					</AlertDialogBody>

					<AlertDialogFooter
						display='flex'
						flexDirection='row'
						justifyContent='center'
						gap={5}
					>
						<Button
							variant={'redAow'}
							onClick={() => {
								onClose();
								onConfirm();
							}}
							ml={3}
						>
							Confirmar
						</Button>
						<Button
							variant={'teal300'}
							ref={cancelRef}
							onClick={onClose}
						>
							Cancelar
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
