import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
	Box,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	HStack,
	InputRightElement,
	Stack,
	Button,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export const FormUserData = ({
	inputCreateUser,
	setCreateUser,
	dataUserUpdate,
	isUpdateData,
	clickOnSubmit,
}) => {
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		isUpdateData && setCreateUser(dataUserUpdate);
	}, [dataUserUpdate, isUpdateData, setCreateUser]);

	const handleChange = e => {
		if (e.target.type === 'file') {
			const file = e.target.files[0].name;
			setCreateUser({ ...inputCreateUser, imageUrl: file });
		} else {
			const { name, value } = e.target;
			setCreateUser({ ...inputCreateUser, [name]: value });
		}
	};

	const checkValue = (value, isOptional = true) => {
		if (isOptional) return clickOnSubmit && !value;
		return clickOnSubmit && (!value || value.trim() === '');
	};

	return (
		<Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
			{/* <FormControl id='userName'>
				<Stack direction={['column', 'row']} spacing={6}>
					<Center>
						<Avatar
							size='xl'
							src={`/avatars/${inputCreateUser.imageUrl}`}
							bgSize={'cover'}
						></Avatar>
					</Center>
					<Center w='full'>
						<Input
							type='file'
							accept='image/*'
							onChange={handleChange}
							style={{ display: 'none' }}
							id='imageInput'
						/>
						<FormLabel htmlFor='imageInput' w={'full'}>
							<Button
								as='span'
								w='full'
								cursor={'pointer'}
								bg={'teal300'}
								color={'white'}
								transition={'0.5s'}
								_hover={{
									bg: 'gray.600',
									color: 'white',
								}}
							>
								Cargar imagen
							</Button>
						</FormLabel>
					</Center>
				</Stack>
			</FormControl> */}
			<HStack>
				<Box>
					<FormControl
						id='name'
						isRequired
						isInvalid={checkValue(inputCreateUser.name, false)}
					>
						<FormLabel>Nombre:</FormLabel>
						<Input
							name='name'
							type='text'
							errorBorderColor='crimson'
							value={inputCreateUser?.name}
							onChange={handleChange}
						/>
					</FormControl>
				</Box>
				<Box>
					<FormControl
						id='lastname'
						isRequired
						isInvalid={checkValue(inputCreateUser.name, false)}
					>
						<FormLabel>Apellidos:</FormLabel>
						<Input
							name='lastname'
							type='text'
							errorBorderColor='crimson'
							value={inputCreateUser?.lastname}
							onChange={handleChange}
						/>
					</FormControl>
				</Box>
			</HStack>
			<FormControl
				id='email'
				isRequired
				isInvalid={clickOnSubmit && !inputCreateUser.email}
			>
				<FormLabel>Email:</FormLabel>
				<Input
					name='email'
					type='email'
					errorBorderColor='crimson'
					value={inputCreateUser?.email}
					onChange={handleChange}
				/>
			</FormControl>
			<FormControl
				id='password'
				isRequired
				isInvalid={
					checkValue(inputCreateUser.password, false) && !isUpdateData
				}
			>
				<FormLabel>Password:</FormLabel>
				<InputGroup>
					<Input
						name='password'
						type={showPassword ? 'text' : 'password'}
						errorBorderColor='crimson'
						value={inputCreateUser?.password}
						onChange={handleChange}
					/>
					<InputRightElement h={'full'}>
						<Button
							variant={'ghost'}
							onClick={() => {
								setShowPassword(showPassword => !showPassword);
							}}
						>
							{showPassword ? <ViewIcon /> : <ViewOffIcon />}
						</Button>
					</InputRightElement>
				</InputGroup>
			</FormControl>
			<FormControl id='confPassword' isRequired>
				<FormLabel>Repetir password:</FormLabel>
				<InputGroup>
					<Input
						name='confPassword'
						type={showPassword ? 'text' : 'password'}
						errorBorderColor='crimson'
						value={inputCreateUser?.confPassword}
						onChange={handleChange}
					/>
					<InputRightElement h={'full'}>
						<Button
							variant={'ghost'}
							onClick={() => {
								setShowPassword(showPassword => !showPassword);
							}}
						>
							{showPassword ? <ViewIcon /> : <ViewOffIcon />}
						</Button>
					</InputRightElement>
				</InputGroup>
			</FormControl>
		</Stack>
	);
};
