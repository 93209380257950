import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { Circle } from './CircleMaps';
import { AbsoluteCenter, Box, Text } from '@chakra-ui/react';

export const LocationPatientProfile = ({
	latitude = null,
	longitude = null,
	accuracy = null,
}) => {
	const radius = accuracy;
	let position = {};

	if (latitude && longitude) {
		position = {
			lat: parseFloat(latitude),
			lng: parseFloat(longitude),
		};
	}

	return (
		<Box
			w={'100%'}
			h={'100%'}
			bgColor={'gray.200'}
			borderRadius={10}
			position={'relative'}
		>
			{latitude && longitude ? (
				<APIProvider apiKey={'AIzaSyDXGSYcqJdq1QT4p-VHLANFN5dpidX-Myw'}>
					<Map
						defaultCenter={position}
						defaultZoom={13}
						gestureHandling={'greedy'}
						disableDefaultUI={true}
					>
						{(!radius || radius <= 12) && (
							<Marker position={position} />
						)}
						<Circle
							center={position}
							radius={radius}
							strokeColor={'#000'}
							strokeOpacity={1}
							strokeWeight={1}
							fillColor={'#FF0000'}
							fillOpacity={0.3}
						/>
					</Map>
				</APIProvider>
			) : (
				<AbsoluteCenter>
					<Text>No hay datos de ubicación</Text>
				</AbsoluteCenter>
			)}
		</Box>
	);
};
