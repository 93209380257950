import {
	Box,
	Button,
	Center,
	Flex,
	FormControl,
	FormLabel,
	Image,
	Input,
	Spinner,
	Stack,
	useToast,
	useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMe, loginUser, reset } from '../redux/reducers/AuthReducer';
import { errorToast } from '../utils/toast';
import { checkEmail } from '../utils/CheckingData';
import { useNavigate } from 'react-router-dom';
import { ForgotPasswordModal } from './password/ForgotPasswordModal';

const initialLogin = {
	email: '',
	password: '',
};

export const Login = () => {
	const { user, isError, message, isSuccess, isLoading } = useSelector(
		state => state.auth,
	);

	const [needReload, setNeedReload] = useState(true);

	const noSesion = isError && (user === null || user === undefined);
	const [inputLogin, setInputLogin] = useState(initialLogin);
	const [clickOnLogin, setClickOnLogin] = useState(false);
	const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure();
	const buttonRef = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const toast = useToast();

	useEffect(() => {
		if (needReload && !isSuccess) {
			setNeedReload(false);
			dispatch(getMe());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [needReload, isLoading, isSuccess]);

	useEffect(() => {
		if (isSuccess && user) {
			navigate('/home');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess, user]);

	useEffect(() => {
		const handleKeyPress = event => {
			if (event.key === 'Enter' && buttonRef.current) {
				buttonRef.current.click();
			}
		};

		document.addEventListener('keydown', handleKeyPress);

		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, []);

	const handleCloseModal = () => {
		setInputLogin(prevState => ({
			...prevState,
			password: '',
		}));
		onCloseModal();
	};
	const handleChangeLogin = e => {
		const { name, value } = e.target;
		setInputLogin({ ...inputLogin, [name]: value });
	};

	const onSubmitLogin = async () => {
		const { email, password } = inputLogin;

		if (!email || !password) {
			setClickOnLogin(true);
			return errorToast(
				toast,
				'Por favor, rellene todos los campos obligatorios',
			);
		}

		const checkingEmail = checkEmail(email);
		if (!checkingEmail)
			return errorToast(
				toast,
				'Email incorrecto',
				'Por favor inserte un email válido',
			);

		dispatch(loginUser({ email, password }));
	};

	useEffect(() => {
		if (noSesion && isError) {
			if (message !== 'Need to be logged in.') {
				errorToast(
					toast,
					'Email o contraseña incorrectas, por favor vuelva a intentarlo',
				);
				dispatch(reset());
				setInputLogin(prevState => ({
					...prevState,
					password: '',
				}));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isError, message, toast, noSesion]);

	return (
		<>
			{isLoading && !isSuccess ? (
				<Center h={'100vh'}>
					<Spinner
						thickness='4px'
						speed='0.65s'
						emptyColor='gray.200'
						color='teal300'
						size='xl'
					/>
				</Center>
			) : (
				<Flex align={'center'} justify={'center'} h={'100vh'}>
					<Stack spacing='8'>
						<Box
							py={'8'}
							px={'10'}
							bg={{ base: 'transparent', sm: 'bg.surface' }}
							boxShadow={{ base: 'none', sm: 'md' }}
							borderRadius={'xl'}
							bgColor={'white'}
						>
							<Stack spacing='6'>
								<Stack gap={5}>
									<Center>
										<Image
											src='/logoBlack.png'
											w={'250px'}
										/>
									</Center>
									<FormControl
										isRequired
										isInvalid={
											clickOnLogin &&
											inputLogin.email === ''
										}
									>
										<FormLabel htmlFor='email'>
											Email
										</FormLabel>
										<Input
											id='email'
											type='email'
											name='email'
											errorBorderColor='crimson'
											value={inputLogin.email}
											onChange={handleChangeLogin}
										/>
									</FormControl>
									<FormControl
										isRequired
										isInvalid={
											clickOnLogin &&
											inputLogin.password === ''
										}
									>
										<FormLabel htmlFor='password'>
											Contraseña
										</FormLabel>
										<Input
											id='password'
											type='password'
											name='password'
											errorBorderColor='crimson'
											value={inputLogin.password}
											onChange={handleChangeLogin}
										/>
									</FormControl>
								</Stack>
								<Stack spacing='2'>
									<Button
										fontSize={'xl'}
										bg={'#4FD1C5'}
										color={'white'}
										onClick={onSubmitLogin}
										ref={buttonRef}
									>
										Iniciar sesión
									</Button>
									<Button
										variant='text'
										color={'#4FD1C5'}
										size='mg'
										transition={'0.5s'}
										_hover={{
											transform: 'scale(105%)',
										}}
										onClick={onOpen}
									>
										¿Contraseña olvidada?
									</Button>
								</Stack>
							</Stack>
						</Box>
					</Stack>
				</Flex>
			)}
			<ForgotPasswordModal isOpen={isOpen} onClose={handleCloseModal} />
		</>
	);
};
