import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(accordionAnatomy.keys);

const aow = definePartsStyle({
	button: {
		bg: 'gray.600',
		color: 'white',
		_hover: {
			bg: 'gray.700',
			color: 'white',
		},
	},
});

export const accordionTheme = defineMultiStyleConfig({ aow });
