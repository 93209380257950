import { useState } from 'react';
import {
	FormControl,
	FormLabel,
	Input,
	VStack,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Text,
	Box,
	Flex,
	List,
	ListItem,
	Divider,
	useToast,
} from '@chakra-ui/react';
import { getAllPatientVitalsData } from '../../../../../services/PatientServices';
import { errorToast } from '../../../../../utils/toast';
import { scrollbar } from '../../../../../data/CONSTANTS';

export const DataHistoricalPatientProfile = ({ patient }) => {
	const { patientId } = patient;
	const toast = useToast();
	const [selectedDate, setSelectedDate] = useState('');
	const [historicalData, setHistoricalData] = useState({
		vitals: [],
		temperatures: [],
	});
	const [dataLoaded, setDataLoaded] = useState(false);
	const [noData, setNoData] = useState(false);

	const handleDateChange = async e => {
		const date = e.target.value;
		setSelectedDate(date);
		await getHistoricalData(date);
	};

	const groupByDate = data => {
		const groupedData = data.reduce((acc, item) => {
			const date = new Date(item.createAt).toLocaleDateString();
			if (!acc[date]) {
				acc[date] = [];
			}
			acc[date].push(item);
			return acc;
		}, {});

		Object.keys(groupedData).forEach(date => {
			groupedData[date].sort(
				(a, b) => new Date(a.createAt) - new Date(b.createAt),
			);
		});

		return groupedData;
	};

	const getHistoricalData = async date => {
		try {
			const data = await getAllPatientVitalsData(patientId, date);

			if (data.length === 0) {
				setNoData(true);
			} else {
				setHistoricalData({
					vitals: data.vitals || [],
					temperatures: data.temperatures || [],
				});
				setDataLoaded(true);
				setNoData(false);
			}
		} catch (error) {
			errorToast(toast, 'Error al obtener los datos');
		}
	};

	return (
		<VStack w='100%' spacing={4} px={{ base: 4, md: 6 }}>
			<Flex direction='column' align='center' w='100%'>
				<FormControl textAlign='center' w={{ base: '100%', md: '50%' }}>
					<FormLabel
						htmlFor='historicalDate'
						fontWeight='normal'
						w='100%'
						mb={1}
					>
						<Text
							as='span'
							fontWeight='bold'
							fontSize={{ base: 'sm', md: 'md' }}
						>
							Selecciona una fecha.
						</Text>{' '}
						<Text as='span' fontSize={{ base: 'xs', md: 'md' }}>
							Los datos históricos se mostrarán desde la fecha
							seleccionada hasta hoy.
						</Text>
					</FormLabel>
					<Input
						id='historicalDate'
						name='historicalDate'
						type='date'
						max={new Date().toISOString().split('T')[0]}
						value={selectedDate}
						onChange={handleDateChange}
						w='100%'
					/>
				</FormControl>
			</Flex>
			{dataLoaded && !noData && (
				<Tabs variant='enclosed' w='100%' mt={4}>
					<TabList>
						<Tab>Datos Vitales</Tab>
						<Tab>Temperaturas</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<Box
								w='100%'
								h={{ base: '400px', md: '450px' }}
								overflowY='auto'
								sx={scrollbar}
							>
								<VStack w='100%' spacing={4} align='start'>
									{Object.keys(
										groupByDate(historicalData.vitals),
									).map(date => (
										<VStack
											key={`vitals-${date}`}
											w='100%'
											spacing={2}
											align='start'
										>
											<Text
												fontSize='lg'
												fontWeight='bold'
												color='teal.600'
												bg='teal.50'
												p={2}
												borderRadius='md'
											>
												{date}
											</Text>
											<List spacing={3} w='100%'>
												{groupByDate(
													historicalData.vitals,
												)[date].map((item, index) => (
													<ListItem
														key={`vital-${index}`}
														p={4}
														borderWidth='1px'
														borderRadius='md'
														py={3}
														px={2}
													>
														<Flex
															direction='row'
															w='100%'
															gap={{
																base: 3,
																md: 2,
															}}
														>
															<Text
																flex='1'
																fontSize={{
																	base: 'sm',
																	md: 'md',
																}}
															>
																<strong>
																	Hora:
																</strong>{' '}
																{new Date(
																	item.createAt,
																).toLocaleTimeString(
																	[],
																	{
																		hour: '2-digit',
																		minute: '2-digit',
																	},
																)}
															</Text>
															<Text
																flex='1'
																fontSize={{
																	base: 'sm',
																	md: 'md',
																}}
															>
																<strong>
																	Presión
																	Arterial:
																</strong>{' '}
																{
																	item
																		.bloodPressure
																		.bloodPressure1
																}{' '}
																/{' '}
																{
																	item
																		.bloodPressure
																		.bloodPressure2
																}
															</Text>
															<Text
																flex='1'
																fontSize={{
																	base: 'sm',
																	md: 'md',
																}}
															>
																<strong>
																	Frecuencia
																	Cardíaca:
																</strong>{' '}
																{item.heartRate}{' '}
																bpm
															</Text>
															<Text
																flex='1'
																fontSize={{
																	base: 'sm',
																	md: 'md',
																}}
																textAlign='center'
															>
																<strong>
																	Oxígeno:
																</strong>{' '}
																{item.oxygen}%
															</Text>
														</Flex>
													</ListItem>
												))}
											</List>
											<Divider />
										</VStack>
									))}
								</VStack>
							</Box>
						</TabPanel>
						<TabPanel>
							<Box
								w='100%'
								h='400px'
								overflowY='auto'
								sx={scrollbar}
							>
								<VStack w='100%' spacing={4} align='start'>
									{Object.keys(
										groupByDate(
											historicalData.temperatures,
										),
									).map(date => (
										<VStack
											key={`temperatures-${date}`}
											w='100%'
											spacing={2}
											align='start'
										>
											<Text
												fontSize='lg'
												fontWeight='bold'
												color='orange.600'
												bg='orange.50'
												p={2}
												borderRadius='md'
											>
												{date}
											</Text>
											<List spacing={3} w='100%'>
												{groupByDate(
													historicalData.temperatures,
												)[date].map((item, index) => (
													<ListItem
														key={`temperature-${index}`}
														p={4}
														borderWidth='1px'
														borderRadius='md'
														py={3}
														px={2}
													>
														<Flex
															direction='row'
															w='100%'
															gap={{
																base: 1,
																md: 5,
															}}
														>
															<Text
																flex='1'
																fontSize={{
																	base: 'sm',
																	md: 'md',
																}}
															>
																<strong>
																	Hora:
																</strong>{' '}
																{new Date(
																	item.createAt,
																).toLocaleTimeString(
																	[],
																	{
																		hour: '2-digit',
																		minute: '2-digit',
																	},
																)}
															</Text>
															<Text
																flex='1'
																fontSize={{
																	base: 'sm',
																	md: 'md',
																}}
															>
																<strong>
																	Temperatura:
																</strong>{' '}
																{
																	item.temperature
																}
																°C
															</Text>
														</Flex>
													</ListItem>
												))}
											</List>
											<Divider />
										</VStack>
									))}
								</VStack>
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			)}
			{noData && (
				<Box w='100%' mt={6}>
					<Text
						fontSize='lg'
						fontWeight='bold'
						color='red.600'
						textAlign='center'
					>
						No hay datos históricos disponibles para la fecha
						seleccionada.
					</Text>
				</Box>
			)}
		</VStack>
	);
};
