import { Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export const Clock = () => {
	const [clock, setClock] = useState(new Date());
	useEffect(() => {
		const interval = setInterval(() => {
			setClock(new Date());
		}, 1000);

		return () => clearInterval(interval);
	}, [clock]);

	const hours = clock.getHours();
	const minutes = clock.getMinutes();
	const seconds = clock.getSeconds();

	const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds} `;

	return (
		<Text color={'black'} fontSize={'2xl'} mr={2}>
			{formattedTime}
		</Text>
	);
};
