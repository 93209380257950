import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Login } from '../components/Login';

import { Home } from '../pages/Home';

import { ViewAllCenters } from '../components/centers/ViewAllCenters';

import { ViewAllUsers } from '../components/users/ViewAllUsers';
import { ViewAllDevices } from '../components/watch/ViewAllDevices';
import { ViewAllCameras } from '../components/cameras/ViewAllCameras';
import { ViewAllEvents } from '../components/events/ViewAllEvents';
import { LayoutMenu } from '../components/general/LayoutMenu';
import { ViewAllPatients } from '../components/patients/ViewAllPatients';
import { AuthGuard } from './authGuard/auth.guards';
import { PageError } from '../components/general/PageError';

/**
 *
 * @name AppRoutes
 * @description The app routes component
 * @returns {JSX.Element} The JSX Code for the AppRoutes component
 * @version 1.0.0
 *
 */

// eslint-disable-next-line react/display-name
const AppRoutes = memo(() => {
	return (
		<>
			<LayoutMenu>
				<Routes>
					<Route path='/' element={<Login />} />
					<Route element={<AuthGuard />}>
						<Route path='/home' element={<Home />} />
						<Route path='/patients' element={<ViewAllPatients />} />
						<Route path='/users' element={<ViewAllUsers />} />
						<Route path='/centers' element={<ViewAllCenters />} />
						<Route path='/devices' element={<ViewAllDevices />} />
						<Route path='/cameras' element={<ViewAllCameras />} />
						<Route path='/events' element={<ViewAllEvents />} />
					</Route>
					<Route path='*' element={<PageError />} />
				</Routes>
			</LayoutMenu>
		</>
	);
});

export default AppRoutes;
