import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
} from '@chakra-ui/react';

import { FormCreateUser } from '../formCreateUser/FormCreateUser';

export const FormModalUsers = ({
	onClose,
	isOpen,
	isReload,
	setIsReload,
	isUpdateData,
	setIsUpdateData,
	dataUserUpdate,
	setDataUserUpdate,
	step,
	setStep,
	userIdSelect,
	isReloadCentersByUser,
	setIsReloadCentersByUser,
	isReloadPatientsByUser,
	setIsReloadPatientsByUser,
	onCloseStatus,
	setOnCloseStatus,
}) => {
	return (
		<>
			<Modal
				closeOnOverlayClick={false}
				closeOnEsc={false}
				onClose={onClose}
				isOpen={isOpen}
				isCentered
			>
				<ModalOverlay />
				<ModalContent>
					{(step === 1 || (step === 2 && onCloseStatus)) && (
						<ModalCloseButton
							onClick={() => {
								isUpdateData && setIsUpdateData(false);
								setDataUserUpdate({});
								setStep(1);
							}}
						/>
					)}

					<ModalBody>
						<FormCreateUser
							onClose={onClose}
							isReload={isReload}
							setIsReload={setIsReload}
							isUpdateData={isUpdateData}
							setIsUpdateData={setIsUpdateData}
							dataUserUpdate={dataUserUpdate}
							setDataUserUpdate={setDataUserUpdate}
							step={step}
							setStep={setStep}
							userIdSelect={userIdSelect}
							isReloadCentersByUser={isReloadCentersByUser}
							setIsReloadCentersByUser={setIsReloadCentersByUser}
							isReloadPatientsByUser={isReloadPatientsByUser}
							setIsReloadPatientsByUser={
								setIsReloadPatientsByUser
							}
							setOnCloseStatus={setOnCloseStatus}
							onCloseStatus={onCloseStatus}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};
