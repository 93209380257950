import { Box, Button, Flex, Stack, useToast } from '@chakra-ui/react';
import { FormWatchData } from './subcomponents/FormWatchData';
import { errorToast, okToast } from '../../../utils/toast';
import { createWatch, updateWatch } from '../../../services/WatchService';
import { initialWatch } from '../../../data/initData';
import { useState } from 'react';
export const FormCreateWatch = ({
	onClose,
	inputWatch,
	setInputWatch,
	isUpdate,
	setIsUpdate,
	setIsReload,
	isReload,
}) => {
	const toast = useToast();

	const hadlerChangeCreateWatch = e => {
		const { name, value } = e.target;
		setInputWatch({ ...inputWatch, [name]: value });
	};

	const [clickOnSubmit, setClickOnSubmit] = useState(false);

	const registerWatch = async () => {
		try {
			const create = await createWatch(inputWatch);

			if (create) {
				onClose();
				okToast(toast, 'Dispositivo creado correctamente');
				setIsReload(!isReload);
				setInputWatch(initialWatch);
			}
		} catch (error) {
			errorToast(toast, 'Error al crear el dispositivo', error);
		}
	};

	const update = async () => {
		try {
			const update = await updateWatch(inputWatch);

			setIsUpdate(false);
			if (update) {
				onClose();
				setIsReload(!isReload);
				okToast(toast, 'Dispositivo actualizado correctamente');
				setInputWatch(initialWatch);
			}
		} catch (error) {
			errorToast(toast, 'Error al actualizar el dispositivo', error);
		}
	};
	const onSubmit = async (isConfirm = true) => {
		setClickOnSubmit(true);
		try {
			const { imei, phoneNumber } = inputWatch;
			if (imei.trim() === '' || !phoneNumber) {
				return errorToast(toast, 'Rellena los campos requeridos');
			}

			if (isUpdate) {
				await update();
			} else {
				await registerWatch();
			}
		} catch (error) {
			errorToast(toast, 'Error al crear el dispositivo', error.message);
		}
	};

	return (
		<>
			<Flex align={'center'} justify={'center'}>
				<Stack spacing={8} maxW={'lg'}>
					<Box rounded={'lg'} bg={'white'}>
						<FormWatchData
							hadlerChangeCreateWatch={hadlerChangeCreateWatch}
							isUpdate={isUpdate}
							inputWatch={inputWatch}
							clickOnSubmit={clickOnSubmit}
						/>
						<Flex w='100%' gap={4} py={8} justify={'center'}>
							<Button
								loadingText='Submitting'
								size='lg'
								w={'50%'}
								variant={'redAow'}
								transition={'0.5s'}
								_hover={{
									bg: 'gray.600',
									color: 'white',
								}}
								onClick={onClose}
							>
								Cancelar
							</Button>
							<Button
								loadingText='Submitting'
								size='lg'
								w={'50%'}
								variant={'teal300'}
								transition={'0.5s'}
								_hover={{
									bg: 'gray.600',
									color: 'white',
								}}
								onClick={onSubmit}
							>
								{isUpdate ? 'Actualizar' : 'Guardar'}
							</Button>
						</Flex>
					</Box>
				</Stack>
			</Flex>
		</>
	);
};
