import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
} from '@chakra-ui/react';
import { FormCreateCenter } from '../formCreateCenter/FormCreateCenter';

export const FormModalCenters = ({
	onClose,
	isOpen,
	isReload,
	setIsReload,
	isUpdateData,
	setIsUpdateData,
	dataCenterUpdate,
	setDataCenterUpdate,
}) => {
	return (
		<>
			<Modal size={'4xl'} onClose={onClose} isOpen={isOpen} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton
						onClick={() => {
							isUpdateData && setIsUpdateData(false);
							setDataCenterUpdate({});
						}}
					/>
					<ModalBody>
						<FormCreateCenter
							onClose={onClose}
							isReload={isReload}
							setIsReload={setIsReload}
							isUpdateData={isUpdateData}
							setIsUpdateData={setIsUpdateData}
							dataCenterUpdate={dataCenterUpdate}
							setDataCenterUpdate={setDataCenterUpdate}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};
