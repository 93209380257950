import axios from 'axios';
import { decodeToken, encodeToken } from '../utils/TokenUtils';
import { createHash } from '../utils/Passwords';

/**
 *
 * @name createUser
 * @description Creates a new user
 * @param {object} data The data object
 * @returns {object} The user data
 *
 */
export const createUser = async (data, selectCenter = null) => {
	try {
		let { password } = data;

		if (selectCenter)
			data = { ...data, centerId: selectCenter.centerId, role: 'user' };

		password = await createHash(password);
		data = { ...data, password };

		const token = encodeToken(data);
		let response;
		if (!selectCenter) {
			response = await axios.post('/user/create', { token });
			return response.data.data.userId;
		} else {
			response = await axios.post('/user/create/centers-patients', {
				token,
			});
		}

		return response.data.data;
	} catch (error) {
		throw error.response.data;
	}
};

/**
 *
 * @name getAllUsers
 * @description Get all users data.
 * @param {object} data The data object
 * @returns {token}
 *
 */
export const getAllUsers = async () => {
	try {
		const response = await axios.get('/user/all');
		const { token } = response.data.data;

		const users = decodeToken(token);

		return users;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name deletedUser
 * @description Deleted a one user
 * @param {string} Dni The Dni center
 * @returns {token}
 *
 */
export const deletedUser = async userId => {
	try {
		const response = await axios.delete(`/user/delete/${userId}`);

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name assingCenterAndPacients
 * @description Assing center and patients to user
 * @param {string} Dni The Dni center
 * @returns {token}
 *
 */
export const assingCenterAndPatients = async data => {
	try {
		const token = encodeToken(data);
		const response = await axios.post(`/user/assingToUser/${data.userId}`, {
			token,
		});

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name updateUser
 * @description Update user data
 * @param {object} data The data object
 * @returns {token}
 *
 */
export const updateUser = async data => {
	try {
		const role = data?.role;
		const centerId = data?.centerId;
		let { password } = data;

		if (password) {
			password = await createHash(password);
			data = { ...data, password };
		}

		delete data?.role;
		delete data?.centerId;

		const token = encodeToken(data);
		const response = await axios.patch(`/user/update/${data.userId}`, {
			token,
		});

		if (role && centerId) {
			const token = encodeToken({ role: 'user', centerId });
			await axios.post(`/user/assingToUser/${data.userId}`, {
				token,
			});
		}

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getAllCenterByUser
 * @description Get all centers data by user.
 * @param {string} userId The userId
 * @returns {token}
 */
export const getAllCenterByUser = async userId => {
	try {
		const response = await axios.get(`/user/centers/${userId}`);
		const { token } = response.data.data;
		const data = decodeToken(token);

		return data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getAllPatientsByUser
 * @description Get all patient data by user.
 * @param {string} userId The userId
 * @returns {token}
 */
export const getAllPatientsByUser = async userId => {
	try {
		const response = await axios.get(`/user/patients/${userId}`);
		const { token } = response.data.data;
		const data = decodeToken(token);

		return data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name getAllCenterNotAssignByUserId
 * @description Get all centers and pacients data by user.
 * @param {string} userId The userId
 * @returns {token}
 */
export const getAllCenterNotAssignByUserId = async userId => {
	try {
		const response = await axios.get(`/user/centers/notAssigned/${userId}`);
		const { token } = response.data.data;
		const data = decodeToken(token);

		return data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name unassignPatient
 * @description Unassign patient from user.
 * @param {string} patientId
 * @returns
 */
export const unassignPatient = async (userId, patientId) => {
	try {
		const response = await axios.delete(`/user/delete/patiens/${userId}`, {
			data: {
				patientId,
			},
		});

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name unassignCenter
 * @description Unassign center from user.
 * @param {string} patientId
 * @returns
 */
export const unassignCenter = async (userId, centerId) => {
	try {
		const response = await axios.delete(`/user/delete/centers/${userId}`, {
			data: {
				centerId,
			},
		});

		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 *
 * @name checkExistingUser
 * @description Checking user exists
 * @param {object} data The data object
 * @returns {token}
 *
 */
export const checkExistingUser = async data => {
	try {
		const token = encodeToken(data);
		const response = await axios.get(`/user/${data.email}`, {
			token,
		});

		return response.data.length > 0;
	} catch (error) {
		throw error.response.data.message;
	}
};

/**
 * @name forgotPassword
 * @description Send a request to reset the user´s password.
 * @param {string} email
 * @returns {boolean} true/false
 */

export const forgotPassword = async email => {
	try {
		const response = await axios.post(`/user/forgotpassword`, {
			email,
		});

		return response.data;
	} catch (error) {
		throw error.response.data;
	}
};

/**
 * @name verifyCode
 * @description Verify the recovery code and send a new temporary password.
 * @param {string} code
 * @returns {boolean} true/false
 */

export const verifyCode = async code => {
	try {
		const response = await axios.post(`/user/send/newpassword`, { code });
		return response.data;
	} catch (error) {
		throw error.response.data;
	}
};
