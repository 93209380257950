import {
	Stack,
	Tabs,
	TabList,
	Tab,
	TabPanel,
	TabPanels,
	useToast,
	useMediaQuery,
	Select,
	Divider,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
	getDataSmartWatchPatient,
	getDataSmartWatchRefreshPatient,
} from '../../../../services/PatientServices';
import { errorToast } from '../../../../utils/toast';
import { DataPersonalPatientProfile } from './subcomponents/DataPersonalPatientProfile';
import { DataMedicalPatientProfile } from './subcomponents/DataMedicalPatientProfile';
import { DataSmartwatchPatientProfile } from './subcomponents/DataSmartwatchPatientProfile';
import { DataCameraPatientProfile } from './subcomponents/DataCameraPatientProfile';
import { DataHistoricalPatientProfile } from './subcomponents/DataHistoricalPatientProfile';
import { DataMedicineReminderPatientProfile } from './subcomponents/DataMedicineReminderPatientProfile';

export const ProfilePatient = ({ patient }) => {
	const toast = useToast();
	const [refreshDataPatient, setRefreshDataPatient] = useState(false);
	const [dataPatient, setDataPatient] = useState({});
	const [refreshCounter, setRefreshCounter] = useState(90);
	const [selectProfileMenu, setSelectProfileMenu] = useState('personal');
	const [isLargerThanSm] = useMediaQuery('(max-width: 480px)');

	const refreschData = async () => {
		if (patient?.imei) {
			try {
				await getDataSmartWatchRefreshPatient(patient?.imei);
				setRefreshDataPatient(true);
			} catch (error) {
				if (error === 'Vitals and location data frame not send.') {
					errorToast(toast, 'Reloj Desconectado');
				} else {
					errorToast(toast, 'Error al refrescar los datos', error);
				}
			}
		}
	};

	const getDataPatient = async () => {
		if (patient?.imei) {
			await getDataSmartWatchPatient(patient?.imei)
				.then(data => {
					setDataPatient(data);
				})
				.catch(() => {
					setDataPatient({});
				});
		}
	};

	useEffect(() => {
		let interval;

		if (refreshDataPatient) {
			interval = setInterval(() => {
				setRefreshCounter(prev => {
					const currentCounter = prev - 1;

					if (currentCounter <= 0) {
						getDataPatient();
						setRefreshDataPatient(false);
						return 90;
					} else {
						return currentCounter;
					}
				});
			}, 1000);
		}

		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshDataPatient]);

	useEffect(() => {
		getDataPatient();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const hadlerChangeSelect = e => {
		setSelectProfileMenu(e.target.value);
	};

	return (
		<Stack
			gap={5}
			mb={{ base: 0, lg: 5 }}
			direction={'column'}
			w={'100%'}
			h={{ base: 'full', lg: '80vh' }}
		>
			{isLargerThanSm ? (
				<>
					<Select
						size='sm'
						value={selectProfileMenu}
						onChange={hadlerChangeSelect}
					>
						<option value='personal'>Personales</option>
						<option value='medical'>Médicos</option>
						{patient?.watchId && (
							<option value='smartwatch'>Smartwatch</option>
						)}
						{patient?.cameraId && (
							<option value='camera'>Cámara</option>
						)}
						<option value='medicine'>Recordatorios</option>
						<option value='historical'>Histórico</option>
					</Select>
					<Divider />
					{selectProfileMenu === 'personal' && (
						<DataPersonalPatientProfile patient={patient} />
					)}
					{selectProfileMenu === 'medical' && (
						<DataMedicalPatientProfile patient={patient} />
					)}
					{selectProfileMenu === 'smartwatch' && (
						<DataSmartwatchPatientProfile
							refreschData={refreschData}
							refreshDataPatient={refreshDataPatient}
							refreshCounter={refreshCounter}
							dataPatient={dataPatient}
							patient={patient}
						/>
					)}
					{selectProfileMenu === 'camera' && (
						<DataCameraPatientProfile
							cameraId={patient?.cameraId}
						/>
					)}
					{selectProfileMenu === 'medicine' && (
						<DataMedicineReminderPatientProfile
							reminders={patient?.reminders}
						/>
					)}
					{selectProfileMenu === 'historical' && (
						<DataHistoricalPatientProfile patient={patient} />
					)}
				</>
			) : (
				<Tabs size={{ base: 'md', lg: 'lg' }} variant='enclosed'>
					<TabList>
						<Tab>Personales</Tab>
						<Tab>Médicos</Tab>
						{patient?.watchId && <Tab>Smartwatch</Tab>}
						{patient?.cameraId && <Tab>Cámara</Tab>}
						{patient?.reminders && <Tab>Recordatorios</Tab>}
						<Tab>Histórico</Tab>
					</TabList>
					<TabPanels h={'full'} position={'relative'}>
						<TabPanel>
							<DataPersonalPatientProfile patient={patient} />
						</TabPanel>
						<TabPanel h={{ base: '100%' }}>
							<DataMedicalPatientProfile patient={patient} />
						</TabPanel>
						{patient?.watchId && (
							<TabPanel>
								<DataSmartwatchPatientProfile
									refreschData={refreschData}
									refreshDataPatient={refreshDataPatient}
									refreshCounter={refreshCounter}
									dataPatient={dataPatient}
									patient={patient}
								/>
							</TabPanel>
						)}
						{patient?.cameraId && (
							<TabPanel>
								<DataCameraPatientProfile
									cameraId={patient?.cameraId}
								/>
							</TabPanel>
						)}
						{patient?.reminders && (
							<TabPanel>
								<DataMedicineReminderPatientProfile
									reminders={patient?.reminders}
								/>
							</TabPanel>
						)}
						<TabPanel>
							<DataHistoricalPatientProfile patient={patient} />
						</TabPanel>
					</TabPanels>
				</Tabs>
			)}
		</Stack>
	);
};
