import axios from 'axios';

/**
 *
 * @name getCities
 * @description Request all cities to provincie.
 * @param {String} province
 * @returns {Array} provincies.
 *
 */
export const getCities = async province => {
	try {
		const response = await axios.get(`/cities/${province}`);

		return response.data.data;
	} catch (error) {
		throw error.response.data.message;
	}
};
